import React, { useEffect } from 'react';
import { labels, furnitureTypes, priceBasedTypes } from '../../../views/Orders/Orders.constants';
import { renderInput, renderCheckbox } from '../../Common/Renderers';
import { IFurnitureProps } from '../OrderForm.interface';
import {
  getCalculationObject,
} from '../../Common/Utils';

// tslint:disable-next-line: variable-name
const Furniture = (props: IFurnitureProps) => {
  const {
    type,
    calculationType,
    assembly,
    price,
    quantity,
    linear_meter,
    worksheet,
    kitchenTools,
    modifyCabinet,
    modifyCabinetCount,
    removeFurniture,
    assembly_price,
    wallCloset,
    floorCloset,
    highCloset,
    other,
    pbPrice,
    worksheet_length,
    wallDrill,
    wallDrill_count,
    desk,
    desk_length,
    slat,
    slat_count,
  } = props;
  const {
    cooler,
    hotplate,
    owen,
    dishwasher,
    microwave,
    dehumidifier,
    sink,
  } = props.kitchenToolTypes;
  const isKika = calculationType === 'kika';
  const callback = (key: string) => (value: number | string | boolean) =>
    props.callback(key, value);
  const isKitchen = type === 'kitchen';
  const {
    basePrice,
    calculateAssemblyPrice,
    editableFields,
    enabledFurnitureTypes,
    getKitchenFields,
  } = getCalculationObject(calculationType);
  const editableAssemblyPrice = editableFields.includes('assembly_price');
  //const updateAssemblyPriceFn = editableAssemblyPrice ? callback('assembly_price') : () => null;
  const updateAssemblyPriceFn = callback('assembly_price');
  useEffect(() => {
    if (!editableAssemblyPrice) {
      let assemblyPrice = 0;
      if (assembly) {
        assemblyPrice = calculateAssemblyPrice(props);
        if (priceBasedTypes.includes(type) && pbPrice) {
          assemblyPrice = pbPrice;
        }
      }
      if (assemblyPrice !== assembly_price) {
        callback('assembly_price')(assemblyPrice);
      }
    }
  // eslint-disable-next-line
  }, [
    calculationType,
    type,
    assembly,
    price,
    quantity,
    linear_meter,
    worksheet,
    kitchenTools,
    modifyCabinet,
    modifyCabinetCount,
    cooler,
    hotplate,
    owen,
    dishwasher,
    microwave,
    dehumidifier,
    sink,
    props.kitchenToolTypes.other,
    wallCloset,
    floorCloset,
    highCloset,
    other,
    pbPrice,
    worksheet_length,
    wallDrill,
    wallDrill_count,
    desk,
    desk_length,
    slat,
    slat_count,
  ]);
  const basePrices: any = {
    couch: `${basePrice.couch} Ft`,
    bed: `${basePrice.bed} Ft`,
    bedCouch: 'Ár alapján',
    plannedKitchen: 'Ár alapján',
    kitchenSurvey: 'Ár alapján',
    furniture: 'Ár alapján',
    kitchen: 'Ár alapján',
  };
  const selectFurnitureType = (value: number | string | boolean) => () => {
    callback('type')(value);
    if (value === 'kitchenSurvey') {
      callback('assembly')(true);
      callback('own_delivery')(false);
    }
  }
  return (
    <>
      <i className="delete furniture" onClick={removeFurniture} />
      {renderInput(
        'price',
        labels.furnitures.price,
        props.price,
        callback('price'),
        'inputtype2',
        'number',
      )}
      <div className="radio imageselector">
        <strong>Bútortípus</strong>
        {Object.keys(furnitureTypes)
          .filter((item: string) => enabledFurnitureTypes.includes(item))
          .map((item: string, index: number) => (
            <span onClick={selectFurnitureType(item)} key={`${props.keyProp}-${index}`}>
              <input type="radio" checked={type === item} />
              <label className={`f-${item}`}>
                {furnitureTypes[item]}&nbsp;
                <small>{basePrices[item]}</small>
              </label>
            </span>
          ))}
      </div>
      {['bed', 'couch'].includes(type) &&
      <>
        {renderInput(
          'quantity',
          labels.furnitures.quantity,
          quantity,
          callback('quantity'),
          'inputtype2',
          'number',
        )}
      </>}
      {type !== 'kitchenSurvey' &&
      <>
        {renderCheckbox(
          'assembly',
          labels.furnitures.assembly,
          assembly,
          callback('assembly'),
        )}
        {renderCheckbox(
          'own_delivery',
          labels.furnitures.own_delivery,
          props.own_delivery,
          callback('own_delivery'),
        )}
      </>}
      {(assembly || type === 'kitchenSurvey') &&
        <div className="innerbox" id="szereles">
          {isKika && isKitchen && false &&
            renderInput(
              'linear_meter',
              labels.furnitures.linear_meter,
              props.linear_meter,
              callback('linear_meter'),
              'inputtype2',
              'number',
            )
          }
          {getKitchenFields(props)}
          {renderInput(
            'assembly_price',
            labels.furnitures.assembly_price,
            assembly_price,
            updateAssemblyPriceFn,
            `inputtype2 lightblue`,
            //`inputtype2 lightblue ${!editableAssemblyPrice ? 'readonly' : ''}`,
            'number',
          )}
        </div>
      }
      <hr />
    </>
  );
};

export default Furniture;
