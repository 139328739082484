import { IFirm } from './Firms.interface';

export const FIRMS_ACTION = {
  GET_FIRM_LIST: '[FIRMS] GET_FIRM_LIST',
  ADD_FIRM: '[FIRMS] ADD_FIRM',
  DELETE_FIRM: '[FIRMS] DELETE_FIRM',
  SUCCESS: {
    GET_FIRM_LIST: '[FIRMS] GET_FIRM_LIST_SUCCESS',
    ADD_FIRM: '[FIRMS] ADD_FIRM_SUCCESS',
    DELETE_FIRM: '[FIRMS] DELETE_FIRM_SUCCESS',
  },
  FAIL: {
    GET_FIRM_LIST: '[FIRMS] GET_FIRM_LIST_FAIL',
    ADD_FIRM: '[FIRMS] ADD_FIRM_FAIL',
    DELETE_FIRM: '[FIRMS] DELETE_FIRM_FAIL',
  },
};

export const getFirms = () => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getFirms',
      },
    },
    type: FIRMS_ACTION.GET_FIRM_LIST,
  };
};

export const saveFirm = (firm: IFirm) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=saveFirm',
        method: 'POST',
        data: firm,
      },
    },
    type: FIRMS_ACTION.ADD_FIRM,
  };
};

export const deleteFirm = (firmId: string) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=deleteFirm',
        method: 'POST',
        data: {
          id: firmId,
        },
      },
    },
    type: FIRMS_ACTION.DELETE_FIRM,
  };
};
