import React from 'react';
import {
  getSums,
  getAddress,
  getDay,
  getCalculationObject,
} from '../../Common/Utils';
import { ITabProps } from '../OrderForm.interface';
import { IContactPerson } from '../../../views/Firms/Firms.interface';
import logo from '../../../assets/images/print_logo.png';
import { formatNumbers, formatDate } from '../../Common/Helpers';
import { IFurniture } from '../../../views/Orders/Orders.interface';

// tslint:disable-next-line: variable-name
const Print = (props: ITabProps) => {
  const {
    deliveryData,
    orderNr,
    date,
    reclamation,
    summary,
    furnitures,
  } = props.stateObject;
  const { name, phone, base, distance_price } = deliveryData;
  const { actual_time, time, comment, book } = date;
  const {
    assemblyDiscount,
    assemblyDiscountApprover,
    deliveryDiscount,
    deliveryDiscountApprover,
  } = summary;
  const {
    assemblyPrice,
    sum,
    deliveryPrice,
    discount,
  } = getSums(props.stateObject);
  const filteredFirms = (props.firms || []).find(ffirm => `${ffirm.id}` === props.stateObject.firm);
  const {
    calculation = 'kika',
    city = '',
    contact: contacts = [],
  } = filteredFirms || {};
  const typeMap: any = {
    assembly: 'Szereléssel',
    delivery: 'Szállítással',
    both: 'Szállítás-szereléssel',
  };
  const logostyle: any = {
    float: 'left',
    width: '50px',
    margin: '0 0 10px 0',
  };
  const { orderFormTexts, getCommentFields, renderElementForPrinting } = getCalculationObject(calculation);
  const text = orderFormTexts
    .map(line => `${line}<br />`)
    .join('');
  const times: any = {
    'Délelőtt (8-14 óráig)': 'DE',
    'Délután (13-18 óráig)': 'DU',
  };
  const hasDeliveredFurniture = furnitures.some((furniture: IFurniture) => furniture.own_delivery);
  return (
    <div id="printcontent" className="hidden">
      <img src={logo} alt="logo" style={logostyle} />
      <h1>Megrendelő</h1>
      <table className="fontos">
        <tbody>
          <tr>
            <td className="bordered w35">Szerződésszám:<br />
              <span className="pszerz">{orderNr}</span> {reclamation ? '(reklamáció)' : ''}
            </td><td />
            <td className="bordered centered">
              Név: <span className="pnev">{name}</span><br />
              Telefon: <span className="ptel">{phone}</span>
            </td>
            <td />
            <td className="bordered rightAligned w35">
              Szállítási időpont: <span className="pdate">
                {book ? 'Előrendelés' : formatDate(new Date(actual_time), '.')}
              </span><br />
              Ügyfél által kért napszak: <span className="pkert">{times[time]}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="fontos">
        <tbody>
          <tr>
            <td className="bordered padding5-10-10">
              <h4>Szállítási adatok</h4><br />
                Szállítási cím: <span className="pcim">{getAddress(deliveryData)}</span><br />
                {hasDeliveredFurniture ?
                  'Kiszállási alapdíj: ' : 'Szállítási ár: '}
                <span className="pszall">{formatNumbers(parseInt(`${base}`, 10))} Ft</span><br />
                Km díj: <span className="pkm">{formatNumbers(parseInt(`${distance_price}`, 10))} Ft</span><br />
              {comment ?
                <>
                  <hr />
                    Megjegyzés: <span className="pmegj">{comment}</span>
                </> : null
              }
            </td>
            <td />
            <td className="bordered rightAligned">
              <h4 className="reset">
                {hasDeliveredFurniture ?
                  'Kiszállási díj: ' : 'Szállítás összesen: '}
                <span className="pszall">{formatNumbers(parseInt(`${deliveryPrice}`, 10))} Ft</span>
              </h4>
              {hasDeliveredFurniture ?
                <>
                  <br /><br />
                  <b className="itemDetails uppercase">A bútor már kiszállítva</b>
                </> : null}
            </td>
          </tr>
          <tr>
            <td className="height2" />
            <td className="height2" />
            <td className="height2" />
          </tr>
          <tr>
            <td className="bordered padding5-10-0">
              <h4>Megrendelt típusok</h4>
            </td>
            <td></td>
            <td className="bordered rightAligned w35">
              <h4 className="reset">
                {assemblyPrice ?
                  <>Szerelés összesen: <span className="pszer">{formatNumbers(assemblyPrice)} Ft</span></> : null
                }
                {!assemblyPrice ? <b>Nem kért szerelést!</b> : null}
              </h4>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="fontos bordered">
        <tbody>
          {renderElementForPrinting(props.stateObject, getCommentFields)}
          <tr>
            <td colSpan={5} className="padding5"></td>
          </tr>
        </tbody>
      </table>
      <table className="fontos">
        <tbody>
          <tr>
            <td className={`padding5-10-10 ${assemblyDiscount && deliveryDiscount ? 'bordered' : ''}`}>
              {(assemblyDiscount || deliveryDiscount) ?
                <>
                  <h4>Engedmény</h4><br />
                  {deliveryDiscount ?
                    <>Szállítási engedmény: <span className="peng">{formatNumbers(deliveryDiscount)} Ft (az {deliveryDiscountApprover} engedélyével)</span><br /></>
                  : null}
                  {assemblyDiscount ?
                    <>Szerelési engedmény: <span className="peng">{formatNumbers(assemblyDiscount)} Ft (az {assemblyDiscountApprover} engedélyével)</span><br /></>
                    : null}
                  Összes engedmény: <span className="peng">{formatNumbers(discount)} Ft</span>
                </> : null}
            </td>
            <td></td>
            <td className="bordered rightAligned w35 grayBg">
              <h4 className="reset">Összesen fizetendő: <span className="pall">{formatNumbers(sum)} Ft</span></h4>
            </td>
          </tr>
        </tbody>
      </table>
      <br /><br />
      <table className="masodik">
        <tbody>
          <tr>
            <td>
              <span className="paruhaz">{city}</span>,
              <span className="ptoday">{getDay('.')}</span>
            </td>
            <td>................................................<br />
              Megrendelést felvevő aláírása</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p dangerouslySetInnerHTML={{ __html: text }} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p><b>INFORMÁCIÓK:</b><br />
                {contacts.map((item: IContactPerson, index: number) => item.type ? (
                  <React.Fragment key={`contactPerson-${index}`}>
                    {typeMap[item.type]} kapcsolatban:&nbsp;
                    <span className="kaptel1" key={`contactperson-${index}`}>
                      {item.name} {item.phone}
                    </span><br />
                  </React.Fragment>
                ) : null)}
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p><small>Tisztelt Megrendelő! 2018. május 25-ével életbe lépett&nbsp;
              adatvédelmi törvénynek megfelelően tájékoztatjuk, hogy ezen megrendelő lapon&nbsp;
              szereplő személyes adatait a jelen jogszabályban (GDPR) foglaltaknak megfelelően&nbsp;
              kezeljük, harmadik félnek nem adjuk tovább és kizárólag minőségbiztosítási&nbsp;
              célból tároljuk az Ön beleegyezésével, amit a megrendelő aláírásával engedélyez&nbsp;
              számunkra. Ezen adatokat papír alapon 1 példányban tároljuk, a törvényben&nbsp;
              foglaltaknak megfelelően 5 évig. Ha ezen idő után el szeretné távolítani&nbsp;
              rendszerünkből ezen adatokat, legyen szíves írásban értesíteni minket a 6000&nbsp;
              Kecskemét, Cédulaház u. 43. postacímen, vagy a drspeedkft@gmail.com e-mail&nbsp;
              címen. Köszönjük megrendelését! Részletes adatvédelmi nyilatkozatunkat&nbsp;
              a http://drspeed.hu/adatvedelem weboldalon olvashatja el.<br />
              DrSpeed Kft. 6000 Kecskemét, Cédulaház utca 43. Adószám: 25351671-2-03
            </small></p>
            </td>
          </tr>
          <tr>
            <td><br /><br /><br />Köszönjük megrendelését!</td>
            <td><br /><br /><br />.........................................<br />Megrendelő/Vevő aláírása</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Print;
