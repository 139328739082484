import React from 'react';
import './Spinner.scss';

import spinner from '../../assets/images/AjaxLoader.svg';

// tslint:disable-next-line: variable-name
const Spinner = () =>
  (
    <div className="spinner">
      <img src={spinner} alt="Loading" />
    </div>
  );

export default Spinner;
