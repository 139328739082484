import React, { useState, useEffect } from 'react';
import { connector } from './Users.connector';
import { IUsersProps, IExistingUser } from './Users.interface';
import Spinner from '../../components/Image/Spinner';
import UserForm from '../../components/UserForm/UserForm';
import { roles } from './User.constants';
import './Users.scss';
import { convertToArray } from '../../components/Common/Utils';

// tslint:disable-next-line: variable-name
const Users = (props: IUsersProps) => {
  const [displayUserForm, setdisplayUserForm] = useState(false);
  const [selectedUser, setselectedUser] = useState<null | IExistingUser>(null);
  const { getUsers, isLoading, firm, team } = props;

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const renderUserRows = (): JSX.Element[] => {
    let rows = [(<tr key="user-0"><td colSpan={6}>Nem található felhasználó.</td></tr>)];
    if (props.users && props.users.length) {
      rows = props.users.map((user: IExistingUser) => (
        <tr key={`user-${user.id}`}>
          <td>
            <a href={`user/${user.id}`} onClick={editUser(user)} title={user.name}>{user.name}</a>
          </td>
          <td>{user.email}</td>
          <td>{roles[user.role]}</td>
          <td>{renderTeam(user)}</td>
          <td>{renderFirm(user)}</td>
        </tr>
      ));
    }
    return rows;
  };

  const renderFirm = (user: IExistingUser) => {
    if (user.role === 'info') {
      return findFirm(user.firm);
    }
    return null;
  };

  const renderTeam = (user: IExistingUser) => {
    if (user.role === 'teammember') {
      return findTeam(user.team);
    }
    return null;
  };

  const findFirm = (ids: string[]) =>
    convertToArray(ids).map((id: string) =>
      (firm.filter(item => `${item.id}` === `${id}`).pop() || { name: '' }).name,
    ).join(', ');

  const findTeam = (ids: string) =>
    convertToArray(ids).map((id: string) =>
      (team.filter(item => `${item.id}` === `${id}`).pop() || { name: '' }).name,
    ).join(', ');

  const editUser = (user: IExistingUser) => {
    return (event: any) => {
      event.preventDefault();
      setdisplayUserForm(true);
      setselectedUser(user);
    };
  };

  const cancelForm = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setdisplayUserForm(false);
    setselectedUser(null);
  };

  const userFormProps = {
    selectedUser,
    cancelForm,
  };

  return (
    <section className={`users ${!displayUserForm && 'fullwidth'}`}>
      {isLoading && <Spinner />}
      {!isLoading &&
        <div>
          {displayUserForm && <UserForm {...userFormProps} />}
          {!displayUserForm &&
          <>
            <a
              href="/user/add"
              onClick={(e: any) => {
                e.preventDefault();
                setdisplayUserForm(true);
              }}
              className="button addnew"
            >
              Felhasználó hozzáadása
            </a>
            <article className="fullwidth">
              <h2>Felhasználók</h2>
              <table>
                <thead>
                  <tr>
                    <th>Név</th>
                    <th>Email</th>
                    <th>Szerep</th>
                    <th>Csapat</th>
                    <th>Cég</th>
                  </tr>
                </thead>
                <tbody>
                  {renderUserRows()}
                </tbody>
              </table>
            </article>
          </>
          }
        </div>
      }
    </section>
  );
};

export default connector(Users);
