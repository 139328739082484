import React, { useEffect, useState } from 'react';
import { connector } from './OrderForm.connector';
import { IOrderFormPopupProps, IOrderFormProps, ITabProps } from './OrderForm.interface';
import Spinner from '../Image/Spinner';
import chaticon from '../../assets/images/icon_chat.png';
import './OrderForm.scss';
import Furnitures from './Tabs/Furnitures';
import DeliveryData from './Tabs/DeliveryData';
import DateData from './Tabs/DateData';
import Summary from './Tabs/Summary';
import General from './Tabs/General';
import Feedback from './Tabs/Feedback';
import { IOrder } from '../../views/Orders/Orders.interface';
import { defaultValues } from '../../views/Orders/Orders.constants';
import { getSums } from '../Common/Utils';
import Print from './Tabs/Print';
import { IFirm } from '../../views/Firms/Firms.interface';
import { get } from 'lodash';
import { IGroup } from '../../views/Groups/Groups.interface';
import { forceNumber, formatDate, formatNumbers } from '../Common/Helpers';
import OrderFormPopup from './OrderFormPopup';

// tslint:disable-next-line: variable-name
const OrderForm = (props: IOrderFormProps) => {
  const {
    selectedOrder,
    isLoading,
    cancelForm,
    firms,
    getOrders,
    user,
    calendarEvents,
    getCalendarEvents,
    getFirmGroups,
    firmGroups,
    updateFurnitures,
    updateCurrentOrder,
    currentOrder,
    setCurrentOrder,
    removeImage,
  } = props;
  const getSelectedFirm = (order: IOrder | null) => {
    if (!firms || !order) {
      return '';
    }
    const filteredFirms = firms.filter((ffirm: IFirm) =>
      user.roles.includes('admin') || user.firm.includes(`${ffirm.id}`));
    const selectedFirm = order ?
      filteredFirms.find((ffirm: IFirm) => `${ffirm.id}` === order.firm) : null;
    const userFirm = firms.find((ffirm: IFirm) => user.firm.includes(`${ffirm.id}`)) || null;
    const { id: firmid } = (selectedFirm || userFirm || { id: filteredFirms.pop()?.id || '' });
    return `${firmid}`;
  };
  const getNextAvailableDate = () => {
    const day = new Date();
    if ([0, 6].includes(day.getDay())) {
      const change = (8 - day.getDay()) % 7;
      day.setDate(day.getDate() + change);
    }
    return formatDate(day, '-');
  };
  if (user.onlyPreOrder) {
    defaultValues.date.book = true;
    defaultValues.date.group = true;
    defaultValues.date.actual_time = getNextAvailableDate();
  }
  const {
    id = '',
    firm,
    orderNr = defaultValues.orderNr,
    accountNr = defaultValues.accountNr,
    reclamation = defaultValues.reclamation,
    furnitures = [defaultValues.furnitures],
    deliveryData = defaultValues.deliveryData,
    date = defaultValues.date,
    summary = defaultValues.summary,
    feedback = get(currentOrder, 'feedback', undefined),
    sums = defaultValues.sums,
  } = currentOrder || {};
  const isEntitledToEdit = !user.roles.includes('teammember');
  const [tab, settab] = useState('Furnitures');
  const [isLocalLoading, setisLoading] = useState(false);
  const [isDirty, setisDirty] = useState(false);
  const [showDialog, setshowDialog] = useState(false);
  const stateObject: IOrder = {
    id,
    firm,
    orderNr,
    accountNr,
    reclamation,
    furnitures,
    deliveryData,
    date,
    summary,
    feedback,
    sums,
  };

  const getGroupEvents = (fgroup: IGroup) =>
    getCalendarEvents({
      group: forceNumber(fgroup.id || ''),
      year: new Date().getFullYear(),
    })
    .then((data:any) => {
      setisLoading(false);
      return get(data, 'payload.data.data', []);
    });

  useEffect(() => {
    setCurrentOrder({
      id: '',
      firm: getSelectedFirm(currentOrder),
      orderNr: defaultValues.orderNr,
      accountNr: defaultValues.accountNr,
      reclamation: defaultValues.reclamation,
      furnitures: [defaultValues.furnitures],
      deliveryData: defaultValues.deliveryData,
      date: defaultValues.date,
      summary: defaultValues.summary,
      feedback: undefined,
      sums: defaultValues.sums,
    });
    return () => {
      setCurrentOrder(null);
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedOrder?.id) {
      setCurrentOrder(selectedOrder);
    }
  // eslint-disable-next-linear_meter
  }, [selectedOrder]);

  useEffect(() => {
    if (firm === '') {
      setCurrentOrder({
        ...stateObject,
        firm: getSelectedFirm(currentOrder),
      });
    }
  // eslint-disable-next-line
  }, [firms]);

  useEffect(() => {
    if (isEntitledToEdit && firm) {
      setisLoading(true);
      getFirmGroups(firm)
      .then((response: any) => get(response, 'payload.data.data', []))
      .then((groupFirms: IGroup[]) => {
        Promise.all(groupFirms.map((fgroup: IGroup) => getGroupEvents(fgroup))).then(() => {
          setisLoading(false);
        });
      });
    }
  // eslint-disable-next-line
  }, [firm]);

  const saveFurnitures = (index: number) =>
    (key: string, value: number | string | boolean) => {
      updateFurnitures(index, key, value);
      setisDirty(true);
    };

  const addFurniture = (furniture: any) => {
    props.addFurniture(furniture);
    setisDirty(true);
  };

  const removeFurniture = (index: number) => () => {
    props.removeFurniture(index);
    setisDirty(true);
  };

  const updateOrder = (key: string) => (value: any) => {
    updateCurrentOrder(key, value);
    if (JSON.stringify(stateObject[key]) !== JSON.stringify(value)) {
      setisDirty(true);
    }
  };

  const functionMap: any = {
    id: isEntitledToEdit ? updateOrder('id') : () => null,
    firm: isEntitledToEdit ? updateOrder('firm') : () => null,
    orderNr: isEntitledToEdit ? updateOrder('orderNr') : () => null,
    accountNr: isEntitledToEdit ? updateOrder('accountNr') : () => null,
    reclamation: isEntitledToEdit ? updateOrder('reclamation') : () => null,
    furnitures: isEntitledToEdit ? updateOrder('furnitures') : () => null,
    deliveryData: isEntitledToEdit ? updateOrder('deliveryData') : () => null,
    date: isEntitledToEdit ? updateOrder('date') : () => null,
    summary: isEntitledToEdit ? updateOrder('summary') : () => null,
    saveFurnitures: isEntitledToEdit ? saveFurnitures : () => null,
    addFurniture: isEntitledToEdit ? addFurniture : () => null,
    removeFurniture: isEntitledToEdit ? removeFurniture : () => null,
  };

  const title = id ? 'Rendelés szerkesztése' : 'Új Rendelés létrehozása';

  const saveOrder = (redirect: boolean = true) => (event: any) => {
    event.preventDefault();
    stateObject.sums = getSums(stateObject);
    return props.saveOrder(stateObject)
      .then((data: any) => {
        settab('Summary');
        const orderId = get(data, 'payload.data.data', 0);
        const success = get(data, 'payload.data.success', false);
        if (success) {
          setshowDialog(true);
          stateObject.id = orderId;
          setCurrentOrder(stateObject);
        } else {
          alert('Sikertelen mentés');
        }
        if (redirect) {
          cancelForm();
        }
        setisDirty(false);
      });
  };

  const deleteOrder = (event: any) => {
    event.preventDefault();
    if (!window.confirm('Biztosan törölni akarod?')) {
      return false;
    }
    props.deleteOrder(id)
    .then(() => {
      getOrders();
      cancelForm();
    });
  };

  const isActive = (component: string) =>
    component === tab;

  const activeClass = (component: string) =>
    isActive(component) ? 'active' : '';

  const tabLinkProps = (component: string) => ({
    href: component,
    className: activeClass(component),
    onClick: tabClick(component),
  });

  const tabClick = (component: string) => (event: any) => {
    event.preventDefault();
    settab(component);
  };

  const {
    assemblyPrice,
    deliveryPrice,
    discount,
    sum,
  } = getSums(stateObject);

  const componentProps: ITabProps = {
    stateObject,
    functionMap,
    saveOrder,
    cancelForm,
    getOrders,
    firms,
    user,
    calendarEvents,
    getCalendarEvents,
    getFirmGroups,
    firmGroups,
    currentOrder,
    isDirty,
    removeImage,
  };

  const newOrder = () => {
    setCurrentOrder({
      id: '',
      firm: getSelectedFirm(null),
      orderNr: '',
      accountNr: defaultValues.accountNr,
      reclamation: defaultValues.reclamation,
      furnitures: [defaultValues.furnitures],
      deliveryData: defaultValues.deliveryData,
      date: defaultValues.date,
      summary: defaultValues.summary,
      feedback: undefined,
      sums: defaultValues.sums,
    });
  };

  const dialogProps: IOrderFormPopupProps = {
    firms,
    order: stateObject,
    cancel: cancelForm,
  };

  return (
    <>
      <article className="firms">
        {(id && isDirty) &&
          <div className="notsaved">
            <p>
              A megrendelés megváltozott, ne felejtsd el menteni a nyomtatáshoz!&nbsp;
              <a onClick={newOrder}> Új megrendeléshez klikk ide.</a>
            </p>
          </div>
        }
        {showDialog && <OrderFormPopup {...dialogProps} />}
        {(isLoading || isLocalLoading) && <Spinner />}
        {!(isLoading || isLocalLoading) &&
          <div className="UserFormContainer">
            <h2>{title}</h2>
            {id ? <i className="delete" onClick={deleteOrder} /> : null}
            <form encType="multipart/form-data">
              <General {...componentProps} />
              <ul className="tabhead">
                <li>
                  <a {...tabLinkProps('Furnitures')}>
                    Szállítmány
                    </a>
                </li>
                <li>
                  <a {...tabLinkProps('DeliveryData')}>
                    Szállítási adatok
                  </a>
                </li>
                {!user.onlyPreOrder && <li>
                  <a {...tabLinkProps('Date')}>
                    Időpont
                  </a>
                </li>}
                <li>
                  <a {...tabLinkProps('Summary')}>
                    Összegzés
                  </a>
                </li>
                {get(currentOrder, 'feedback', false) && <li>
                  <a {...tabLinkProps('Feedback')}>
                    Visszajelzés
                  </a>
                </li>}
              </ul>
              {isActive('Furnitures') &&
                <Furnitures {...componentProps} />}
              {isActive('DeliveryData') &&
                <DeliveryData {...componentProps} />}
              {isActive('Date') &&
                <DateData {...componentProps} /> }
              {isActive('Summary') &&
                <Summary {...componentProps} />}
              {isActive('Feedback') &&
                <Feedback {...componentProps} />}
              <Print {...componentProps} />
            </form>
          </div>
        }
      </article>
      <article className="helper">
        <div className="quicknumbers">
          <h3 className="allshipping">
            <span>Szállítás</span><strong>{formatNumbers(deliveryPrice)} Ft</strong>
          </h3>
          <h3 className="allwork">
            <span>Szerelés</span><strong>{formatNumbers(assemblyPrice)} Ft</strong>
          </h3>
          <h3 className="discount">
            <span>Engedmény</span><strong>{formatNumbers(discount)} Ft</strong>
          </h3>
          <h1 className="allfee">
            <span>Összesen</span><strong>{formatNumbers(sum)} Ft</strong>
          </h1>
        </div>
        <img src={chaticon} alt="chat" />
        <h4>Rendelés szerkesztése</h4>
        <p>1. A felvett bútorok típusai megjelennek a megrendelőlapon&nbsp;
          és segítik a kiszállítók munkáját.</p>
        <p>2. A szállítási adatok megadás után lekérhető a távolság,&nbsp;
          ami alapján extra km díj generálódik és a kiszállítási időt is megbecsüli.</p>
        <p>3. Az időpont kiválasztásánál pirossal jelöljük, ha egy&nbsp;
          megrendelés nem fér az adott napra.</p>
        <p>4. Az engedményeknél meg kell adni, ki engedélyezte azt.</p>
        <p>5. A mentés, nyomtatás és pdf letöltés csak akkor lehetséges, ha a kötelező&nbsp;
          adatok bevitele megtörtént (megrendelésszám, név, cím, telefon és időpont).</p>
      </article>
    </>
  );
};

export default connector(OrderForm);
