import React, { useState, useEffect } from 'react';
import { connector } from './Groups.connector';
import { IGroupsProps, IGroup } from './Groups.interface';
import Spinner from '../../components/Image/Spinner';
import GroupForm from '../../components/GroupForm/GroupForm';
import './Groups.scss';
import { workTypes } from './Groups.constants';

// tslint:disable-next-line: variable-name
const Groups = (props: IGroupsProps) => {
  const [displayGroupForm, setdisplayGroupForm] = useState(false);
  const [selectedGroup, setselectedGroup] = useState<null | IGroup>(null);
  const { getGroups, isLoading } = props;

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const renderGroupRows = (): JSX.Element[] => {
    let rows = [(<tr key="group-0"><td colSpan={3}>Nem található csapat.</td></tr>)];
    if (props.groups && props.groups.length) {
      rows = props.groups.map((group: IGroup) => (
        <tr key={`group-${group.id}`}>
          <td>
            <a href={`group/${group.name}`} onClick={editGroup(group)}>{group.name}</a>
          </td>
          <td>{(group.workTypes || []).map(type => workTypes[type]).join(', ')}</td>
          <td>{(group.firms || []).map(firm => (findFirm(firm))).join(', ')}</td>
        </tr>
      ));
    }
    return rows;
  };

  const findFirm = (id: string) =>
    (props.firms.filter(item => `${item.id}` === `${id}`).pop() || { name: '' }).name;

  const editGroup = (group: IGroup) => {
    return (event: any) => {
      event.preventDefault();
      setdisplayGroupForm(true);
      setselectedGroup(group);
    };
  };

  const cancelForm = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setdisplayGroupForm(false);
    setselectedGroup(null);
  };

  const groupGroupProps = {
    selectedGroup,
    cancelForm,
  };

  return (
    <section className={`groups ${!displayGroupForm && 'fullwidth'}`}>
      {isLoading && <Spinner />}
      {!isLoading &&
        <div>
          {displayGroupForm && <GroupForm {...groupGroupProps} />}
          {!displayGroupForm &&
            <>
              <a
                href="/group/add"
                onClick={(e: any) => {
                  e.preventDefault();
                  setdisplayGroupForm(true);
                }}
                className="button addnew"
              >
                Csapat hozzáadása
              </a>
              <article className="fullwidth">
                <h2>Csapatok</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Név</th>
                      <th>Típus</th>
                      <th>Cégek</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderGroupRows()}
                  </tbody>
                </table>
              </article>
            </>
          }
        </div>
      }
    </section>
  );
};

export default connector(Groups);
