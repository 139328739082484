import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { clients } from './config/clients';
import config from './config/config';
import rootReducer from './rootReducer';

const middlewares = [reduxThunk, multiClientMiddleware(clients, config)];
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default function configureStore() {
 return store;
}