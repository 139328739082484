import { connect } from 'react-redux';

const mapStateToProps = ({ appReducer: { selectedComponent, isNavOpen } }: any) => ({
  selectedComponent,
  isNavOpen,
});

const mapDispatchToProps = {
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
