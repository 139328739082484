// tslint:disable-next-line: no-unused-declaration
import React, { useState, useEffect } from 'react';
import {
  renderInput,
  renderRadioGroup,
  renderTextarea,
  renderOrderDetails,
  renderHiddenInput,
  renderFilesInput,
} from '../Common/Renderers';
import { fields, labels, feedbackTypes } from './Feedback.constants';
import chaticon from '../../assets/images/icon_chat.png';
import { connector } from './Feedback.connector';
import { IFeedbackProps } from './Feedback.interface';
import Spinner from '../Image/Spinner';

// tslint:disable-next-line: variable-name
const Feedback = (props: IFeedbackProps) => {
  const [feedbackType, setfeedbackType] = useState('');
  const [feedback, setfeedback] = useState('');
  const [images, setimages] = useState<any[]>([]);
  const { isLoading, selectedOrder, saveFeedback, cancelForm, firms } = props;
  const { id, firm } = selectedOrder;
  const { images: originalImages = [] } = selectedOrder.feedback || {};
  const { name: firmName } = firms.find(ffirm => `${ffirm.id}` === firm) || { name: '' };

  useEffect(() => {
    if (selectedOrder.feedback) {
      setfeedbackType(selectedOrder.feedback.feedbackType);
      setfeedback(selectedOrder.feedback.feedback);
    }
  }, [selectedOrder]);

  const stateObject: any = {
    id,
    feedbackType,
    feedback,
    images,
  };

  const functionMap: any = {
    id: () => false,
    feedbackType: setfeedbackType,
    feedback: setfeedback,
    images: setimages,
  };

  const renderInputFields = (item: string) => {
    switch (fields[item]) {
      case 'hidden':
        return renderHiddenInput(item, stateObject[item], functionMap[item]);
      case 'radio':
        return renderRadioGroup(
          item,
          labels[item],
          stateObject[item],
          feedbackTypes,
          functionMap[item],
        );
      case 'textarea':
        return renderTextarea(
          item,
          labels.feedback,
          feedback,
          functionMap[item],
        );
      case 'files':
        return renderFilesInput(
          item,
          labels.files,
          images,
          originalImages,
          functionMap[item],
        );
      case 'orderDetails':
        if (id) {
          return renderOrderDetails(selectedOrder, firmName);
        }
        break;
      default:
        return renderInput(
          'orderNr',
          labels[item],
          selectedOrder.orderNr,
          () => null,
          'inputtype2',
          'text',
        );
    }
  };

  const save = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    saveFeedback(stateObject)
      .then((response: any) => {
        alert(`A mentés ${response.payload.data.success ? 'sikeres' : 'sikertelen'} volt`);
        cancelForm();
      });
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading &&
        <>
          <article className="teamorder">
            <div className="UserFormContainer">
              <h2>Visszajelzés</h2>
              <form>
                {Object.keys(fields).map(renderInputFields)}
                <input
                  onClick={save}
                  type="submit"
                  value="Visszajelzés mentése"
                  className="button"
                />
                <input
                  onClick={cancelForm}
                  type="submit"
                  value="Mégsem"
                  className="button secondary"
                />
              </form>
            </div>
          </article>
          <article className="helper">
            <img src={chaticon} alt="chat" />
            <h4>Visszajelzés</h4>
            <p>1. Az adott megrendeléshez kapcsolható egy-egy visszajelzés.</p>
            <p>2. Képeket lehet a visszajelzéshez hozzáfűzni illetve szöveges&nbsp;
              formában leírni a problémát.</p>
            <p>3. Egy gyors státuszmegadással lehet konkretizálni a&nbsp;
              visszajelzés/reklamáció típusát.</p>
          </article>
        </>
      }
    </>
  );
};
export default connector(Feedback);
