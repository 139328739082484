import { connect } from 'react-redux';
import * as firmActions from './Firms.actions';

const mapStateToProps = ({ firmReducer }: any) => ({
  firms: firmReducer.firms,
  isLoading: firmReducer.isLoading,
});

const mapDispatchToProps = {
  ...firmActions,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
