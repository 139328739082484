import React, { Fragment } from 'react';
import { IFurniture, IOrder } from '../../views/Orders/Orders.interface';
import { ICalculations } from '../Calculations.interface';
import { forceNumber, formatNumbers } from '../../components/Common/Helpers';
import { furnitureTypes, kitchenClosetTypes, kitchenClosetTypesrint, kitchenToolTypes, labels } from '../../views/Orders/Orders.constants';
import { cloneDeep, floor } from 'lodash';
import { renderCheckbox, renderInput } from '../../components/Common/Renderers';
import { IFurnitureProps } from '../../components/OrderForm/OrderForm.interface';

const basePrices: any = {
  couch: 10000,
  bed: 15000,
  furniture: 5000,
  kitchenSurvey: 0,
  kitchen: 0,
  worksheet: 12000,
  kitchenTool: 5000,
  modifyCabinet: 10000,
  delivery: 10000,
  wallCloset: 5500,
  floorCloset: 6500,
  highCloset: 6500,
  other: 2500,
  onlyAssembly: 13900,
};

const editableFields: string[] = [];
const kmCost: number = 280;
const baseDistance: number = 20;
const amounts: number[] =
  [50000, 100000, 250000, 500000, 750000, 1000000, 1500000, 2000000, 2500000, 3000000];
const ship: number[] = [6600, 16600, 26600, 33600, 37600, 42600, 57600, 72600, 87600, 102600];
const work: number[] = [16000, 12000, 13000, 42000, 61000, 72000, 102400, 137400, 177400, 193400];

export const calculateByValue = (price: number) => {
  const base = floor(price / 3000000) * work[work.length - 1];
  const workPrice = work[amounts.findIndex(amount => amount >= (price % 3000000))];
  return base + workPrice;
};
const calculatePlannedKitchen = (price: number) =>
  price < 800000 ? 88000 : price < 3000000 ? price * 0.11 : 330000;

export const calculateAssemblyPrice = (furniture: IFurniture) => {
  let price = 0;
  if (furniture.type === 'kitchenSurvey') {
    price = basePrices.kitchenSurvey;
  } else if (furniture.assembly) {
    const calculatedByValue = furniture.price * 0.10;
    switch (furniture.type) {
      case 'couch':
      case 'bed':
        price = furniture.quantity * basePrices[furniture.type];
        break;
      case 'furniture':
      case 'bedCouch':
      case 'plannedKitchen':
      case 'kitchen':
        price = calculateByValue(furniture.price) || calculatedByValue;
        if (furniture.type === 'plannedKitchen') {
          price = calculatePlannedKitchen(furniture.price);
        }
        if (furniture.type === 'kitchen') {
          price = basePrices.kitchen;
          [
            'wallCloset',
            'floorCloset',
            'highCloset',
            'other',
          ].forEach((item: string) => {
            if (furniture[item]) {
              price += (furniture[item] * basePrices[item]);
            }
          });
        }
        if (furniture.worksheet) {
          price += basePrices.worksheet;
        }
        if (furniture.kitchenTools) {
          const count = Object.keys(furniture.kitchenToolTypes)
            .filter(item => item !== 'owen')
            .filter(item => !!furniture.kitchenToolTypes[item]).length;
          price += (count * basePrices.kitchenTool);
        }
        if (furniture.modifyCabinet) {
          price += (furniture.modifyCabinetCount * basePrices.modifyCabinet);
        }
        break;
    }
  }
  return price;
};

export const getShippingPrice = (price: number) => {
  const base = floor(price / 3000000) * ship[ship.length - 1];
  const index = amounts.findIndex(amount => amount >= (price % 3000000));
  return base + ship[index];
};

const calculateDeliveryPrice = ({ furnitures = [], isDelivery = false }: any) => {
  let basePrice = 0;
  const furniturePrices = furnitures
    .filter((furniture: IFurniture) => !furniture.own_delivery)
    .reduce((prevValue: number, currentItem: IFurniture) =>
      prevValue + currentItem.price, 0);
  if (!isDelivery) {
    basePrice = basePrices.onlyAssembly;
  } else {
    basePrice = getShippingPrice(furniturePrices);
  }
  return basePrice;
};

const getKmCost = () => kmCost;

const getExtraCost = ({ distance }: any) => {
  let extradist = 0;
  if (distance > baseDistance) {
    extradist = (Math.round(distance) - baseDistance) * kmCost;
  }
  return extradist;
};

const orderFormTexts: string[] = [
  // `<b>Ülőgarnitúrák szerelése:</b> szállítási díj + ${formatNumbers(basePrices.couch)},- Ft`,
  // `<b>Kárpitozott ágyak szerelése:</b> szállítási díj + ${formatNumbers(basePrices.bed)},- Ft`,
  `Amennyiben a beszerelni-beépíteni kívánt konyhaeszközöket nem az Áruháztól vásárolták, úgy eszközönként + bruttó ${formatNumbers(basePrices.kitchenTool)},- Ft kerül felszámításra. Az árak nem tartalmazzák az áram-, víz- és gázbekötéseket. Amennyiben a bútorok szereléséhez - helyszínhez kötött okból kifolyólag - a gyártó által biztosított szerelési egységcsomag nem elegendő, úgy a szereléshez szükséges egyéb anyagok beszerzésének költsége a Megrendelőt terheli.`,
  '',
  `Az áruház ${baseDistance} km-es zónáján kívül eső szállításoknál az alapdíjon felül ${kmCost},- Ft/km felárat számolunk fel. A szállítási árak tartalmazzák az emeletre történő szállítást. Az árak forintban értendők és az ÁFÁ-t tartalmazzák. Az esetleges parkolási díj, komp díj, behajtási engedély díja és beszerzése a megrendelőt terheli.`,
];

export const getCommentFields = (item: IFurniture) => {
  const comments: string[] = [];
  const kitchenTools: string[] = [];
  const kitchenClosets: any = {
    wallCloset: 0,
    floorCloset: 0,
    highCloset: 0,
    other: 0,
  };
  if (item.type === 'kitchen') {
    ['wallCloset', 'floorCloset', 'highCloset', 'other'].forEach((closet: string) => {
      if (item[closet]) {
        kitchenClosets[closet] += forceNumber(item[closet]);
      }
    });
    const closets = Object.keys(kitchenClosets)
    .map(mitem =>
      kitchenClosets[mitem] ? `${kitchenClosets[mitem]}db ${kitchenClosetTypesrint[mitem]}` : '')
    .filter(fitem => !!fitem);
    comments.push(`\
    Konyhaszekrények: ${closets.join(', ')}`);
  }
  if (item.kitchenTools) {
    Object.keys(item.kitchenToolTypes)
      .filter((tool: string) => item.kitchenToolTypes[tool])
      .forEach((tool: string) => {
        kitchenTools.push(kitchenToolTypes[tool]);
      });
    comments.push(`Eszközök beépítése (${Array.from(new Set(kitchenTools)).join(', ')})`);
  }
  if (item.worksheet) {
    comments.push('Hátfal panel szerelés');
  }
  if (item.modifyCabinet) {
    comments.push(`Szekrényátalakítás (${item.modifyCabinetCount}db)`);
  }
  return comments;
};

const enabledFurnitureTypes: string[] = [
  'furniture',
  'kitchen',
  'plannedKitchen',
  'kitchenSurvey',
  'bed',
  'couch',
];

const getKitchenFields = (props: IFurnitureProps) => {
  const {
    type,
    worksheet,
    kitchenTools,
    modifyCabinet,
    modifyCabinetCount,
    kitchenToolTypes,
  } = props;
  const callback = (key: string) => (value: number | string | boolean) =>
    props.callback(key, value);
  const updateKitchenTools = (key: string) => (value: boolean) => {
    const newObject: any = cloneDeep(kitchenToolTypes);
    newObject[key] = value;
    callback('kitchenToolTypes')(newObject);
  };
  const isKitchen = type === 'kitchen';
  const isPlannedKitchen = type === 'plannedKitchen';
  if (!isKitchen && !isPlannedKitchen) {
    return null;
  }
  return (
    <div className="momax" id="csakkonyha">
      <div className="" id="szekrenytipus">
        {isKitchen &&
          Object.keys(kitchenClosetTypes).map((key: string) =>
            renderInput(
              key,
              `${kitchenClosetTypes[key]} (db)`,
              props[key],
              callback(key),
              'inputtype2',
              'number',
            ),
          )
        }
      </div>
      {renderCheckbox(
        'worksheet',
        labels.furnitures.worksheet,
        worksheet,
        callback('worksheet'),
      )}
      {renderCheckbox(
        'kitchenTools',
        labels.furnitures.kitchenTools,
        kitchenTools,
        callback('kitchenTools'),
      )}
      {renderCheckbox(
        'modifyCabinet',
        labels.furnitures.modifyCabinet,
        modifyCabinet,
        callback('modifyCabinet'),
      )}
      {kitchenTools &&
        <div className="checkbox">
          <hr />
          {Object.keys(kitchenToolTypes).map((key: string) => (
            <label
              htmlFor={key}
              key={`tool-${key}`}
              onClick={() => updateKitchenTools(key)(!kitchenToolTypes[key])}
            >
              <input
                type="checkbox"
                name={key}
                checked={kitchenToolTypes[key]}
              />
              {kitchenToolTypes[key]}
            </label>
          ))}
        </div>
      }
      {modifyCabinet &&
        <div className="" id="szekreny">
          <hr />
          {renderInput(
            'modifyCabinetCount',
            labels.furnitures.modifyCabinetCount,
            modifyCabinetCount,
            callback('modifyCabinetCount'),
            'inputtype2',
          )}
        </div>
      }
    </div>
  );
};

const renderElementForPrinting = (order: IOrder, details: (item: IFurniture) => string[]) => {
  return order.furnitures.map((furniture: IFurniture, index: number) => (
    <Fragment key={`orderitem-${Date.now()}`}>
      <tr>
        <td colSpan={5} className="padding5"></td>
      </tr>
      <tr>
        <td rowSpan={2} className="itemNumber">
          <b>{index + 1}.</b>
        </td>
        <td className="itemGap"></td>
        <td>
          <b>{furnitureTypes[furniture.type]}</b>&nbsp;{furniture.quantity > 1 ? `${furniture.quantity} db ` : ''}
          (bútor ára: {formatNumbers(furniture.price)} Ft{furniture.own_delivery === true && ', ügyfél szállítja ki'})
        </td>
        <td className="itemDetails">
          {furniture.assembly_price ?
            <>
              Szerelési ár: <span className="pszer">{formatNumbers(furniture.assembly_price)} Ft</span>
            </> : null
          }
          {(!furniture.assembly_price && !furniture.own_delivery) && <>CSAK SZÁLLÍTÁS</>}
        </td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={2}>
          Szerelés részletezve: <span className="pmeg">{details(furniture)}</span>
        </td>
        <td></td>
      </tr>
    </Fragment>
  ));
};

const defaultFurniture = {
  price: 0,
  quantity: 1,
  type: '',
  assembly: false,
  own_delivery: false,
  assembly_price: 0,
  linear_meter: 0,
  worksheet: false,
  kitchenTools: false,
  modifyCabinet: false,
  modifyCabinetCount: 0,
  wallCloset: 0,
  floorCloset: 0,
  highCloset: 0,
  other: 0,
  kitchenToolTypes: {
    hotplate: false,
    sink: false,
    dishwasher: false,
    cooler: false,
    dehumidifier: false,
  },
};

const applyAssemblyDiscount = () => 0;

const calculation: ICalculations = {
  getKmCost,
  getExtraCost,
  baseDistance,
  calculateAssemblyPrice,
  calculateDeliveryPrice,
  orderFormTexts,
  editableFields,
  getCommentFields,
  enabledFurnitureTypes,
  getKitchenFields,
  renderElementForPrinting,
  defaultFurniture,
  applyAssemblyDiscount,
  basePrice: basePrices,
};

export default calculation;
