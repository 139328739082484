import { IUserForm } from './UserForm.interface';

export const USER_FORM_ACTIONS = {
  SAVE_USER: '[USER_FORM] SAVE_USER',
  SUCCESS: {
    SAVE_USER: '[USER_FORM] SAVE_USER_SUCCESS',
  },
  FAIL: {
    SAVE_USER: '[USER_FORM] SAVE_USER_FAIL',
  },
};

export const saveUser = (data: IUserForm) => {
  return {
    payload: {
      request: {
        data,
        method: 'POST',
        url: 'wp-admin/admin-ajax.php?action=saveUser',
      },
    },
    type: USER_FORM_ACTIONS.SAVE_USER,
  };
};
