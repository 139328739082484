import React, { useState } from 'react';
import config from '../../../package.json';
import { INavItem, INavigationProps } from './Navigaton.interface';
import './Navigaton.scss';
import { connector } from './Navigaton.connector';
import logo from '../../assets/images/drspeed_logo.png';
import { IUserObject } from '../../views/Users/Users.interface';
import siteConfig from '../../config/siteConfig';

// tslint:disable-next-line: variable-name
const Navigaton = (props: INavigationProps) => {
  const [selected, setselected] = useState('');

  const renderNavigationItems = (type: string) => (item: INavItem) =>
    isEntitled((item.role || []), props.user) ?
      (<li key={`navitem-${item.key}`}>
        <a
          href={`nav/${item.key}`}
          onClick={(e) => { e.preventDefault(); handleClick(item); }}
          className={`
            ${selected === item.id ? 'active' : ''}
            ${item.className}
          `}
        >
          {item.icon ?
            <img src={require(`../../assets/images/${item.icon}`)} alt={item.label} /> : null}
          {(type === 'secondary' ? <span>{item.label}</span> : item.label)}
        </a>
        {item.children && item.children.length ?
          <ul>{item.children.map(renderNavigationItems('secondary'))}</ul> : null}
      </li>) : null;

  const isEntitled = (roles: string[], user: IUserObject) =>
    user.roles[0] === 'administrator' || !roles.length || roles.includes(user.roles[0]);

  const handleClick = (item: INavItem) => {
    if (item.className === 'inactive') {
      return false;
    }
    if (item.key === 'Logout') {
      props.logout();
    } else {
      if (/Mobi/i.test(window.navigator.userAgent)) {
        props.toggleMobileNav();
      }
      props.updateView('');
      setTimeout(() => {
        props.updateView(item.key, item.params);
        setselected(item.id);
      }, 1);
    }
  };

  return (
    <div className={`navigation trans05 ${props.isNavOpen ? 'opened' : ''}`}>
      <img src={logo} alt="logo" className="logo" />
      <ul>
        {siteConfig.navigationItems.map(renderNavigationItems('primary'))}
      </ul>
      <i>Verzió: {config.version}</i>
    </div>
  );
};

export default connector(Navigaton);
