import { IOrder } from './Orders.interface';
import { IFeedback } from '../../components/Feedback/Feedback.interface';

export const ORDERS_ACTION = {
  UPDATE_DATE: '[ORDERS] UPDATE_DATE',
  SET_ORDER: '[ORDERS] SET_ORDER',
  UPDATE_ORDER: '[ORDERS] UPDATE_ORDER',
  UPDATE_FURNITURE: '[ORDERS] UPDATE_FURNITURE',
  ADD_FURNITURE: '[ORDERS] ADD_FURNITURE',
  REMOVE_FURNITURE: '[ORDERS] REMOVE_FURNITURE',
  GET_ORDER_LIST: '[ORDERS] GET_ORDER_LIST',
  GET_DAILY_ORDER_LIST: '[ORDERS] GET_DAILY_ORDER_LIST',
  SAVE_ORDER: '[ORDERS] SAVE_ORDER',
  SAVE_FEEDBACK: '[ORDERS] SAVE_FEEDBACK',
  DELETE_ORDER: '[ORDERS] DELETE_ORDER',
  EXPORT_ORDERS: '[ORDERS] EXPORT_ORDERS',
  REMOVE_IMAGE: '[ORDERS] REMOVE_IMAGE',
  SUCCESS: {
    GET_ORDER_LIST: '[ORDERS] GET_ORDER_LIST_SUCCESS',
    SAVE_ORDER: '[ORDERS] SAVE_ORDER_SUCCESS',
    SAVE_FEEDBACK: '[ORDERS] SAVE_FEEDBACK_SUCCESS',
    DELETE_ORDER: '[ORDERS] DELETE_ORDER_SUCCESS',
    EXPORT_ORDERS: '[ORDERS] EXPORT_ORDERS_SUCCESS',
    REMOVE_IMAGE: '[ORDERS] REMOVE_IMAGE_SUCCESS',
  },
  FAIL: {
    GET_ORDER_LIST: '[ORDERS] GET_ORDER_LIST_FAIL',
    SAVE_ORDER: '[ORDERS] SAVE_ORDER_FAIL',
    SAVE_FEEDBACK: '[ORDERS] SAVE_FEEDBACK_FAIL',
    DELETE_ORDER: '[ORDERS] DELETE_ORDER_FAIL',
    EXPORT_ORDERS: '[ORDERS] EXPORT_ORDERS_FAIL',
    REMOVE_IMAGE: '[ORDERS] REMOVE_IMAGE_FAIL',
  },
};

export const getOrders = (
  startDate: string,
  endDate: string,
  useRedux = true,
  firm = '',
  query = '',
) => {
  let type = ORDERS_ACTION.GET_ORDER_LIST;
  if (!useRedux) {
    type = ORDERS_ACTION.GET_DAILY_ORDER_LIST;
  }
  return {
    type,
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php',
        params: {
          startDate,
          endDate,
          firm,
          query,
          action: 'getOrders',
        },
      },
    },
  };
};

export const exportOrders = (startDate: string, endDate: string) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php',
        params: {
          startDate,
          endDate,
          action: 'exportOrders',
        },
      },
    },
    type: ORDERS_ACTION.EXPORT_ORDERS,
  };
};

export const saveOrder = (order: IOrder) => {
  const bodyFormData = jsonToFormData(order);
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=saveOrder',
        method: 'POST',
        data: bodyFormData,
      },
    },
    type: ORDERS_ACTION.SAVE_ORDER,
  };
};

export const saveFeedback = (feedback: IFeedback) => {
  const bodyFormData = new FormData();
  Object.keys(feedback).forEach((item: string) => {
    if (item === 'images') {
      Array.from(feedback.images).forEach((image) => {
        bodyFormData.append(`${item}[]`, image);
      });
    } else {
      bodyFormData.append(item, feedback[item]);
    }
  });
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=saveFeedback',
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    },
    type: ORDERS_ACTION.SAVE_FEEDBACK,
  };
};

export const deleteOrder = (orderId: string) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=deleteOrder',
        method: 'POST',
        data: {
          id: orderId,
        },
      },
    },
    type: ORDERS_ACTION.DELETE_ORDER,
  };
};

export const removeImage = (orderId: string, index: number) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=removeImage',
        method: 'POST',
        data: {
          index,
          id: orderId,
        },
      },
    },
    type: ORDERS_ACTION.REMOVE_IMAGE,
  };
};

export const updateDate = (label: string, value: string) => ({
  label,
  value,
  type: ORDERS_ACTION.UPDATE_DATE,
});

export const setCurrentOrder = (order: IOrder | null) => ({
  order,
  type: ORDERS_ACTION.SET_ORDER,
});

export const updateCurrentOrder = (property: string, value: any) => ({
  property,
  value,
  type: ORDERS_ACTION.UPDATE_ORDER,
});

export const updateFurnitures = (key: number, property: string, value: string) => ({
  property,
  key,
  value,
  type: ORDERS_ACTION.UPDATE_FURNITURE,
});

export const addFurniture = (furniture: any) => ({
  furniture,
  type: ORDERS_ACTION.ADD_FURNITURE,
});

export const removeFurniture = (key: number) => ({
  key,
  type: ORDERS_ACTION.REMOVE_FURNITURE,
});

const buildFormData = (formData: any, data: any, parentKey: any = null) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data === null ? '' : data;
    formData.append(parentKey, value);
  }
};

const jsonToFormData = (data: any) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};
