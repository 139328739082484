import { connect } from 'react-redux';
import { updateView, logout, toggleMobileNav } from '../App/App.actions';

const mapStateToProps = ({ appReducer: { user, isNavOpen } }: any) => ({
  isNavOpen,
  user,
});

const mapDispatchToProps = {
  logout,
  toggleMobileNav,
  updateView,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
