import { connect } from 'react-redux';
import * as groupActions from './Groups.actions';

const mapStateToProps = ({ groupReducer }: any) => ({
  groups: groupReducer.groups,
  firms: groupReducer.firms,
  isLoading: groupReducer.isLoading,
});

const mapDispatchToProps = {
  ...groupActions,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
