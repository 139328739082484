import React from 'react';
import { ICalendarPopupProps } from './Calendar.interface';

// tslint:disable-next-line: variable-name
export const CalendarPopup = (props: ICalendarPopupProps) => {
  const { selectedDays, cancel, saveCalendar, group, type, year } = props;

  const addCalendarEvent = (value: number) =>
    (event: any) => {
      event.preventDefault();
      saveCalendar({
        group,
        type,
        value,
        selectedDays,
        year,
      }).then(() => {
        cancel();
      });
    };

  return (
    <div className="actionspanel">
      <div className="apinside">
        <h5>{selectedDays.length} nap kijelölve</h5>
        <input onClick={addCalendarEvent(0)} type="submit" value="0 óra" className="button b3" />
        <input onClick={addCalendarEvent(5)} type="submit" value="5 óra" className="button b2" />
        <input onClick={addCalendarEvent(8)} type="submit" value="8 óra" className="button b2" />
        <input onClick={addCalendarEvent(10)} type="submit" value="10 óra" className="button" />
        <input onClick={cancel} type="submit" value="Mégsem" className="button secondary" />
      </div>
    </div>
  );
};
