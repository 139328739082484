export const APP_ACTION = {
  GET_LOGIN_DATA: '[APP] GET_LOGIN_DATA',
  UPDATE_VIEW: '[APP] UPDATE_VIEW',
  TOGGLE_MOBILE_NAV: '[APP] TOGGLE_MOBILE_NAV',
  LOGOUT: '[APP] LOGOUT',
  GET_ZIPS: '[APP] GET_ZIPS',
  SUCCESS: {
    GET_LOGIN_DATA: '[APP] GET_LOGIN_DATA_SUCCESS',
    LOGOUT: '[APP] LOGOUT_SUCCESS',
    GET_ZIPS: '[APP] GET_ZIPS_SUCCESS',
  },
  FAIL: {
    GET_LOGIN_DATA: '[APP] GET_LOGIN_DATA_FAIL',
    LOGOUT: '[APP] LOGOUT_SUCCESS',
    GET_ZIPS: '[APP] GET_ZIPS_SUCCESS',
  },
};

export const getLoginData = () => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getLoginData',
      },
    },
    type: APP_ACTION.GET_LOGIN_DATA,
  };
};

export const logout = () => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=logout',
      },
    },
    type: APP_ACTION.LOGOUT,
  };
};

export const getZips = () => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getZips',
      },
    },
    type: APP_ACTION.GET_ZIPS,
  };
};

export const updateView = (view: string, params?: string) => ({
  view,
  params,
  type: APP_ACTION.UPDATE_VIEW,
});

export const toggleMobileNav = () => ({
  type: APP_ACTION.TOGGLE_MOBILE_NAV,
});
