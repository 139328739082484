import { IFirm } from '../../views/Firms/Firms.interface';
import { IGroup } from '../../views/Groups/Groups.interface';
import { IFurniture } from '../../views/Orders/Orders.interface';

export const forceNumber = (value: string | number) =>
  parseInt(`${value}`.replace(/\s+/g, '') || '0', 10);

export const formatNumbers = (
  n: number,
  a: number = 0,
  c: string = ' ',
) => {
  return forceNumber(n).toFixed(a).replace(/(\d)(?=(\d{3})+\b)/g, `$1${c}`);
};

export const formatDate = (date: any, glue: string = '-') => {
  return `${date.getFullYear()}${glue}${padding(date.getMonth() + 1)}${glue}${padding(date.getDate())}`;
};

export const formatTime = (date: any) => {
  return `${formatDate(date, '.')} ${padding(date.getHours())}:${padding(date.getMinutes())}`;
};

export const needDelivery = (furnitures: IFurniture[]) =>
  furnitures.some((currentItem: IFurniture) => !currentItem.own_delivery);

export const needAssembly = (furnitures: IFurniture[]) =>
  furnitures.some((currentItem: IFurniture) => currentItem.assembly);

export const padding = (input: number | string) =>
` 0${input}`.slice(-2);

export const getTypeOrder = (workTypes: string[]) => {
  let value = 0;
  if (workTypes.includes('delivery')) { value += 1; }
  if (workTypes.includes('assembly')) { value += 2; }
  if (workTypes.includes('plannedAssembly')) { value += 4; }
  return value;
}

export const getFilteredTeam = (teams: IGroup[], id: number): IGroup | undefined =>
  teams.find((team: IGroup) => team.id === id);

export const getFilteredShop = (shops: IFirm[], id: number): IFirm | undefined =>
  shops.find((shop: IFirm) => shop.id === id);

export const getShop = (shops: IFirm[], id: number) =>
  (getFilteredShop(shops, id) || { name: 'Nincs találat' }).name;
