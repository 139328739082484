import React, { useState, useEffect } from 'react';
import { ICalendarProps } from './Calendar.interface';
import { IOrder } from '../Orders/Orders.interface';
import { formatDate } from '../../components/Common/Helpers';
import { connector } from './Calendar.connector';
import Spinner from '../../components/Image/Spinner';
import OrderForm from '../../components/OrderForm/OrderForm';
import {
  filterByFirm,
  filterOrders,
  filterByDate,
  getDayLabel,
  addDateDiff,
  getFirmsLengthOnDate,
  mapOrders,
  filterByTime,
  needPlaceholder,
  getFirms,
  mapLegend,
  filterBookings,
  filterFirms,
  mapFilter,
  filterByTeam,
  filterByCity,
  getCities,
  mapCityFilter,
} from './Calendar.utils';
import CalendarPopup from './CalendarPopup';
import Feedback from '../../components/Feedback/Feedback';
import { IGroup } from '../Groups/Groups.interface';
import { isTeamMemberUser, isAdminUser, isInfoUser } from '../../components/Common/Utils';

// tslint:disable-next-line: variable-name
const CalendarWeek = (props: ICalendarProps) => {
  const monday = new Date();
  const diff = monday.getDay() === 0 ? 6 : monday.getDay() - 1;
  monday.setDate(monday.getDate() - diff);
  const [selectedOrder, setselectedOrder] = useState<null | IOrder>(null);
  const [selectedDate, setSelectedDate] = useState(monday);
  const [displayForm, setform] = useState(false);
  const [displayFeedbackForm, setdisplayFeedbackForm] = useState(false);
  const [selectedFirms, setselectedFirms] = useState<string[]>([]);
  const [selectedCity, setselectedCity] = useState('');
  const { getOrders, isLoading, firms, selectedComponent, user, getGroups, groups, orders } = props;
  const isTeamMember = isTeamMemberUser(user);
  const isAdmin = isAdminUser(user);
  const isInfo = isInfoUser(user);
  const morningLabel = 'Délelőtt (8-14 óráig)';
  const afternoonLabel = 'Délután (13-18 óráig)';

  useEffect(() => {
    if (selectedComponent === 'CalendarWeek') {
      getGroups();
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedComponent === 'CalendarWeek') {
      getOrdersFn();
    }
  // eslint-disable-next-line
  }, [selectedDate]);

  const getOrdersFn = () => {
    const endDate = new Date(selectedDate);
    endDate.setDate(endDate.getDate() + 6);
    getOrders(formatDate(selectedDate), formatDate(endDate));
  };

  const updateDate = (value: number) => () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + value);
    setSelectedDate(newDate);
    setselectedFirms([]);
    setselectedCity('');
  };

  const filterInfo = (order: IOrder) => isInfo ? user.firm.includes(order.firm) : true;

  const cancel = (event: any = { preventDefault: () => null }) => {
    event.preventDefault();
    setform(false);
    setdisplayFeedbackForm(false);
    setselectedOrder(null);
  };

  const edit = () => {
    if (isTeamMember) {
      setdisplayFeedbackForm(true);
    } else {
      setform(true);
    }
  };

  const popupProps = {
    cancel,
    edit,
    user,
    order: selectedOrder,
  };

  const editProps = {
    selectedOrder,
    cancelForm: cancel,
  };

  const nameMap: any = {
    delivery: 'Szállítási Naptár',
    assembly: 'Szerelési Naptár',
  };

  const filteredOrders = orders.filter(filterInfo);
  const filteredFirms = getFirms(filteredOrders);

  // tslint:disable-next-line: max-line-length
  const getFilteredOrders = (firm: string, type: string, index: string, time: string, team: number) =>
    orders
      .filter(filterInfo)
      .filter(filterByFirm(firm))
      .filter(filterBookings)
      .filter(filterOrders(type))
      .filter(filterByDate(addDateDiff(selectedDate, index)))
      .filter(filterByTime(time))
      .filter(filterByTeam(team));

  // tslint:disable-next-line: max-line-length
  const mapFilteredOrders = (firm: string, type: string, index: string, time: string, team: number) =>
    getFilteredOrders(firm, type, index, time, team)
      .map(mapOrders(firms, setselectedOrder));

  const filterGroups = (firm: string, type: string, index: string) =>
    (groups || [])
      .filter((team: IGroup) => team.firms.includes(firm))
      .filter((team: IGroup) =>
        getFilteredOrders(firm, type, index, morningLabel, team.id || 0).length
        || getFilteredOrders(firm, type, index, afternoonLabel, team.id || 0).length,
      );

  const renderCalendar = (type: string) => (
    <div className="mycalendar" key={`calendar-${type}`}>
      <h6>{nameMap[type]}</h6>
      <div className="mc-legend">
        <em><i /></em>
        <em><i>9.00</i><i>10.00</i><i>11.00</i><i>12.00</i><i>13.00</i></em>
        <em><i>14.00</i><i>15.00</i><i>16.00</i><i>17.00</i><i>18.00</i></em>
      </div>
      {Object.keys([...Array(7)]).map((index: string) => (
        <div className="mc-day" key={`day-${index}`}>
          <div className="calbase">
            <em>{getDayLabel(addDateDiff(selectedDate, index))}</em>
            <em><i /><i /><i /><i /><i /></em>
            <em><i /><i /><i /><i /><i /></em>
          </div>
          <div className="slotholder">
            {
            // tslint:disable-next-line: max-line-length
            getFirmsLengthOnDate(filteredOrders, type, addDateDiff(selectedDate, index), selectedFirms)
              .map((firm: string) => (
                <div
                  className="firmholder"
                  key={`firm-${firm}`}
                  style={{ width: `${Math.floor(
                    100 / getFirmsLengthOnDate(filteredOrders, type, addDateDiff(selectedDate, index), selectedFirms).length,
                  )}%` }}
                >
                  {filterGroups(firm, type, index).map((team: IGroup) => (
                    <div
                      className="teamholder"
                      key={`team-${firm}-${team.id}`}
                      // tslint:disable-next-line: max-line-length
                      style={{ width: `${Math.floor(100 / filterGroups(firm, type, index).length)}%` }}
                    >
                      { mapFilteredOrders(firm, type, index, morningLabel, team.id || 0) }
                      <span
                        key={`dp-${Date.now()}`}
                        style={{ height: `${needPlaceholder(
                          getFilteredOrders(firm, type, index, morningLabel, team.id || 0),
                          getFilteredOrders(firm, type, index, afternoonLabel, team.id || 0),
                        )}px` }}
                        className="placeholder"
                      >
                        <b>Szabad hely</b><br />
                      </span>
                      { mapFilteredOrders(firm, type, index, afternoonLabel, team.id || 0) }
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );

  const changeFirm = (event: any) => {
    let newValue: string[] = [];
    if (event.target.value) {
      newValue = [event.target.value];
    }
    setselectedFirms(newValue);
    setselectedCity('');
  };

  const changeCity = (event: any) => {
    const teams = filteredFirms.filter(filterByCity(firms, event.target.value));
    setselectedFirms(teams);
    setselectedCity(event.target.value);
  };

  return (
    <section className={`calendar ${(displayForm || displayFeedbackForm) ? '' : 'fullwidth'}`}>
      {isLoading && <Spinner />}
      {!isLoading && !(displayForm || displayFeedbackForm) &&
        <article>
          <div className="navbuttons">
            <input
              type="submit"
              value="Előző"
              className="button secondary"
              onClick={updateDate(-7)}
            />
            <input
              type="submit"
              value="Következő"
              className="button secondary"
              onClick={updateDate(7)}
            />
            {isAdmin &&
              <div className="filters">
                <label className="inputtype2 selectbox companyChanger">
                  <span>Áruház:</span>
                  <select onChange={changeFirm} value={selectedFirms} multiple={true}>
                    <option value="">Összes</option>
                    {filteredFirms.map(mapFilter(firms))}
                  </select>
                </label>
                {<label className="inputtype2 selectbox">
                  <span>Város:</span>
                  <select onChange={changeCity} value={selectedCity}>
                    <option value="">Összes</option>
                    {getCities(filteredFirms, firms).map(mapCityFilter)}
                  </select>
                </label>}
              </div>
            }
          </div>
          {Object.keys(nameMap)
            .map(renderCalendar)}
          <div className="calendarhelper">
            {filteredFirms.filter(filterFirms(selectedFirms)).map(mapLegend(firms))}<br />
            <span className="reclamation">Reklamáció</span>
            <span className="e-kitchen">Konyhaszerelés, konyha felmérés</span>
          </div>
        </article>
      }
      {!(displayForm || displayFeedbackForm) && selectedOrder &&
        <CalendarPopup { ...popupProps } />}
      {displayForm && <OrderForm {...editProps} />}
      {displayFeedbackForm && <Feedback {...editProps} />}
    </section>
  );
};

export default connector(CalendarWeek);
