import React from 'react';
import { contactTypes } from '../../views/Firms/Firms.constants';
import { cloneDeep, get } from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import hu from 'date-fns/locale/hu';
import { IOrder, IFurniture } from '../../views/Orders/Orders.interface';
import { calculateFurnituresPrice, getAddress } from './Utils';
import { furnitureTypes } from '../../views/Orders/Orders.constants';
import { Calendar } from './Calendar/Calendar';
import { ICalendarEvents } from './Calendar/Calendar.interface';
import { IImage } from '../Feedback/Feedback.interface';
import pdfIcon from '../../assets/images/pdf.png';
import { formatDate, formatNumbers } from './Helpers';
registerLocale('hu', hu);
export const renderInput =
  (
    item: string,
    label: string,
    value: string | number,
    changeFN: (e: any) => void,
    className: string = '',
    type: string = 'text',
    keypressFN?: (e: any) => void,
  ) =>
  (
      <label className={className}>
        <span>{label}:</span>
        <input
          type="text"
          key={`input-${item}`}
          required={true}
          id={item}
          value={value}
          onKeyPress={(event: any) => keypressFN ? keypressFN(event) : null}
          onChange={(event: any) => changeFN(
            type === 'text' ?
              event.target.value : parseInt(event.target.value.replace(/\W/g, ''), 10),
          )}
          onFocus={(event: any) => {
            if (type === 'number') {
              event.target.type = 'number';
              if (value === 0) {
                event.target.value = '';
              } else {
                event.target.value = parseInt(`${value}`.replace(/\W/g, ''), 10);
              }
            }
          }}
          onBlur={(event: any) => {
            if (type === 'number') {
              event.target.type = 'text';
              /* event.target.value =
                formatNumbers(parseInt(event.target.value.replace(/\W/g, ''), 10)); */
            }
          }}
        />
      </label>
  );

// tslint:disable-next-line: variable-name
const ExampleCustomInput = ({ value, onClick }: any) => (
  <>
    <input
      type="text"
      key={`dp-${Date.now()}`}
      value={value}
      onClick={onClick}
      onChange={onClick}
    />
  </>
);
export const renderDatePicker =
  (
    item: string,
    label: string,
    value: string | number,
    changeFN: (e: any) => void,
    className: string = '',
    additionalProps?: any,
  ) =>
  (
    <>
      <label key={`dp-${item}`} className={`datepicker_label ${className}`}>
        <span>{label}:</span>
        <DatePicker
          key={`dp-${Date.now()}`}
          selected={new Date(value)}
          onChange={date => changeFN(date)}
          locale="hu"
          timeIntervals={60}
          withPortal={true}
          customInput={<ExampleCustomInput />}
          shouldCloseOnSelect={true}
          {...additionalProps}
        />
      </label>
    </>
  );

export const renderTextarea =
  (item: string, label: string, value: string | number, changeFN: (e: any) => void) =>
  (
      <label>
        <textarea
          placeholder={label}
          required={true}
          key={`user-${item}`}
          id={item}
          value={value}
          onChange={(event: any) => changeFN(event.target.value)}
        />
      </label>
  );

export const renderCheckbox =
  (item: string, label: string, value: boolean, changeFN: (e: any) => void) => (
    <label className="switch" onClick={() => changeFN(!value)}>
      <span>{label}:</span>
      <div>
        <input
          type="checkbox"
          checked={value}
          key={`user-${item}`}
          required={true}
          id={item}
          value="1"
          // onClick={(event: any) => changeFN(!!parseInt(event.target.value, 10))}
        />
        <span className="cb-bg" />
        <span className="cb-circle" />
      </div>
    </label>
  );

export const renderPassword =
  (item: string, label: string, value: string, changeFN: (e: any) => void, type: string = '') =>
  (
      <label className={type}>
        <span>{label}:</span>
        <input
          type="password"
          key={`user-${item}`}
          required={true}
          id={item}
          value={value}
          onChange={(event: any) => changeFN(event.target.value)}
        />
      </label>
  );

export const renderSelect =
  (item: string, label: string, value: string, values: any, changeFN: (e: any) => void, classname = 'inputtype2 selectbox') =>
    (
      <label className={classname}>
        <span>{label}:</span>
        <select
          onChange={(event: any) => changeFN(event.target.value)}
          key={`user-${item}`}
          value={value}
        >
          {Object.keys(values).map((mvalue: string) =>
            <option
              value={mvalue}
              key={`select-${mvalue}`}
            >{values[mvalue]}</option>)}
        </select>
      </label>
    );

export const renderMultiSelect =
  (item: string, label: string, value: string[], values: any, changeFN: (e: any) => void) =>
    (
      <label className="selectbox multiselect">
        <span>{label}:</span>
        <select
          onChange={(event: any) => updateMultiSelect(event, changeFN)}
          key={`user-${item}`}
          multiple={true}
        >
          {Object.keys(values).map((mrole: string) =>
            <option
              value={mrole}
              selected={value.includes(mrole)}
              key={`select-${mrole}`}
            >{values[mrole]}</option>)}
        </select>
      </label>
    );

const updateMultiSelect = (event: any, callback: (e: any) => void) => {
  const options = Array.apply(null, event.target.options);
  const selectedValues = options
    .filter((option: any) => option.selected)
    .map((option: any) => option.value);
  callback(selectedValues);
};

export const renderRadioGroup =
  (item: string, label: string, value: string | number, values: any, changeFN: (e: any) => void) =>
  (
    <div className="radio" key={`user-${item}`}>
      <strong>{label}:</strong>
      {Object.keys(values).map((val: string) =>
        <span key={`radio-${val}`}>
          <input
            type="radio"
            value={val}
            onChange={(event: any) => changeFN(event.target.value)}
            checked={value === val}
          />
          <label>{values[val]}</label>
        </span>)}
    </div>
  );

export const renderContact =
  (label: string, values: any, changeFN: (e: any) => void) =>
  (
    <>
      <hr />
      <h4>{label}</h4>
      {values.map((val: any, index: number) =>
        <>
          {renderInput(
            'name',
            'Név',
            val.name,
            updateContact(values, index, 'name', changeFN),
            'inputtype2',
            )}
          {renderInput(
            'phone',
            'Telefon/mobil',
            val.phone,
            updateContact(values, index, 'phone', changeFN),
            'inputtype2',
          )}
          {renderRadioGroup(
            'type',
            'Típus',
            val.type,
            contactTypes,
            updateContact(values, index, 'type', changeFN),
          )}
        </>)}
    </>
  );

const updateContact =
  (array: any, index: number, key: string, callback: (newValue: any) => void) =>
    (newValue: string) => {
      const newArray = cloneDeep(array);
      newArray[index][key] = newValue;
      callback(newArray);
    };

export const renderHiddenInput = (item: string, value: string, changeFN: (e: any) => void) =>
  (
    <>
      <input
        key={`user-${item}`}
        type="hidden"
        required={true}
        id={item}
        value={value}
        onChange={(event: any) => changeFN(event.target.value)}
      />
    </>
  );

export const renderFurniture =
  (label: string, values: any, changeFN: (e: any) => void) =>
  (
    <>
      <h4>{label}</h4>
      {values.map((val: any, index: number) =>
        <>
          {renderInput(
            'price',
            'Bútor ára',
            val.name,
            updateContact(values, index, 'price', changeFN),
            'inputtype2',
          )}
          {renderRadioGroup(
            'type',
            'Típus',
            val.type,
            contactTypes,
            updateContact(values, index, 'type', changeFN),
          )}
          {renderInput(
            'assembly',
            'Telefon/mobil',
            val.assembly,
            updateContact(values, index, 'assembly', changeFN),
            'inputtype2',
          )}
          <hr />
        </>)}
    </>
  );

export const renderColorChooser =
  (value: string, label: string, values: any, changeFN: (e: any) => void) =>
  (
    <div className="radio colorpick">
      <strong>{label}</strong>
      {Object.keys(values).map((item: string) => (
        <span key={`color-${item}`} onClick={() => changeFN(item)}>
          <input
            id={`color-${item}`}
            checked={value === item}
            type="radio"
            name="calcolor"
            value={item}
            className={item}
            onSelect={() => changeFN(item)}
          />
          <label
            htmlFor={`color-${item}`}
            style={{ color: `#${item}` }}
          >
            <span>
              {values[item]}
            </span>
          </label>
        </span>
      ))}
    </div>
  );

export const renderOrderDetails = ({
    deliveryData,
    deliveryData: { name = '', phone = '' },
    date: { actual_time = '', time = '' },
    sums: { assemblyPrice = 0, deliveryPrice = 0, sum = 0 },
    summary: { deliveryDiscount = 0, assemblyDiscount = 0, images = [] },
    furnitures = [],
  }: IOrder, firm: string) =>
  (
    <div className="kivonat">
      <small className="rcomp">{firm}</small>
      <h4 className="rnev">{name}</h4>
      <p className="rdatum">
        {formatDate(new Date(actual_time), '.')}. / {time}
      </p>
      <p className="rcim">{getAddress(deliveryData)} / Telefon: {phone}</p>
      <span>
        <b>Bútorok értéke:</b> {formatNumbers(calculateFurnituresPrice(furnitures))} Ft
      </span>
      <span>
        <b>Bútorok típusai:</b>&nbsp;
        {furnitures.map((item: IFurniture) => furnitureTypes[item.type]).join(', ')}
      </span>
      <span><b>Szállítás értéke:</b> {formatNumbers(deliveryPrice - deliveryDiscount)} Ft</span>
      <span><b>Szerelés értéke:</b> {formatNumbers(assemblyPrice - assemblyDiscount)} Ft</span>
      <span><b>Összesen:</b> {formatNumbers(sum)} Ft</span>
      {images.length ?
        <div className="topbuttons">
          <label className="filecontainer">
            <h3>
              <br />
              Csatolt dokumentumok:
            </h3>
          </label>
          <div className="imgcont3">
            {Array.from(images).map(imageItem =>
              <a key={`image-${imageItem.name}`} target="_BLANK" href={imageItem.url} rel="noopener noreferrer">
                <img src={getImage(imageItem)} alt={imageItem.name} />
              </a>,
            )}
          </div>
        </div> : null}
    </div>
  );

export const renderFilesInput =
  (
    item: string,
    label: string,
    value: any[],
    originalValue: any[],
    changeFN: (e: any) => void,
    className = 'innerbox imgcont',
  ) =>
  (
    <div className="topbuttons" key={`files-${item}`}>
      <label className="filecontainer">
        <input
          type="file"
          multiple={true}
          placeholder={label}
          id={item}
          onChange={(event: any) => changeFN(event.target.files)}
        />
        <a className="button">
          {label}
        </a>
      </label>
      <div className={className}>
        {Array.from(originalValue).map(imageItem =>
          <a key={`image-${imageItem.name}`} target="_BLANK" href={imageItem.url} rel="noopener noreferrer">
            <img src={getImage(imageItem)} alt={imageItem.name} />
          </a>,
        )}
        {Array.from(value).map(imageItem =>
          <img key={`image-${imageItem}`} src={getImage(imageItem)} alt={imageItem.name} />)}
      </div>
    </div>
  );

export const renderCalendar = (
  item: string,
  group: number,
  calendarEvents: ICalendarEvents,
  saturdayOrders: string,
  sundayOrders: string,
  saveCalendarEvent: (e: any) => void,
) => {
  const props = { item, group, calendarEvents, saveCalendarEvent, saturdayOrders, sundayOrders };
  if (!group) {
    return null;
  }
  return (
    <Calendar {...props} type="assembly" label="Naptár" />
  );
};

export const getImage = (imageItem: IImage | Blob) => {
  const url = get(imageItem, 'url', false);
  const name = get(imageItem, 'name', '');
  let thumbnail = '';
  if (url) {
    thumbnail = url;
    if (url.match(/\.([0-9a-z]+)$/i)[1] === 'pdf') {
      thumbnail = pdfIcon;
    }
  } else {
    const image = URL.createObjectURL(imageItem);
    thumbnail = image;
    if (name.match(/\.([0-9a-z]+)$/i)[1] === 'pdf') {
      thumbnail = pdfIcon;
    }
  }
  return thumbnail;
};

