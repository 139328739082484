import { IUserObject } from './Users.interface';

export const labels: any = {
  id: '',
  name: 'Felhasználó neve',
  email: 'Felhasználó email címe',
  role: 'Felhasználó típus',
  team: 'Csapat',
  firm: 'Cég',
  phone: 'Telefon/mobil',
  password: 'Jelszó',
  onlyPreOrder: 'Csak előrendelés',
};

export const roles: any = {
  admin: 'Adminisztrátor',
  info: 'Információ pultos',
  teammember: 'Csapat tag',
};

export const types: any = {
  id: 'hidden',
  role: 'radio',
  team: 'multiSelect',
  firm: 'multiSelect',
  password: 'password',
  onlyPreOrder: 'checkbox',
};

export const defaultUser: IUserObject = {
  ID: '',
  firm: '',
  data: {
    display_name: '',
    user_email: '',
  },
  roles: [''],
  onlyPreOrder: false,
};
