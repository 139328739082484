import React, { useEffect, useState } from 'react';
import { connector } from './FirmForm.connector';
import { IFirmFormProps } from './FirmForm.interface';
import Spinner from '../Image/Spinner';
import { types, labels, radioValues, colors } from '../../views/Firms/Firms.constants';
import {
  renderHiddenInput,
  renderSelect,
  renderInput,
  renderRadioGroup,
  renderContact,
  renderColorChooser,
  renderMultiSelect,
  renderCheckbox,
} from '../Common/Renderers';
import chaticon from '../../assets/images/icon_chat.png';
import { convertObject } from '../Common/Utils';
// tslint:disable-next-line: variable-name
const times = Array(10).fill(0).map((_item, key) => `${key + 9}:00`);

// tslint:disable-next-line: variable-name
const FirmForm = (props: IFirmFormProps) => {
  const [id, setid] = useState(0);
  const [name, setname] = useState('');
  const [address, setaddress] = useState('');
  const [city, setcity] = useState('');
  const [zips, setzips] = useState('');
  const [color, setcolor] = useState('72bf44');
  const [contact, setcontact] = useState([{}, {}]);
  const [calculation, setcalculation] = useState('');
  const [dayClosing, setdayClosing] = useState('');
  const { selectedFirm, isLoading } = props;
  const stateObject: any = {
    id,
    name,
    address,
    city,
    zips,
    dayClosing,
    calculation,
    color,
    contact,
  };

  useEffect(() => {
    if (selectedFirm && selectedFirm.id) {
      setid(selectedFirm.id);
      setname(selectedFirm.name);
      setaddress(selectedFirm.address);
      setzips(selectedFirm.zips);
      setcity(selectedFirm.city);
      setdayClosing(selectedFirm.dayClosing);
      setcolor(selectedFirm.color);
      setcontact(selectedFirm.contact);
      setcalculation(selectedFirm.calculation);
    }
  }, [selectedFirm]);

  const functionMap: any = {
    id: setid,
    name: setname,
    address: setaddress,
    zips: setzips,
    city: setcity,
    contact: setcontact,
    calculation: setcalculation,
    color: setcolor,
    dayClosing: setdayClosing,
  };

  const title = id ? 'Cég szerkesztése' : 'Új cég létrehozása';

  const renderInputFields = (item: string) => {
    switch (types[item]) {
      case 'hidden':
        return renderHiddenInput(item, stateObject[item], functionMap[item]);
      case 'select':
        return renderSelect(
          item,
          labels[item],
          stateObject[item],
          times,
          functionMap[item],
        );
      case 'radio':
        return renderRadioGroup(
          item,
          labels[item],
          stateObject[item],
          radioValues[item],
          functionMap[item],
        );
      case 'contact':
        return renderContact(
          labels[item],
          stateObject[item],
          functionMap[item],
        );
      case 'color':
        return renderColorChooser(
          stateObject[item],
          labels[item],
          colors,
          functionMap[item],
        );
      case 'multiselect':
        return renderMultiSelect(
          item,
          labels[item],
          stateObject[item],
          convertObject(props.firms),
          functionMap[item],
        );
      case 'checkbox':
        return renderCheckbox(
          item,
          labels[item],
          stateObject[item],
          functionMap[item],
        );
      default:
        return renderInput(item, labels[item], stateObject[item], functionMap[item]);
    }
  };

  const saveFirm = (event: any) => {
    event.preventDefault();
    props.saveFirm(stateObject)
    .then(() => {
      props.getFirms();
      props.cancelForm();
    });
  };

  const deleteFirm = (event: any) => {
    event.preventDefault();
    if (!window.confirm('Biztosan törölni akarod?')) {
      return false;
    }
    props.deleteFirm(`${id}`)
    .then(() => {
      props.getFirms();
      props.cancelForm();
    });
  };

  return (
    <>
      <article className="firms">
      {isLoading && <Spinner />}
      {!isLoading &&
        <div className="UserFormContainer">
          <h2>{title}</h2>
            {id ? <i className="delete" onClick={deleteFirm} /> : null}
          <form>
            {Object.keys(stateObject).map(renderInputFields)}
            <input onClick={saveFirm} type="submit" value="Cég mentése" className="button" />
            <input onClick={props.cancelForm} type="submit" value="Mégsem" className="button secondary" />
          </form>
        </div>
      }
      </article>
      <article className="helper">
        <img src={chaticon} alt="chat" />
        <h4>Új cég hozzáadása</h4>
        <p>1. Az áruháznál a cím a távolságszámításhoz szükséges,&nbsp;
          a város pedig a megrendelőlap aláírásánál jelenik meg.</p>
        <p>2. A kapcsolattartók telefonszáma a megrendelőlapnál van kilistázva.</p>
        <p>3. A színek beállítása a naptárban való megjelenítés miatt.</p>
      </article>
    </>
  );
};

export default connector(FirmForm);
