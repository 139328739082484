import React, { useState } from 'react';
import { ICalendarPopupProps } from '../Calendar/Calendar.interface';
import Print from '../../components/OrderForm/Tabs/Print';
import { IFirm } from '../Firms/Firms.interface';
import { print, downloadPdf } from '../../components/Common/Utils';
import { connector } from '../Calendar/Calendar.connector';
import { getPrintProps } from '../Calendar/Calendar.utils';

// tslint:disable-next-line: variable-name
const OrdersPopup = (props: ICalendarPopupProps) => {
  const { firms, order, cancel, feedback = () => false } = props;
  const [displayprint, setprint] = useState(false);
  const printProps = getPrintProps(firms, order);
  const firm = (firms || []).find((ffirm: IFirm) => `${ffirm.id}` === order.firm);

  const printOrder = (event: any) => {
    setprint(true);
    setTimeout(() => print(event), 10);
  };

  const download = (event: any) => {
    setprint(true);
    setTimeout(() => downloadPdf(order.orderNr)(event), 10);
  };

  return (
    <div className="actionspanel">
      <div className="apinside">
        <h5>{firm?.name || ''}</h5>
        <h4>{order.deliveryData.name} - {order.orderNr}</h4>
        <p>
          {order.deliveryData.zip} {order.deliveryData.city},&nbsp;
          {order.deliveryData.address} / Telefon: {order.deliveryData.phone}
        </p>
        <hr />
        <input onClick={printOrder} type="submit" value="Nyomtatás" className="button b2" />
        <input onClick={download} type="submit" value="Pdf Letöltése" className="button b2" />
        <input onClick={feedback} type="submit" value="Visszajelzés" className="button b3 ins active" />
        <input onClick={cancel} type="submit" value="Mégsem" className="button secondary" />
        {displayprint && <Print {...printProps} />}
      </div>
    </div>
  );
};

export default connector(OrdersPopup);
