import React, { useState, useEffect } from 'react';
import { ITabProps } from '../OrderForm.interface';
import { cloneDeep, get } from 'lodash';
import {
  renderInput,
  renderCheckbox,
  renderSelect,
  renderFilesInput,
} from '../../Common/Renderers';
import { labels, approvers, requiredFields, defaultValues } from '../../../views/Orders/Orders.constants';
import { getSums, convertObject, downloadPdf, print, validation, fitInDay, isAdminUser } from '../../Common/Utils';
import { IFurniture, IOrder } from '../../../views/Orders/Orders.interface';
import { IImage } from '../../Feedback/Feedback.interface';
import { IGroup } from '../../../views/Groups/Groups.interface';
import { formatDate } from '../../Common/Helpers';

// tslint:disable-next-line: variable-name
const Summary = (props: ITabProps) => {
  const { getOrders, calendarEvents, stateObject, firmGroups, isDirty } =  props;
  const { summary, orderNr, furnitures, id, date, firm } =  stateObject;
  const {
    group,
    book,
  } = stateObject.date;
  const [showdeliveryDiscount, setdeliveryDiscount] = useState<boolean>(
    summary.deliveryDiscount || defaultValues.summary.deliveryDiscount,
  );
  const [showassemblyDiscount, setassemblyDiscount] = useState<boolean>(
    summary.assemblyDiscount || defaultValues.summary.assemblyDiscount,
  );
  const [images, setimages] = useState<IImage[] | Blob[]>([]);
  const [allorders, setallorders] = useState<IOrder[]>([]);
  const filteredGroup = firmGroups.find((fGroup: IGroup) => fGroup.id === group);
  useEffect(() => {
    if (images.length) {
      saveSummary('images')([...get(summary, 'images', []), ...images]);
      setimages([]);
    }
  // eslint-disable-next-line
  }, [images]);
  useEffect(() => {
    const formattedDate = formatDate(new Date(date.actual_time));
    getOrders(formattedDate, formattedDate, false, firm)
    .then((data: any) => {
      const newOrders = get(data, 'payload.data.data.orders', []);
      setallorders(newOrders);
    });
  // eslint-disable-next-line
  }, [getOrders]);
  const { saveOrder, cancelForm, user } =  props;
  const {
    assemblyPrice,
    deliveryPrice,
    sum,
    discount,
  } = getSums(stateObject);
  const isAdmin = isAdminUser(user);
  const isAssembly = furnitures
    .some((item: IFurniture) => item.assembly);
  const saveSummary = (key: string) =>
    (value: number | string | boolean | IImage[] | Blob[]) => {
      const newObject: any = cloneDeep(summary);
      let newValue = value;
      if (key === 'deliveryDiscount' && value > deliveryPrice) {
        newValue = deliveryPrice;
      } else if (key === 'assemblyDiscount' && value > assemblyPrice) {
        newValue = assemblyPrice;
      }
      newObject[key] = newValue;
      props.functionMap.summary(newObject);
    };
  const sumWithoutDiscount = assemblyPrice + deliveryPrice;

  const updatedeliveryDiscount = () => {
    if (!showdeliveryDiscount) {
      saveSummary('deliveryDiscount')(0);
    }
    setdeliveryDiscount(!showdeliveryDiscount);
  };

  const updateassemblyDiscount = () => {
    if (!showdeliveryDiscount) {
      saveSummary('assemblyDiscount')(0);
    }
    setassemblyDiscount(!showassemblyDiscount);
  };

  const dirtyFields: string[] = [];
  const requiredFieldsFilled = Object.keys(requiredFields)
    .every((item: string) =>  {
      const valid = requiredFields[item]
        .every((func: string) => validation[func](get(stateObject, item), stateObject));
      if (!valid) {
        dirtyFields.push(item);
      }
      return valid;
    });
  const isDateOk = book || (!book && fitInDay(
    allorders,
    isAssembly,
    stateObject,
    calendarEvents[stateObject.date.group],
    filteredGroup,
  ));
  const isGroupOk = book || firmGroups.map(item => item?.id).includes(group);
  const isDirtyOrder = !isAdmin && !(requiredFieldsFilled && isDateOk && isGroupOk);

  return (
    <div className="tabcontent" id="osszegzes">
      <hr />
      {renderInput(
        'delivery',
        labels.summary.delivery,
        deliveryPrice,
        () => null,
        'inputtype2 lightblue readonly',
        'number',
      )}
      {renderCheckbox(
        'showdeliveryDiscount',
        labels.summary.deliveryDiscount,
        showdeliveryDiscount,
        updatedeliveryDiscount,
      )}
      {showdeliveryDiscount ?
        <div className="innerbox" id="freeshipping">
          {renderInput(
            'discount',
            labels.summary.deliveryDiscount,
            summary.deliveryDiscount,
            saveSummary('deliveryDiscount'),
            'inputtype2',
            'number',
          )}
          {renderSelect(
            'freeDeliveryApprover',
            labels.summary.deliveryDiscountApprover,
            summary.deliveryDiscountApprover,
            convertObject(approvers),
            saveSummary('deliveryDiscountApprover'),
          )}
        </div> : null
      }
      <hr />
      {renderInput(
        'assembly',
        labels.summary.assembly,
        assemblyPrice,
        () => null,
        'inputtype2 lightblue readonly',
        'number',
      )}
      {isAssembly &&
        <>
          {renderCheckbox(
            'freeAssembly',
            labels.summary.assemblyDiscount,
            showassemblyDiscount,
            updateassemblyDiscount,
          )}
          {showassemblyDiscount ?
            <div className="innerbox" id="freework">
              {renderInput(
                'assemblyDiscount',
                labels.summary.assemblyDiscount,
                summary.assemblyDiscount,
                saveSummary('assemblyDiscount'),
                'inputtype2',
                'number',
              )}
              {renderSelect(
                'assemblyDiscountApprover',
                labels.summary.assemblyDiscountApprover,
                summary.assemblyDiscountApprover,
                convertObject(approvers),
                saveSummary('assemblyDiscountApprover'),
              )}
            </div> : null
          }
        </>
      }
      <hr />
      {renderInput(
        'sumWithoutDiscount',
        labels.summary.sumWithoutDiscount,
        sumWithoutDiscount,
        () => null,
        'inputtype2 orange readonly',
        'number',
      )}
      {renderInput(
        'discount',
        labels.summary.discount,
        discount,
        () => null,
        'inputtype2 readonly',
        'number',
      )}
      {renderInput(
        'sum',
        labels.summary.sum,
        sum,
        () => null,
        'inputtype2 orange readonly',
        'number',
      )}
      <hr />
      {renderFilesInput(
        'images',
        labels.summary.images,
        images,
        get(summary, 'images', []),
        setimages,
        'innerbox imgcont3',
      )}
      <hr />
      <div className="topbuttons">
        {!requiredFieldsFilled &&
          <p>A '{get(labels, dirtyFields[0])}' mező kitöltése kötelező!</p>}
        {!isDateOk &&
          <p>Az időpont nincs megfelelően kitöltve!</p>}
        {!isGroupOk &&
          <p>A csoport nincs megfelelően kiválasztva!</p>}
        {user.roles[0] !== 'teammember' ? (
          <input
            onClick={saveOrder(false)}
            type="submit"
            value="Megrendelés mentése"
            className="button"
            disabled={isDirtyOrder}
          />
        ) : null}
        <input
          onClick={cancelForm}
          type="submit"
          value="Mégsem"
          className="button secondary"
        />
        {(id && !isDirty && false) ?
          <>
            <input
              onClick={print}
              type="submit"
              value="Nyomtatás"
              className="button secondary"
              id="printthis"
              disabled={isDirtyOrder}
            />
            <input
              onClick={downloadPdf(orderNr)}
              type="submit"
              value="Pdf letöltése"
              className="button secondary"
              id="downloadthis"
              disabled={isDirtyOrder}
            />
          </> : null
        }
      </div>
    </div>
  );
};
export default Summary;
