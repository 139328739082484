// tslint:disable-next-line: no-unused-declaration
import React from 'react';
import { renderHiddenInput, renderSelect, renderInput, renderCheckbox } from '../../Common/Renderers';
import { labels } from '../../../views/Orders/Orders.constants';
import { convertObject } from '../../Common/Utils';
import { ITabProps } from '../OrderForm.interface';
import { IFirm } from '../../../views/Firms/Firms.interface';

// tslint:disable-next-line: variable-name
const General = (props: ITabProps) => (
  <>
    {renderHiddenInput(
      'id',
      props.stateObject.id || '',
      props.functionMap.id,
    )}
    {renderSelect(
      'firm',
      labels.firm,
      props.stateObject.firm,
      convertObject(props.firms.filter((firm: IFirm) =>
        props.user.roles.includes('admin') || props.user.firm.includes(`${firm.id}`)) || []),
      props.functionMap.firm,
      'inputtype2 selectbox',
    )}
    {renderInput(
      'orderNr',
      labels.orderNr,
      props.stateObject.orderNr,
      props.functionMap.orderNr,
      'inputtype2',
    )}
    {renderCheckbox(
      'reclamation',
      labels.reclamation,
      props.stateObject.reclamation,
      props.functionMap.reclamation,
    )}
  </>
);
export default General;
