import { dayTypes } from '../Firms/Firms.constants';

export const labels: any = {
  id: '',
  name: 'Csapatnév',
  type: 'Csapat típusa',
  firms: 'Cégek',
  saturdayOrders: 'Szombati megrendélesek',
  sundayOrders: 'Vasárnapi megrendélesek',
  workTypes: 'Munka típusok',
};

export const types: any = {
  id: 'hidden',
  name: 'input',
  type: 'radio',
  firms: 'multiselect',
  calendar: 'calendar',
  saturdayOrders: 'radio',
  sundayOrders: 'radio',
  workTypes: 'multiselect',
};

export const workTypes: any = {
  delivery: 'Szállítás',
  assembly: 'Szerelés',
  plannedAssembly: 'Tervezett szerelés',
};

export const contactTypes: any = {
  delivery: 'Szállítás',
  assembly: 'Szerelés',
  plannedAssembly: 'Tervezett szerelés',
  both: 'Szállítás - Szerelés',
};

const weekendDayTypes = {
  delivery: {
    off: 'Kikapcsolva',
    onlyDelivery: 'Bekapcsolva',
  },
  assembly: {
    off: 'Kikapcsolva',
    both: 'Bekapcsolva',
  },
  plannedAssembly: dayTypes,
  'delivery,assembly': dayTypes,
  'delivery,plannedAssembly': dayTypes,
  'assembly,plannedAssembly': dayTypes,
  'delivery,assembly,plannedAssembly': dayTypes,
};

export const radioValues: any = {
  type: contactTypes,
  saturdayOrders: weekendDayTypes,
  sundayOrders: weekendDayTypes,
};
