// tslint:disable-next-line: no-unused-declaration
import React from 'react';
import { ITabProps } from '../OrderForm.interface';
import { feedbackTypes } from '../../Feedback/Feedback.constants';
import { get } from 'lodash';
import fsIcon from '../../../assets/images/icon_fullscreen.png';
import dlIcon from '../../../assets/images/icon_more.png';
import delIcon from '../../../assets/images/icon_delete.png';
import { getImage } from '../../Common/Renderers';

const getType = (type: string = 'noComplaint') =>
  get(feedbackTypes, type, feedbackTypes.noComplaint);

const growImage = (event: any) => {
  let newClass = 'grow';
  if (event.target.parentElement.className.includes('grow')) {
    newClass = '';
  }
  event.target.parentElement.className = newClass;
};

// tslint:disable-next-line: variable-name
const Feedback = (props: ITabProps) => {
  const { currentOrder, removeImage } = props;

  const delImage = (index: number) =>
    () => {
      if (window.confirm('Biztosan törölni szeretnéd a képet?')) {
        removeImage(currentOrder.id || '', index);
      }
    }

  return (
    <div className="tabcontent" id="visszajelzes">
      <h5>
        <b>Reklamáció/visszajelzés típusa:</b>&nbsp;
        {getType(get(props, 'stateObject.feedback.feedbackType'))}
      </h5>
      <p><b>Megjegyzés:</b> {get(props, 'stateObject.feedback.feedback', '')}</p>
      <div className="innerbox imgcont2">
        {Object.values(get(props, 'stateObject.feedback.images', [])).map((image: any, index: number) =>
          <div key={`picture-${index}`}>
            <img
              src={getImage(image)}
              className="image"
              onClick={growImage}
              alt={`reclamation-${index}`}
            />
            <img
              src={fsIcon}
              alt="fullscreen"
              className="actionIcon fs"
              onClick={growImage}
            />
            <img
              src={delIcon}
              alt="delete"
              className="actionIcon del"
              onClick={delImage(index)}
            />
            <a href={image.url} target="_BLANK" download={true} className="actionIcon" rel="noopener noreferrer">
              <img src={dlIcon} alt="download" />
            </a>
          </div>)}
      </div>
    </div>
  );
};
export default Feedback;
