import { connect } from 'react-redux';
import * as loginActions from './Login.actions';

const mapStateToProps = ({ appReducer }: any) => ({
  message: appReducer.message,
});

const mapDispatchToProps = {
  login: loginActions.login,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
