import React, { useEffect } from 'react';
import './App.scss';
import Navigaton from '../Navigation/Navigaton';
import ViewContent from '../ViewContent/ViewContent';
import Header from '../Header/Header';
import { connector } from './App.connector';
import { IAppProps } from './App.interface';
import Spinner from '../Image/Spinner';
import Login from '../../views/Login/Login';

// tslint:disable-next-line: variable-name
const App = (props: IAppProps) => {
  const { getLoginData, getZips, isLoading, isLoggedIn, zips } = props;

  useEffect(() => {
    getLoginData();
  }, [getLoginData]);

  useEffect(() => {
    if (isLoggedIn && !zips) {
      getZips();
    }
  }, [isLoggedIn]);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading &&
        <div className="DrSpeedApp">
        {!isLoggedIn &&
          <>
            <Login />
          </>
        }
        {isLoggedIn &&
          <>
            <Navigaton />
            <Header />
            <ViewContent />
          </>
        }
        </div>
      }
    </>
  );
};

export default connector(App);
