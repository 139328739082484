import { connect } from 'react-redux';
import { getGroups, saveGroup, deleteGroup, getCalendarEvents, addCalendarEvent } from '../../views/Groups/Groups.actions';

const mapStateToProps = ({ groupReducer: { calendarEvents, firms, groups, isLoading } }: any) => ({
  calendarEvents,
  firms,
  groups,
  isLoading,
});

const mapDispatchToProps = {
  getGroups,
  saveGroup,
  deleteGroup,
  getCalendarEvents,
  addCalendarEvent,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
