import React from 'react';
import { IFirm } from '../Firms/Firms.interface';
import { IOrder, IFurniture } from '../Orders/Orders.interface';
import { calculateSlotWidth } from '../../components/Common/Utils';
import { defaultUser } from '../Users/User.constants';
import { uniq } from 'lodash';
import { formatDate, needAssembly } from '../../components/Common/Helpers';

const printOrder = (
  order: IOrder,
  setselectedOrder: (order: IOrder) => void,
) => {
  return (event: any) => {
    event.preventDefault();
    setselectedOrder(order);
  };
};

export const filterByFirm = (firm: string) => (order: IOrder) =>
  order.firm === firm;

export const filterByTeam = (team: number) => (order: IOrder) =>
  order.date.group === team;

export const mapOrders = (
  firms: IFirm[],
  setselectedOrder: (order: IOrder) => void,
) => (order: IOrder) => {
  const { furnitures, reclamation } = order;
  let className = '';
  const isAssembly = furnitures
    .some((item: IFurniture) => item.assembly);
  const isKitchen = furnitures.some((currentItem: IFurniture) => currentItem.type === 'kitchen');
  const isPlannedKitchen = furnitures.some((currentItem: IFurniture) => currentItem.type === 'plannedKitchen');
  const isKitchenSurvey = furnitures.some((currentItem: IFurniture) => currentItem.type === 'kitchenSurvey');
  const assemblyTime = (calculateSlotWidth(order) + 1) * 5 - 10;
  const currentFirm = (firms || []).find((firm: IFirm) => `${firm.id}` === order.firm);
  if ((isAssembly && (isKitchen || isPlannedKitchen)) || isKitchenSurvey) {
    className = 'e-kitchen';
  }
  if (reclamation) {
    className = 'reclamation';
  }
  const style = {
    height: `${assemblyTime}px`,
    background: `#${currentFirm?.color}`,
  };
  return (
    <span
      key={`order-${order.id}`}
      className={className}
      style={style}
      onClick={printOrder(order, setselectedOrder)}
    >
      <b>{order.deliveryData.name}</b><br />
      {/* order.orderNr */}
    </span>
  );
};

export const filterOrders = (type: string) => (order: IOrder) => {
  if (type === 'merged') {
    return true;
  }
  const isAssembly = needAssembly(order.furnitures);
  return type === 'assembly' ? isAssembly : !isAssembly;
};

export const filterByDate = (date: Date) => (order: IOrder) => {
  return formatDate(date) === formatDate(new Date(order.date.actual_time));
};

export const filterByTime = (time: string) => (order: IOrder) => time === order.date.time;

export const filterBookings = (order: IOrder) => !order.date.book;

export const getPrintProps = (firms: IFirm[], stateObject: IOrder) => ({
  firms,
  stateObject,
  currentOrder: stateObject,
  saveOrder: () => () => new Promise(() => true),
  getOrders: () => new Promise(() => true),
  getCalendarEvents: () => new Promise(() => true),
  getFirmGroups: () => new Promise(() => true),
  cancelForm: () => null,
  removeImage: () => null,
  user: defaultUser,
  functionMap: {},
  dirtyTabs: {},
  tab: '',
  calendarEvents: {},
  firmGroups: [],
  isDirty: false,
});

export const getDayLabel = (date: Date) => (
  <i>
    {date.toLocaleString('hu-HU', { weekday: 'long' })}&nbsp;
    ({date.getMonth() + 1}.{date.getDate()})
  </i>
);

export const addDateDiff = (date: Date, diff: string) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + parseInt(diff, 10));
  return newDate;
};

export const getFirmsLengthOnDate = (orders: IOrder[], type: string, date: Date, selectedFirms: string[]) =>
  uniq(orders
    .filter(filterOrders(type))
    .filter(filterByDate(date))
    .map((order: IOrder) => order.firm))
    .filter(filterFirms(selectedFirms));

export const getFirms = (orders: IOrder[]) =>
  uniq(orders.map((order: IOrder) => order.firm));

export const getCities = (filteredFirms: string[], firms: IFirm[]) =>
  uniq(
    (firms || [])
      .filter((firm: IFirm) => filteredFirms.includes(`${firm.id}`))
      .map((firm: IFirm) => firm.city)
  );

export const filterFirms = (selectedFirms: string[]) => (firm: string) =>
  selectedFirms.length ? selectedFirms.includes(firm) : true;

export const mapFilter = (firms: IFirm[]) => (item: string) => {
  const selectedFirm = (firms || []).find(firm => `${firm.id}` === item);
  const { name = '' } = selectedFirm || {};
  return (
    <option className="" key={`filter-${item}`} value={item}>
      {name}
    </option>
  );
};

export const filterByCity = (firms: IFirm[], cityFilter: string) => (item: string) => {
  const selectedFirm = (firms || []).find(firm => `${firm.id}` === item);
  const { city = '' } = selectedFirm || {};
  return city === cityFilter;
};

export const mapCityFilter = (item: string) => {
  return (
    <option className="" key={`filter-${item}`} value={item}>
      {item}
    </option>
  );
};

export const mapLegend = (firms: IFirm[]) => (item: string) => {
  const selectedFirm = (firms || []).find(firm => `${firm.id}` === item);
  const { color = '', name = '' } = selectedFirm || {};
  return (
    <span className="" key={`legend-${item}`}>
      <i style={{ background: `#${color}` }} />
      {name}
    </span>
  );
};

export const needPlaceholder = (morningOrders: IOrder[], afternoonOrders: IOrder[]) => {
  const morningOrdersLength = morningOrders.reduce((value: number, order: IOrder) =>
      value + ((calculateSlotWidth(order) + 1) / 10), 0);
  const afternoonOrdersLength = afternoonOrders.reduce((value: number, order: IOrder) =>
      value + ((calculateSlotWidth(order) + 1) / 10), 0);
  return (10 - Math.max(afternoonOrdersLength, 5) - morningOrdersLength) * 50 - 10;
};
