export const labels: any = {
  id: '',
  name: 'Áruház neve',
  address: 'Áruház címe',
  zips: 'Irányítószámok',
  city: 'Város',
  color: 'Naptárban megjelenített szín',
  calculation: 'Kalkuláció típusa',
  contact: 'Kapcsolattartók (a megrendelőlapra)',
  visibleFirms: 'Látható cégek',
  dayClosing: 'A holnapra felvehető rendelések lezárása',
  saturdayOrders: 'Szombati megrendélesek',
  sundayOrders: 'Vasárnapi megrendélesek',
  mergedOrders: 'Megrendelések összevonása',
};

export const calculationTypes: any = {
  momax2024: 'Mömax/Möbelix 2024',
  momax: 'Mömax/Möbelix',
  kika: 'Kika',
  lutz: 'Lutz',
};

export const contactTypes: any = {
  delivery: 'Szállítás',
  assembly: 'Szerelés',
  both: 'Szállítás/Szerelés',
};

export const dayTypes: any = {
  off: 'Kikapcsolva',
  onlyDelivery: 'Csak szállítás',
  fullDeliveryPartAssembly: 'Szállítás és 1 órás szerelés',
  both: 'Szállítás és szerelés',
};

export const radioValues: any = {
  calculation: calculationTypes,
  contact: contactTypes,
  saturdayOrders: dayTypes,
  sundayOrders: dayTypes,
};

export const types: any = {
  id: 'hidden',
  name: 'input',
  address: 'input',
  zips: 'input',
  visibleFirms: 'multiselect',
  dayClosing: 'select',
  city: 'input',
  color: 'color',
  calculation: 'radio',
  saturdayOrders: 'radio',
  sundayOrders: 'radio',
  contact: 'contact',
  calendar: 'calendar',
  mergedOrders: 'checkbox',
};

export const colors: any = {
  '72bf44': 'Mömax zöld',
  '00b8f0': 'Möbelix kék',
  ee4a2f: 'Kika piros',
  b443e0: 'Egyéb lila',
  '968a74': 'Egyéb barna',
};

export const disabledDeliveryTypes = ['off'];

export const disabledAssemblyTypes = [
  'off',
  'onlyDelivery',
];
