import React, { useState, useEffect } from 'react';
import { connector } from './Orders.connector';
import { IOrderProps, IOrder } from './Orders.interface';
import Spinner from '../../components/Image/Spinner';
import OrderForm from '../../components/OrderForm/OrderForm';
import { convertObject, getSums } from '../../components/Common/Utils';
import OrdersPopup from './OrdersPopup';
import Feedback from '../../components/Feedback/Feedback';
import {
  forceNumber, formatDate, formatNumbers, getFilteredShop, getFilteredTeam, getShop, needAssembly
} from '../../components/Common/Helpers';
import Filter, { IFilterProps } from './Filter';

// tslint:disable-next-line: variable-name
const Orders = (props: IOrderProps) => {
  const {
    getOrders, updateDate, getGroups, isLoading,
    startDate, endDate, user, selectedComponent, firms, groups, params = ''
  } = props;
  const [displayOrderForm, setdisplayOrderForm] = useState(params === 'new');
  const [displayFeedbackForm, setdisplayFeedbackForm] = useState(false);
  const [displayForm, setdisplayForm] = useState(false);
  const [ordersLoading, setisLoading] = useState(false);
  const [selectedOrder, setselectedOrder] = useState<null | IOrder>(null);
  const [type, settype] = useState('all');
  const [city, setcity] = useState('all');
  const [shop, setshop] = useState('all');
  const [team, setteam] = useState('all');
  const [name, setname] = useState('');
  const [address, setaddress] = useState('');
  const [reclamation, setreclamation] = useState(false);
  const [book, setbook] = useState(false);
  const [feedback, setfeedback] = useState(false);
  const isTeamMember = user.roles[0] === 'teammember';
  const isInfo = user.roles[0] === 'info';
  const filteredOrders = props.orders
    .filter((order: IOrder) => isInfo ? user.firm.includes(order.firm) : true);

  useEffect(() => {
    setselectedOrder(null);
    getGroups();
  }, []);

  useEffect(() => {
    if (selectedComponent === 'Orders' && !(displayOrderForm || displayFeedbackForm)) {
      setisLoading(true);
      getOrders(startDate, endDate)
      .finally(() => setisLoading(false));
    }
  }, [displayOrderForm, displayFeedbackForm]);

  const formatDateTable = (date: string) => {
    const dateObj = new Date(date);
    return formatDate(dateObj, '.');
  };

  const getFeedbackTypeClass = (type: string) =>
    type === 'noComplaint' ? 'green' : 'orange';

  const renderOrderRows = (): JSX.Element[] => {
    let rows = [(<tr key="order-0"><td colSpan={5}>Nem található megrendelés.</td></tr>)];
    const orders = filteredOrders
      .filter((order: IOrder) =>
        `${order.deliveryData.name}`.toLocaleLowerCase().includes(`${name}`.toLocaleLowerCase()))
      .filter((order: IOrder) => reclamation ? order.reclamation === reclamation : true)
      .filter((order: IOrder) => book ? order.date.book === book : true)
      .filter((order: IOrder) => feedback ? order.feedback : true)
      .filter((order: IOrder) =>
        type === 'all' ?
          true : (type === 'assembly' ?
            needAssembly(order.furnitures) : !needAssembly(order.furnitures)))
      .filter((order: IOrder) =>
        city === 'all' ? true : city === getFilteredShop(firms, forceNumber(order.firm))?.city)
      .filter((order: IOrder) => team === 'all' ? true : forceNumber(team) === order.date.group)
      .filter((order: IOrder) => `${order.deliveryData.address}`.toLocaleLowerCase().includes(`${address}`.toLocaleLowerCase()));
    if (orders && orders.length) {
      rows = orders.map((order: IOrder) => (
        <tr key={`order-${order.id}`}>
          <td>
            <a href={`order/${order.orderNr}`} onClick={editOrder(order)}>
              {order.orderNr}
            </a>
            {order.feedback &&
              <em className={getFeedbackTypeClass(order.feedback.feedbackType)}>V</em>
            }
            {order.date.book &&
              <em className="blue">E</em>
            }
            {order.reclamation &&
              <em>R</em>
            }
          </td>
          <td>{order.deliveryData.name}, {order.deliveryData.city}</td>
          <td>{formatNumbers(getSums(order).sum)} Ft</td>
          <td>
            {!order.date.book && formatDateTable(order.date.actual_time)}&nbsp;
            {!order.date.book && (order.date.time === 'Délelőtt (8-14 óráig)' ? 'DE' : 'DU')}
          </td>
          <td>{findFirm(order.firm)}</td>
          <td>
            {!isTeamMember && <i className="delete" onClick={deleteOrder(`${order.id}`)} />}
            <i className="print" onClick={showForm(order)} />
          </td>
        </tr>
      ));
    }
    return rows;
  };

  const findFirm = (id: string) =>
    (props.firms.filter(item => `${item.id}` === `${id}`).pop() || { name: '' }).name;

  const editOrder = (order: IOrder) => {
    return (event: any) => {
      event.preventDefault();
      setselectedOrder(null);
      setselectedOrder(order);
      if (isTeamMember) {
        setdisplayFeedbackForm(true);
      } else {
        setdisplayOrderForm(true);
      }
    };
  };

  const showForm = (order: IOrder) => {
    return (event: any) => {
      event.preventDefault();
      setselectedOrder(order);
      setdisplayForm(true);
    };
  };

  const cancelForm = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setdisplayOrderForm(false);
    setdisplayFeedbackForm(false);
    setselectedOrder(null);
  };

  const deleteOrder = (id: string) => (event: any) => {
    event.preventDefault();
    if (!window.confirm('Biztosan törölni akarod?')) {
      return false;
    }
    props.deleteOrder(id)
    .then(() => {
      props.getOrders(startDate, endDate);
    });
  };

  const orderFormProps = {
    selectedOrder,
    cancelForm,
  };

  const cancel = (event: any = { preventDefault: () => null }) => {
    event.preventDefault();
    setdisplayForm(false);
    setselectedOrder(null);
  };

  const provideFeedback = (event: any = { preventDefault: () => null }) => {
    event.preventDefault();
    setdisplayForm(false);
    setdisplayFeedbackForm(true);
  };

  const popupProps = {
    cancel,
    feedback: provideFeedback,
    order: selectedOrder,
    edit: () => false,
  };

  const shops = convertObject(filteredOrders.map((order: IOrder) =>
    ({ id: order.firm, name: getShop(firms, forceNumber(order.firm)) })));

  const cities = convertObject(
    filteredOrders
      .map((order: IOrder) => order.firm )
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((firm: string) =>
        ({
          id: getFilteredShop(firms, forceNumber(firm))?.city,
          name: getFilteredShop(firms, forceNumber(firm))?.city,
        }))
  );

  const teams = convertObject(
    filteredOrders
      .map((order: IOrder) => order.date.group )
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((group: number) =>
        ({
          id: getFilteredTeam(groups, group)?.id,
          name: getFilteredTeam(groups, group)?.name,
        }))
  );

  const filterprops: IFilterProps = {
    getOrders,
    updateDate,
    setname,
    setaddress,
    setreclamation,
    setbook,
    setfeedback,
    settype,
    setcity,
    setshop,
    setteam,
    team,
    shop,
    city,
    type,
    name,
    address,
    reclamation,
    book,
    feedback,
    cities,
    shops,
    teams,
    startDate,
    endDate,
  };

  return (
    <section className={`orders ${!(displayOrderForm || displayFeedbackForm) && 'fullwidth'}`}>
      {!displayOrderForm && (isLoading || ordersLoading) && <Spinner />}
      {displayOrderForm && <OrderForm {...orderFormProps} key={`order-${selectedOrder?.id}`} />}
      {displayFeedbackForm && <Feedback {...orderFormProps} />}
      {displayForm && selectedOrder && <OrdersPopup { ...popupProps } />}
      {!isLoading &&
        <>
          {!(displayOrderForm || displayFeedbackForm) &&
          <>
            {!isTeamMember &&
              <a
                href="/order/add"
                onClick={(e: any) => {
                  e.preventDefault();
                  setdisplayOrderForm(true);
                }}
                className="button addnew"
              >
                Új megrendelés
              </a>
            }
            <article className="fullwidth">
              <h2>Megrendelések</h2>
              <Filter {...filterprops} />
              <table>
                <thead>
                  <tr>
                    <th>Szerződésszám</th>
                    <th>Megrendelő neve</th>
                    <th>Teljes ár</th>
                    <th>Dátum</th>
                    <th>Cég/Csapat</th>
                    <th>Műveletek</th>
                  </tr>
                </thead>
                <tbody>
                  {renderOrderRows()}
                </tbody>
              </table>
            </article>
          </>
          }
        </>
      }
    </section>
  );
};

export default connector(Orders);
