import React, { useState, useEffect, JSXElementConstructor } from 'react';
import { connector } from './ViewContent.connector';
import { IViewContentProps } from './ViewContent.interface';
import Users from '../../views/Users/Users';
import Groups from '../../views/Groups/Groups';
import Firms from '../../views/Firms/Firms';
import Orders from '../../views/Orders/Orders';
import Landing from '../../views/Landing/Landing';
import CalendarDay from '../../views/Calendar/CalendarDay';
import CalendarWeek from '../../views/Calendar/CalendarWeek';
import './ViewContent.scss';

// tslint:disable-next-line: variable-name
const ViewContent = (props: IViewContentProps) => {
  // tslint:disable-next-line: variable-name
  const [SelectedComponent, setSelectedComponent] =
    useState<JSXElementConstructor<any> | null>(Landing);
  const { selectedComponent, isNavOpen } = props;

  useEffect(() => {
    setSelectedComponent(null);
  }, [selectedComponent]);

  useEffect(() => {
    setSelectedComponent(null);
    let component = null;
    switch (selectedComponent) {
      case 'Users':
        component = Users;
        break;
      case 'Groups':
        component = Groups;
        break;
      case 'Firms':
        component = Firms;
        break;
      case 'Orders':
        component = Orders;
        break;
      case 'CalendarDay':
        component = CalendarDay;
        break;
      case 'CalendarWeek':
        component = CalendarWeek;
        break;
      default:
        component = Landing;
        break;
    }
    setSelectedComponent(component);
  }, [selectedComponent]);

  return (
    <div className={`content trans05 ${isNavOpen ? 'opened' : ''}`}>
      {SelectedComponent !== null ?
        <SelectedComponent key={`selectedComponent-${selectedComponent}`} /> : null}
    </div>
  );
};

export default connector(ViewContent);
