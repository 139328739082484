import { IFurniture, IOrder, IDeliveryData } from '../../views/Orders/Orders.interface';
import * as kika from '../../Calculations/Kika/Kika';
import * as momax from '../../Calculations/Momax/Momax';
import * as momax2024 from '../../Calculations/Momax2024/Momax';
import * as lutz from '../../Calculations/Lutz/Lutz';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { get } from 'lodash';
import { ICalendarEvents } from './Calendar/Calendar.interface';
import { disabledAssemblyTypes, disabledDeliveryTypes } from '../../views/Firms/Firms.constants';
import { IGroup } from '../../views/Groups/Groups.interface';
import { forceNumber } from './Helpers';
import style from './Print.css';
import { IUserObject } from '../../views/Users/Users.interface';

export const convertObject = (object: any) => {
  if (!Array.isArray(object)) {
    return object;
  }
  const newObj: any = {};
  object.forEach((element: any) => {
    newObj[element.id] = element.name;
  });
  return newObj;
};

export const calculateAssemblyPrice = (furnitures: IFurniture[]) =>
  furnitures.reduce((prevValue: number, currentItem: IFurniture) =>
    prevValue + getFurniturePrice(currentItem), 0);

export const calculateFurnituresPrice = (furnitures: IFurniture[]) =>
  furnitures.reduce((prevValue: number, currentItem: IFurniture) =>
    prevValue + currentItem.price, 0);

export const getFurniturePrice = (furniture: IFurniture) => {
  let price = 0;
  if (furniture.type === 'kitchenSurvey' || furniture.assembly) {
    price = furniture.assembly_price;
  }
  return price;
};

export const getSums = (order: IOrder) => {
  const { furnitures = [], deliveryData, summary } = order;
  const { assemblyDiscount, deliveryDiscount } = summary;
  const { base, distance_price } = deliveryData;
  const discount = forceNumber(deliveryDiscount) + forceNumber(assemblyDiscount);
  const deliveryPrice = forceNumber(base) + forceNumber(distance_price);
  let assemblyPrice = calculateAssemblyPrice(furnitures);
  if (calculateFurnituresPrice(furnitures) > 999999) {
    //assemblyPrice -= deliveryPrice;
  }
  let sum = assemblyPrice + deliveryPrice - (discount || 0);
  if (sum < 0) {
    sum = 0;
  }
  return {
    assemblyPrice,
    deliveryPrice,
    discount,
    sum,
  };
};

export const getDay = (sep: string) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  return yyyy + sep + mm + sep + dd;
};

export const getAddress = (deliveryData: IDeliveryData) => {
  const { city, address, zip, country, floor, bell } = deliveryData;
  const floorData = floor ? `, emelet: ${floor}` : '';
  const bellData = bell ? `, csengő: ${bell}` : '';
  return `${city}, ${address}${floorData}${bellData}, ${zip} ${country}`;
};

export const calculateAssemblyTime = (furniture: IFurniture) => {
  let assemblyTime = 60;
  let slotwidth = 9;
  if (furniture.assembly) {
    const { type, price } = furniture;
    if (type === 'furniture') {
      assemblyTime = 180;
      slotwidth = 29;
      if (price > 400000) {
        assemblyTime = 480;
        slotwidth = 79;
      } else if (price > 300000) {
        assemblyTime = 300;
        slotwidth = 49;
      } else if (price > 200000) {
        assemblyTime = 240;
        slotwidth = 39;
      }
    } else if (['kitchen', 'plannedKitchen'].includes(type)) {
      assemblyTime = 480;
      slotwidth = 79;
    } else {
      assemblyTime = 60;
      slotwidth = 9;
    }
  }
  return { assemblyTime, slotwidth };
};

export const hasKitchen = (furnitures: IFurniture[]) =>
  furnitures.some((item: IFurniture) => item.type === 'kitchen');

export const calculateSlotWidth = (order: IOrder) => {
  const { furnitures, deliveryData, reclamation } = order;
  const isKitchen = furnitures
    .some((item: IFurniture) => item.type === 'kitchen');
  const isPlannedKitchen = furnitures
    .some((item: IFurniture) => item.type === 'plannedKitchen');
  const isKitchenSurvey = furnitures
    .some((item: IFurniture) => item.type === 'kitchenSurvey');
  const isOrderAssembly = furnitures
    .some((fitem: IFurniture) => fitem.assembly);
  let width = isOrderAssembly ? furnitures
    .filter((item: IFurniture) => item.assembly)
    .reduce((prevElement: number, item: IFurniture) =>
      prevElement + calculateAssemblyTime(item).slotwidth, 0) : 9;
  const distance_time = get(deliveryData, 'distance_time', 0);
  if (reclamation) {
    width = 9;
    if (isKitchen || isPlannedKitchen) {
      width = 29;
    }
  }
  if (isKitchenSurvey) {
    width = 9;
  }
  if (!isOrderAssembly && distance_time > 30) {
    width = Math.round(deliveryData.distance_time * 2 / 60) * 10 - 1;
  }
  if (isOrderAssembly && distance_time > 30) {
    width += Math.round(deliveryData.distance_time * 2 / 60) * 10;
  }
  return width;
};

export const print = (event: any) => {
  event.preventDefault();
  const printWindow = window.open('', '');
  if (printWindow) {
    const content = document.querySelector('#printcontent')?.innerHTML;
    printWindow.document.write(`<html><body id="printcontent">${style}${content}</body></html>`);
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 1500);
  }
};

export const downloadPdf = (orderNr: string) => (event: any) => {
  event.preventDefault();
  window.scrollTo(0, 0);
  const today = getDay('-');
  const content = document.querySelector('#printcontent')?.innerHTML;
  const printWindow = window.open('', '');
  if (printWindow) {
    printWindow.document.write(`<html><body id="printcontent">${style}${content}</body></html>`);
    html2canvas(printWindow.document.body, { scale: 1.6 }).then((canvas: any) => {
      printWindow.close();
      const img = canvas.toDataURL('image/jpeg', 1);
      const doc = new jsPDF('p', 'mm', 'a4');
      doc.addImage(img, 'JPEG', 0, 0, 210, 297);
      //doc.output('dataurlnewwindow');
      doc.save(`${orderNr}_${today}.pdf`);
    });
  }
};

export const validation: any = {
  empty: (item: string) => !!item,
  emptyDate: (item: string, state: IOrder) => get(state, 'date.book', false) || !!item,
  length: (item: any[]) => !!item.length,
};

export const getCalculationObject = (calculationType: string) => {
  switch (calculationType) {
    case 'kika':
      return kika.default;
    case 'momax':
      return momax.default;
    case 'momax2024':
      return momax2024.default;
    case 'lutz':
      return lutz.default;
    default:
      return kika.default;
  }
};

export const convertToArray = (element: string | string[]) =>
  Array.isArray(element) ? element : [element];

export const getWorkingHoursOnDay = (
  calendarEvents: ICalendarEvents,
  day: string,
  group: IGroup | undefined,
  isAssembly: boolean,
): number => {
  if (!calendarEvents || !group) {
    return 10;
  }
  const {
    saturdayOrders = 'off',
    sundayOrders = 'off',
  } = group;
  const currentDay = new Date(day);
  const dayindex = `${currentDay.getMonth()}-${currentDay.getDate()}`;
  const override: any = get(calendarEvents, `${currentDay.getFullYear()}.${dayindex}`, -1);
  let hours = override > -1 ? override : 10;
  if ([0, 6].includes(currentDay.getDay())) {
    const dayOrders = currentDay.getDay() === 6 ? saturdayOrders : sundayOrders;
    const types = isAssembly ? disabledAssemblyTypes : disabledDeliveryTypes;
    const isDisabled = types.includes(dayOrders);
    if (override === -1) {
      if (isDisabled) {
        hours = 0;
      }
    }
  }
  return hours;
};

export const isDisabledWeekendOrder = (
  date: string,
  width: number,
  group: IGroup | undefined,
  isAssembly: boolean,
  calendarEvents: ICalendarEvents,
) => {
  if (!group) {
    return false;
  }
  const {
    saturdayOrders = 'off',
    sundayOrders = 'off',
  } = group;
  const day = new Date(date);
  const isLong = width > 1;
  const isWeekend = [0, 6].includes(day.getDay());
  const dayOrders = day.getDay() === 6 ? saturdayOrders : sundayOrders;
  const isDisabled = !getWorkingHoursOnDay(calendarEvents, date, group, isAssembly);
  return isDisabled || (isWeekend && isAssembly && isLong && dayOrders === 'fullDeliveryPartAssembly');
};

export const fitInDay = (
  allorders: IOrder[],
  isAssembly: boolean,
  item: IOrder,
  calendarEvents: ICalendarEvents,
  group: IGroup | undefined,
) => {
  if (!group) {
    return false;
  }
  let fit = true;
  const orders = allorders
    .filter((order: IOrder) => !order.date.book)
    .filter((order: IOrder) => order.date.actual_time === item.date.actual_time)
    .filter(filterByGroup(group.id || 0))
    .filter((order: IOrder) => order.id !== item.id);
  orders.push(item);
  const hours =
    getWorkingHoursOnDay(calendarEvents, item.date.actual_time, group, isAssembly);
  const width = calculateSlotWidth(item);
  const ordersLength = orders.reduce((value: number, order: IOrder) =>
    value + ((calculateSlotWidth(order) + 1) / 10), 0);
  const fitInSlot = ordersLength <= hours;
  const isLongAssembly = orders.length === 1 && isAssembly && hours === 10;
  const isDisabledWeekend =
    isDisabledWeekendOrder(item.date.actual_time, width, group, isAssembly, calendarEvents);
  if ((!fitInSlot && !isLongAssembly) || isDisabledWeekend) {
    fit = false;
  }
  return fit;
};

export const filterByGroup = (group: number | string | undefined) =>
  (order: IOrder) => order.date.group ?
    forceNumber(order.date.group) === forceNumber(group || 0) : false;

export const filterByType = (reclamation: boolean, furnitures: IFurniture[]) =>
  (group: IGroup) => {
    const isKitchenSurvey = furnitures
      .some((item: IFurniture) => item.type === 'kitchenSurvey');
    const isAssembly = furnitures
      .some((item: IFurniture) => item.assembly);
    const isKitchen = furnitures
      .some((item: IFurniture) => item.type === 'kitchen');
    const isCustomKitchen = furnitures
      .some((item: IFurniture) => item.type === 'plannedKitchen');
    if (reclamation) {
      if (isKitchen || isCustomKitchen) {
        return group.workTypes.includes('assembly');
      } else {
        return isAssembly ? group.workTypes.includes('assembly') : group.workTypes.includes('delivery');
      }
    }
    return isCustomKitchen ? group.workTypes.includes('plannedAssembly') :
      (isAssembly || isKitchenSurvey) ? group.workTypes.includes('assembly') : group.workTypes.includes('delivery');
  };

export const isAdminUser = (user:IUserObject) => user.roles.includes('admin');

export const isInfoUser = (user:IUserObject) => user.roles.includes('info');

export const isTeamMemberUser = (user:IUserObject) => user.roles.includes('teammember');
