import React, { useState } from 'react';
import { ICalendarPopupProps } from './Calendar.interface';
import { connector } from './Calendar.connector';
import Print from '../../components/OrderForm/Tabs/Print';
import {
  getPrintProps,
} from './Calendar.utils';
import { IFirm } from '../Firms/Firms.interface';
import { print, downloadPdf } from '../../components/Common/Utils';

// tslint:disable-next-line: variable-name
const CalendarPopup = (props: ICalendarPopupProps) => {
  const { firms, order, cancel, edit, user } = props;
  const [displayprint, setprint] = useState(false);
  const printProps = getPrintProps(firms, order);
  const firm = (firms || []).find((ffirm: IFirm) => `${ffirm.id}` === order.firm);
  const isTeamMember = user.roles[0] === 'teammember';

  const printOrder = (event: any) => {
    setprint(true);
    setTimeout(() => print(event), 10);
  };

  const download = (event: any) => {
    setprint(true);
    setTimeout(() => downloadPdf(order.orderNr)(event), 10);
  };

  return (
    <div className="actionspanel">
      <div className="apinside">
        <h5>{firm?.name || ''}</h5>
        <h4>{order.deliveryData.name} - {order.orderNr}</h4>
        <p>
          {order.deliveryData.zip} {order.deliveryData.city},&nbsp;
          {order.deliveryData.address} / Telefon: {order.deliveryData.phone}
        </p>
        {order.date.comment && 
          <p>
            Megjegyzés:&nbsp;
            {order.date.comment}
          </p>}
        <hr />
        <input onClick={edit} type="submit" value={isTeamMember ? 'Megtekintés' : 'Szerkesztés'} className="button" />
        <input
          onClick={printOrder}
          type="submit"
          value="Nyomtatás"
          className="button b2"
        />
        <input onClick={download} type="submit" value="Pdf letöltése" className="button b2" />
        <input onClick={cancel} type="submit" value="Mégsem" className="button secondary" />
        {displayprint && <Print {...printProps} />}
      </div>
    </div>
  );
};

export default connector(CalendarPopup);
