import React, { Fragment } from 'react';
import { IFurniture, IOrder } from '../../views/Orders/Orders.interface';
import { ICalculations } from '../Calculations.interface';
import { furnitureTypes } from '../../views/Orders/Orders.constants';
import { formatNumbers } from '../../components/Common/Helpers';

const basePrices = {
  couch: 4000,
  bed: 6000,
  furniture: 5000,
  kitchen: 5000,
  plannedKitchen: 5000,
  kitchenSurvey: 14000,
  linear_meter: 5000,
  delivery: 9500,
  onlyAssembly: 6490,
  internationalDelivery: 19000,
};

const editableFields: string[] = [];
const kmCost: number = 280;
const abroadKmCost: number = 580;
const baseDistance: number = 15;

const calculateAssemblyPrice = (furniture: IFurniture) => {
  let price = 0;
  if (furniture.type === 'kitchenSurvey') {
    price = basePrices.kitchenSurvey;
  } else if (furniture.assembly) {
    const calculatedByValue = furniture.price * 0.12;
    switch (furniture.type) {
      case 'couch':
      case 'bed':
        price = furniture.quantity * basePrices[furniture.type];
        break;
      case 'furniture':
        price = Math.max(calculatedByValue, basePrices[furniture.type]);
        break;
      case 'kitchen':
      case 'plannedKitchen':
        const basePrice = Math.max(calculatedByValue, basePrices[furniture.type]);
        // const priceByLength = (furniture.linear_meter * basePrices.linear_meter);
        price = basePrice; // + priceByLength;
        break;
    }
  }
  return Math.round(price);
};

const calculateDeliveryPrice = ({ country = '', isDelivery = false }: any) => {
  let basePrice = basePrices.delivery;
  if ((country !== 'Magyarország')) {
    // kmcost = 580;
    basePrice = basePrices.internationalDelivery;
  }
  if (!isDelivery) {
    basePrice = basePrices.onlyAssembly;
  }
  return basePrice;
};

const getKmCost = (country: string) =>
  country !== 'Magyarország' ? abroadKmCost : kmCost;

const getExtraCost = ({ zips, zip, distance, country }: any) => {
  let extradist = 0;
  if (!zips.includes(`${zip}`)) {
    extradist = Math.round(distance) * getKmCost(country);
  }
  return extradist;
};

const orderFormTexts: string[] = [
  '<b>Ülőgarnitúrák szerelése:</b> szállítási díj + 4.000,- Ft',
  '<b>Kárpitozott ágyak szerelése:</b> szállítási díj + 6.000,- Ft',
  'Az árak nem tartalmazzák az áram-, víz- és gázbekötéseket. Amennyiben a bútorok szereléséhez - helyszínhez kötött okból kifolyólag - a gyártó által biztosított szerelési egységcsomag nem elegendő, úgy a szereléshez szükséges egyéb anyagok beszerzésének költsége a Megrendelőt terheli.',
  '',
  'A városon kívül eső szállításoknál az alapdíjon felül 280,- Ft/km felárat számolunk fel. A szállítási árak tartalmazzák az emeletre történő szállítást. Az árak forintban értendők és az ÁFÁ-t tartalmazzák. Az esetleges parkolási díj, komp díj, behajtási engedély díja és beszerzése a megrendelőt terheli.',
];

export const getCommentFields = (item: IFurniture) => {
  const comments: string[] = [];
  if (item.worksheet) {
    comments.push('Hátfal panel szerelés');
  }
  return comments;
};

const enabledFurnitureTypes: string[] = [
  'couch',
  'bed',
  'furniture',
  'kitchen',
  'plannedKitchen',
  'kitchenSurvey',
];

const getKitchenFields = () => null;

const renderElementForPrinting = (order: IOrder, details: (item: IFurniture) => string[]) => {
  return order.furnitures.map((furniture: IFurniture, index: number) => (
    <Fragment key={`orderitem-${Date.now()}`}>
      <tr>
        <td colSpan={5} className="padding5"></td>
      </tr>
      <tr>
        <td rowSpan={2} className="itemNumber">
          <b>{index + 1}.</b>
        </td>
        <td className="itemGap"></td>
        <td>
          <b>{furnitureTypes[furniture.type]}</b>&nbsp;{furniture.quantity > 1 ? `${furniture.quantity} db ` : ''}
          (bútor ára: {formatNumbers(furniture.price)} Ft{furniture.own_delivery === true && ', ügyfél szállítja ki'})
        </td>
        <td className="itemDetails">
          {furniture.assembly_price ?
            <>
              Szerelési ár: <span className="pszer">{formatNumbers(furniture.assembly_price)} Ft</span>
            </> : null
          }
          {(!furniture.assembly_price && !furniture.own_delivery) && <>CSAK SZÁLLÍTÁS</>}
        </td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={2}>
          Szerelés részletezve: <span className="pmeg">{details(furniture)}</span>
        </td>
        <td></td>
      </tr>
    </Fragment>
  ));
};

const defaultFurniture = {
  price: 0,
  quantity: 1,
  type: '',
  assembly: false,
  own_delivery: false,
  assembly_price: 0,
  linear_meter: 0,
  worksheet: false,
  kitchenTools: false,
  modifyCabinet: false,
  modifyCabinetCount: 0,
  wallCloset: 0,
  floorCloset: 0,
  highCloset: 0,
  other: 0,
  kitchenToolTypes: {
    hotplate: false,
    sink: false,
    dishwasher: false,
    cooler: false,
    dehumidifier: false,
  },
};

const applyAssemblyDiscount = () => 0;

const calculation: ICalculations = {
  getKmCost,
  getExtraCost,
  baseDistance,
  calculateAssemblyPrice,
  calculateDeliveryPrice,
  orderFormTexts,
  editableFields,
  getCommentFields,
  enabledFurnitureTypes,
  getKitchenFields,
  renderElementForPrinting,
  defaultFurniture,
  applyAssemblyDiscount,
  basePrice: basePrices,
};

export default calculation;
