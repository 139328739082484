export const fields: any = {
  id: 'hidden',
  orderNr: 'input',
  orderDetails: 'orderDetails',
  feedbackType: 'radio',
  feedback: 'textarea',
  images: 'files',
};

export const labels: any = {
  id: '',
  orderNr: 'Szerződésszám',
  orderDetails: '',
  feedbackType: 'Visszajelzés típusa',
  feedback: 'Megjegyzés',
  images: '',
  files: 'Képfeltöltés',
};

export const feedbackTypes: any = {
  noComplaint: 'Nincs reklamáció',
  damagedItem: 'Sérült bútorelem',
  missingPart: 'Hiányzó alkatrész',
  causedDamage: 'Okozott kár',
  other: 'Egyéb',
};
