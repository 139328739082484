import React, { useEffect, useState } from 'react';
import { connector } from './GroupForm.connector';
import { IGroupFormProps } from './GroupForm.interface';
import Spinner from '../Image/Spinner';
import { types, labels, radioValues, workTypes as allWorkTypes } from '../../views/Groups/Groups.constants';
import {
  renderHiddenInput,
  renderInput,
  renderRadioGroup,
  renderMultiSelect,
  renderCalendar,
} from '../Common/Renderers';
import chaticon from '../../assets/images/icon_chat.png';
import { convertObject } from '../Common/Utils';
import { dayTypes } from '../../views/Firms/Firms.constants';
import { get } from 'lodash';

// tslint:disable-next-line: variable-name
const GroupForm = (props: IGroupFormProps) => {
  const [id, setid] = useState(0);
  const [name, setname] = useState('');
  const [firms, setfirms] = useState<string[]>([]);
  const [workTypes, setworkTypes] = useState<string[]>(['delivery']);
  const [saturdayOrders, setsaturdayOrders] = useState(dayTypes.off);
  const [sundayOrders, setsundayOrders] = useState(dayTypes.off);
  const [calendar, setcalendar] = useState({});
  const { selectedGroup, isLoading, calendarEvents, addCalendarEvent, getCalendarEvents } = props;
  const stateObject: any = {
    id,
    name,
    workTypes,
    saturdayOrders,
    sundayOrders,
    firms,
    calendar,
  };

  useEffect(() => {
    if (selectedGroup && selectedGroup.id) {
      setid(selectedGroup.id);
      setname(selectedGroup.name);
      setfirms(selectedGroup.firms);
      setsaturdayOrders(selectedGroup.saturdayOrders);
      setsundayOrders(selectedGroup.sundayOrders);
      setcalendar(selectedGroup.calendar);
      setworkTypes(selectedGroup.workTypes || []);
    }
  // eslint-disable-next-line
  }, [selectedGroup]);

  useEffect(() => {
    getCalendarEvents({
      year: new Date().getFullYear(),
      group: selectedGroup?.id || 0,
    });
  // eslint-disable-next-line
  }, [getCalendarEvents]);

  const functionMap: any = {
    id: setid,
    name: setname,
    firms: setfirms,
    saturdayOrders: setsaturdayOrders,
    sundayOrders: setsundayOrders,
    calendar: setcalendar,
    workTypes: setworkTypes,
  };

  const title = id ? 'Csapat szerkesztése' : 'Új csapat létrehozása';

  const renderInputFields = (item: string) => {
    switch (types[item]) {
      case 'hidden':
        return renderHiddenInput(item, stateObject[item], functionMap[item]);
      case 'multiselect':
        let values = convertObject(props[item]);
        if (item === 'workTypes') {
          values = allWorkTypes;
        }
        return renderMultiSelect(
          item,
          labels[item],
          stateObject[item],
          values,
          functionMap[item],
        );
      case 'radio':
        let items = radioValues[item];
        if (['saturdayOrders', 'sundayOrders'].includes(item)) {
          items = radioValues[item][workTypes.join(',')] || [];
        }
        return renderRadioGroup(
          item,
          labels[item],
          stateObject[item],
          items,
          functionMap[item],
        );
      case 'calendar':
        return renderCalendar(
          item,
          id,
          get(calendarEvents, `${selectedGroup?.id || 0}`, {}),
          saturdayOrders,
          sundayOrders,
          addCalendarEvent,
        );
      default:
        return renderInput(item, labels[item], stateObject[item], functionMap[item]);
    }
  };

  const saveGroup = (event: any) => {
    event.preventDefault();
    props.saveGroup(stateObject)
    .then(() => {
      props.getGroups();
      props.cancelForm();
    });
  };

  const deleteGroup = (event: any) => {
    event.preventDefault();
    if (!window.confirm('Biztosan törölni akarod?')) {
      return false;
    }
    props.deleteGroup(`${id}`)
    .then(() => {
      props.getGroups();
      props.cancelForm();
    });
  };

  return (
    <>
      <article className="firms">
        {isLoading && <Spinner />}
        {!isLoading &&
          <div className="UserFormContainer">
            <h2>{title}</h2>
            {id ? <i className="delete" onClick={deleteGroup} /> : null}
            <form>
              {Object.keys(stateObject).map(renderInputFields)}
              <input onClick={saveGroup} type="submit" value="Csapat mentése" className="button" />
              <input onClick={props.cancelForm} type="submit" value="Mégsem" className="button secondary" />
            </form>
          </div>
        }
      </article>
      <article className="helper">
        <img src={chaticon} alt="chat" />
        <h4>Új csapat létrehozása</h4>
        <p>1. A csapatokhoz kapcsolt cégeknél CTRL gombot nyomvatartva több kijelölhető.</p>
        <p>2. A csapatokhoz tartozó felhasználók csak a kapcsolt cégek&nbsp;
          adatait látják, de nem szerkeszthetik.</p>
      </article>
    </>
  );
};

export default connector(GroupForm);
