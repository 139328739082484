import { cloneDeep, get } from 'lodash';
import { APP_ACTION } from './App.actions';
import { LOGIN_ACTION } from '../../views/Login/Login.actions';
export const defaultAppState = {
  isLoading: true,
  isLoggedIn: false,
  isNavOpen: true,
  selectedComponent: 'Landing',
  selectedComponentProps: '',
  user: {},
  zips: [],
  message: '',
};

export const appReducer = (
  state: any = cloneDeep(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case APP_ACTION.GET_LOGIN_DATA:
      return { ...state, isLoading: true };
    case APP_ACTION.SUCCESS.GET_LOGIN_DATA:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: get(action, 'payload.data.isLoggedIn'),
        user: {
          ...get(action, 'payload.data.user', {}),
          firm: get(action, 'payload.data.firm', {}),
          team: get(action, 'payload.data.team', {}),
          onlyPreOrder: get(action, 'payload.data.onlyPreOrder', false),
        },
      };
    case APP_ACTION.FAIL.GET_LOGIN_DATA:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_ACTION.LOGIN:
      return { ...state, isLoading: true };
    case LOGIN_ACTION.SUCCESS.LOGIN:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: get(action, 'payload.data.isLoggedIn', {}),
        user: {
          ...get(action, 'payload.data.user', {}),
          firm: get(action, 'payload.data.firm', {}),
          team: get(action, 'payload.data.team', {}),
          onlyPreOrder: get(action, 'payload.data.onlyPreOrder', false),
        },
        message: get(action, 'payload.data.message', {}),
      };
    case LOGIN_ACTION.FAIL.LOGIN:
      return {
        ...state,
        isLoading: false,
        message: 'Sikertelen belépés!',
      };
    case APP_ACTION.LOGOUT:
      return { ...state, isLoading: true };
    case APP_ACTION.SUCCESS.LOGOUT:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: {},
        message: 'Sikeres kilépés!',
        selectedComponent: 'Landing',
        selectedComponentProps: '',
      };
    case APP_ACTION.FAIL.LOGOUT:
      return {
        ...state,
        isLoading: false,
        message: 'Sikertelen kilépés!',
      };
    case APP_ACTION.GET_ZIPS:
      return { ...state, isLoading: true };
    case APP_ACTION.SUCCESS.GET_ZIPS:
      return {
        ...state,
        isLoading: false,
        zips: get(action, 'payload.data.zips', []),
      };
    case APP_ACTION.FAIL.GET_ZIPS:
      return {
        ...state,
        isLoading: false,
      };
    case APP_ACTION.UPDATE_VIEW:
      return {
        ...state,
        selectedComponent: action.view,
        selectedComponentProps: action.params,
      };
    case APP_ACTION.TOGGLE_MOBILE_NAV:
      return {
        ...state,
        isNavOpen: !state.isNavOpen,
      };
    default:
      const isLoggedIn = get(action, 'payload.data.loggedIn');
      return {
        ...state,
        isLoggedIn: isLoggedIn !== undefined ? isLoggedIn : state.isLoggedIn,
        message: isLoggedIn !== undefined && !isLoggedIn
          ? 'Lejárt az időkorlát, kérjük lépjen be újra!' : state.message,
      };
  }
};
