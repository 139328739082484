import { connect } from 'react-redux';
import {
  getOrders,
  saveOrder,
  deleteOrder,
  setCurrentOrder,
  updateCurrentOrder,
  updateFurnitures,
  removeFurniture,
  addFurniture,
  removeImage,
} from '../../views/Orders/Orders.actions';
import { getCalendarEvents, getFirmGroups } from '../../views/Groups/Groups.actions';

const mapStateToProps = ({
  groupReducer: { firms, groups },
  orderReducer: { isLoading, currentOrder },
  appReducer: { user },
  groupReducer: { firmGroups, calendarEvents },
}: any) => ({
  firms,
  groups,
  isLoading,
  user,
  calendarEvents,
  firmGroups,
  currentOrder,
});

const mapStateToPropsDate = ({
  firmReducer: { calendarEvents },
}: any) => ({
  calendarEvents,
});

const mapDispatchToProps = {
  getOrders,
  saveOrder,
  deleteOrder,
  getCalendarEvents,
  getFirmGroups,
  setCurrentOrder,
  updateCurrentOrder,
  updateFurnitures,
  removeFurniture,
  addFurniture,
  removeImage,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);

export const dateConnector = (component: any) =>
  connect(mapStateToPropsDate, { getCalendarEvents })(component);
