import React, { useState } from 'react';
import { ILoginProps } from './Login.interface';
import { connector } from './Login.connector';
import './Login.scss';
import logo from '../../assets/images/drspeed_logo.png';

// tslint:disable-next-line: variable-name
const Login = (props: ILoginProps) => {
  const { message } = props;
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');

  const login = () =>
    props.login({ username, password });

  const updatePassword = (e: any) => {
    setpassword(e.target.value);
    if (e.keyCode === 13) {
      login();
    }
  };

  return (
    <>
      {message &&
        <div className="message">{message}</div>
      }
      <div className="login">
        <div>
          <img src={logo} className="logo" alt="logo" />
          <label htmlFor="loginuser">
            <span>Felhasználó név vagy email cím</span>
            <input
              type="text"
              name="loginuser"
              onKeyUp={(e: any) => setusername(e.target.value)}
            />
          </label>
          <label htmlFor="loginpass">
            <span>Jelszó</span>
            <input
              type="password"
              name="loginpass"
              onKeyUp={updatePassword}
            />
          </label>
          <input type="submit" value="Belépés" className="button" onClick={login} />
          </div>
      </div>
    </>
  );
};

export default connector(Login);
