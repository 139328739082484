import { ICalendarEvent, IGetCalendarEvent } from '../../components/Common/Calendar/Calendar.interface';
import { IGroup } from './Groups.interface';

export const GROUPS_ACTION = {
  GET_GROUP_LIST: '[GROUPS] GET_GROUP_LIST',
  ADD_GROUP: '[GROUPS] ADD_GROUP',
  DELETE_GROUP: '[GROUPS] DELETE_GROUP',
  GET_CALENDAR_EVENTS: '[GROUPS] GET_CALENDAR_EVENTS',
  ADD_CALENDAR_EVENT: '[GROUPS] ADD_CALENDAR_EVENT',
  GET_GROUPS: '[GROUPS] GET_GROUPS',
  SUCCESS: {
    GET_GROUP_LIST: '[GROUPS] GET_GROUP_LIST_SUCCESS',
    ADD_GROUP: '[GROUPS] ADD_GROUP_SUCCESS',
    DELETE_GROUP: '[GROUPS] DELETE_GROUP_SUCCESS',
    GET_CALENDAR_EVENTS: '[GROUPS] GET_CALENDAR_EVENTS_SUCCESS',
    ADD_CALENDAR_EVENT: '[GROUPS] ADD_CALENDAR_EVENT_SUCCESS',
    GET_GROUPS: '[GROUPS] GET_GROUPS_SUCCESS',
  },
  FAIL: {
    GET_GROUP_LIST: '[GROUPS] GET_GROUP_LIST_FAIL',
    ADD_GROUP: '[GROUPS] ADD_GROUP_FAIL',
    DELETE_GROUP: '[GROUPS] DELETE_GROUP_FAIL',
    GET_CALENDAR_EVENTS: '[GROUPS] GET_CALENDAR_EVENTS_FAIL',
    ADD_CALENDAR_EVENT: '[GROUPS] ADD_CALENDAR_EVENT_FAIL',
    GET_GROUPS: '[GROUPS] GET_GROUPS_FAIL',
  },
};

export const getGroups = () => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getGroups',
      },
    },
    type: GROUPS_ACTION.GET_GROUP_LIST,
  };
};

export const saveGroup = (group: IGroup) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=saveGroup',
        method: 'POST',
        data: group,
      },
    },
    type: GROUPS_ACTION.ADD_GROUP,
  };
};

export const deleteGroup = (groupId: string) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=deleteGroup',
        method: 'POST',
        data: {
          id: groupId,
        },
      },
    },
    type: GROUPS_ACTION.DELETE_GROUP,
  };
};

export const getCalendarEvents = (props: IGetCalendarEvent) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getCalendarEvents',
        method: 'POST',
        data: props,
      },
    },
    type: GROUPS_ACTION.GET_CALENDAR_EVENTS,
  };
};

export const addCalendarEvent = (event: ICalendarEvent) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=addCalendarEvent',
        method: 'POST',
        data: event,
      },
    },
    type: GROUPS_ACTION.ADD_CALENDAR_EVENT,
  };
};

export const getFirmGroups = (firm: string) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getFirmGroups',
        method: 'POST',
        data: {
          firm,
        },
      },
    },
    type: GROUPS_ACTION.GET_GROUPS,
  };
};
