import { cloneDeep, get } from 'lodash';
import { USERS_ACTION } from './Users.actions';
import { USER_FORM_ACTIONS } from '../../components/UserForm/UserForm.actions';
export const defaultAppState = {
  isLoading: true,
  users: [],
  firms: [],
};

export const userReducer = (
  state = cloneDeep(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case USERS_ACTION.GET_USER_LIST:
      return { ...state, isLoading: true };
    case USERS_ACTION.SUCCESS.GET_USER_LIST:
      return {
        ...state,
        users: get(action, 'payload.data.data.users', []),
        firms: get(action, 'payload.data.data.firms', []),
        groups: get(action, 'payload.data.data.groups', []),
        isLoading: false,
      };
    case USERS_ACTION.FAIL.GET_USER_LIST:
      return { ...state, isLoading: false };
    case USER_FORM_ACTIONS.SAVE_USER:
      return { ...state, isLoading: true };
    case USER_FORM_ACTIONS.SUCCESS.SAVE_USER:
      return {
        ...state,
        isLoading: false,
      };
    case USER_FORM_ACTIONS.FAIL.SAVE_USER:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
