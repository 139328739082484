import { connect } from 'react-redux';
import { saveUser } from './UserForm.actions';
import { getUsers, deleteUser } from '../../views/Users/Users.actions';
import { roles } from '../../views/Users/User.constants';

const mapStateToProps = ({ userReducer }: any) => ({
  role: roles,
  users: userReducer.users,
  firm: userReducer.firms,
  team: userReducer.groups,
  isLoading: userReducer.isLoading,
});

const mapDispatchToProps = {
  saveUser,
  getUsers,
  deleteUser,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
