import { connect } from 'react-redux';
import * as usersActions from './Users.actions';

const mapStateToProps = ({ userReducer }: any) => ({
  users: userReducer.users,
  firm: userReducer.firms,
  team: userReducer.groups,
  isLoading: userReducer.isLoading,
});

const mapDispatchToProps = {
  ...usersActions,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
