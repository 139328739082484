import { INavItem } from '../components/Navigation/Navigaton.interface';

const domains = {
  test: 'https://gridcms.com/drspeed/',
  prod: 'https://app.drspeed.hu/',
};

const navigationItems: INavItem[] = [
  {
    id: 'Orders',
    key: 'Orders',
    label: 'Megrendelések',
    icon: 'icon_list.png',
    children: [
      {
        id: 'NewOrders',
        key: 'Orders',
        label: 'Új megrendelés',
        icon: '',
        params: 'new',
        role: [
          'info',
          'admin',
        ],
      },
    ],
    role: [
      'teammember',
      'info',
      'admin',
    ],
  },
  {
    id: 'CalendarDay',
    key: 'CalendarDay',
    label: 'Naptár',
    role: [
      'teammember',
      'admin',
    ],
    icon: 'icon_cal.png',
    children: [
      {
        id: 'CalendarDay',
        key: 'CalendarDay',
        label: 'Napi',
        icon: '',
      },
      {
        id: 'CalendarWeek',
        key: 'CalendarWeek',
        label: 'Heti',
        icon: '',
      },
    ],
  },
  {
    id: 'Users',
    key: 'Users',
    label: 'Felhasználók',
    icon: 'icon_users.png',
    role: [
      'admin',
    ],
    children: [
      {
        id: 'Users',
        key: 'Users',
        label: 'Lista',
        icon: '',
      },
      {
        id: 'Groups',
        key: 'Groups',
        label: 'Csapatok',
        icon: '',
      },
      {
        id: 'Firms',
        key: 'Firms',
        label: 'Cégek',
        icon: '',
      },
    ],
  },
  {
    id: 'Statistics',
    key: 'Statistics',
    label: 'Statisztika',
    icon: 'icon_stat.png',
    className: 'inactive',
    role: [
      'admin',
    ],
  },
  {
    id: 'Settings',
    key: 'Settings',
    label: 'Beállítások',
    icon: 'icon_settings.png',
    className: 'inactive',
    role: [
      'admin',
    ],
  },
  {
    id: 'Logout',
    key: 'Logout',
    label: 'Kilépés',
    icon: 'icon_exit.png',
    role: [
      'teammember',
      'info',
      'admin',
    ],
  },
];

const siteConfig = {
  navigationItems,
  domain: domains.prod,
};

export default siteConfig;
