import { connect } from 'react-redux';

const mapStateToProps = ({
  appReducer: { zips },
}: any) => ({
  zips,
});

export const connector = (component: any) =>
  connect(mapStateToProps)(component);
