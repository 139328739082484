import React, { useEffect, useState } from 'react';
import { ITabProps } from '../OrderForm.interface';
import Furniture from './Furniture';
import { IDate, IFurniture, ISummary } from '../../../views/Orders/Orders.interface';
import { labels, /* priceBasedTypes */ } from '../../../views/Orders/Orders.constants';
import { renderInput } from '../../Common/Renderers';
import { calculateAssemblyPrice, getCalculationObject } from '../../Common/Utils';
import { cloneDeep } from 'lodash';

// tslint:disable-next-line: variable-name
const Furnitures = (props: ITabProps) => {
  const { stateObject, functionMap, firms = [] } = props;
  const { removeFurniture, saveFurnitures, addFurniture, date, summary } = functionMap;
  const { furnitures, firm } = stateObject;
  const [calculation, setcalculation] = useState('kika');
  const {
    defaultFurniture,
    applyAssemblyDiscount,
  } = getCalculationObject(calculation);
  useEffect(() => {
    const { calculation = 'kika' } = firms.find(ffirm => `${ffirm.id}` === firm) || {};
    setcalculation(calculation);
  }, [JSON.stringify(firms), firm]);
  useEffect(() => {
    const newObject: ISummary = cloneDeep(stateObject.summary);
      newObject.assemblyDiscount = applyAssemblyDiscount(stateObject);
      newObject.assemblyDiscountApprover = 'Áruház vezető';
      summary(newObject);
  }, [furnitures]);
  const sum = calculateAssemblyPrice(furnitures);
  let pbPrice = 0;

  const addNewFurniture = (event: any) => {
    event.preventDefault();
    addFurniture(defaultFurniture);
  }

  const changeFurnitures = (type: string, key: number) =>
    (prop?: string, value?: any) => {
      switch (type) {
        case 'remove':
          removeFurniture(key)();
          break;
        default:
          saveFurnitures(key)(prop, value);
          break;
      }
      if (prop && prop !== 'assembly_price') {
        const newObject: IDate = cloneDeep(stateObject.date);
        newObject.time = '';
        date(newObject);
      }
    }

  return (
    <div className="tabcontent" id="szallitmany">
      <h4>Bútorok</h4>
      {furnitures.map((furniture: IFurniture, key: number) =>
        <Furniture
          key={`furniture-${furniture.type}-${key}`}
          keyProp={`furniture-${furniture.type}-${key}`}
          removeFurniture={changeFurnitures('remove', key)}
          {...furniture}
          callback={changeFurnitures('', key)}
          calculationType={calculation}
          pbPrice={pbPrice}
          index={key}
        />)}
      <div className="topbuttons">
        <input
          type="submit"
          value="Másik bútor hozzáadása"
          className="button"
          onClick={addNewFurniture}
        />
      </div>
      <hr />
      {renderInput(
        'assemblySum',
        labels.furnitures.assemblySum,
        sum,
        () => null,
        'inputtype2 lightblue',
        'number',
      )}
    </div>
  );
};
export default Furnitures;
