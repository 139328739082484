import { connect } from 'react-redux';
import { saveFirm, getFirms, deleteFirm } from '../../views/Firms/Firms.actions';

const mapStateToProps = ({ firmReducer: { firms, isLoading } }: any) => ({
  firms,
  isLoading,
});

const mapDispatchToProps = {
  deleteFirm,
  getFirms,
  saveFirm,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
