const middlewareConfig = {
  interceptors: {
    request: [
      ({ }, config: any) => {
        return config;
      },
    ],
    response: [
      {
        error: ({ }, error: any) => {
          return Promise.reject(error);
        },
        success: ({ }, response: any) => {
          redirectToLocationUrl(response.headers.location);
          return response;
        },
      },
    ],
  },
  returnRejectedPromiseOnError: false,
};

const redirectToLocationUrl = (location: Location) => {
  if (location) {
    window.location = location;
  }
};

export default middlewareConfig;
