import React from 'react';

import user from '../../assets/images/user.jpg';
import fsIcon from '../../assets/images/icon_fullscreen.png';
import { IHeaderProps } from './Header.interface';
import { connector } from './Header.connector';
import { get } from 'lodash';

// tslint:disable-next-line: variable-name
const Header = (props: IHeaderProps) => {
  const name = get(props, 'user.data.display_name', 'user');
  const { isNavOpen } = props;

  return (
    <header className={`trans05 ${isNavOpen ? 'opened' : ''}`}>
      <div className="avatar">
        <figcaption>{name}</figcaption>
        <div className="avacont">
          <span><img src={user} alt={name} /></span>
        </div>
        <img
          src={fsIcon}
          alt="fullscreen"
          className="mobilemenu"
          onClick={() => props.toggleMobileNav()}
        />
      </div>
    </header>
  );
};

export default connector(Header);
