const style = `
<style>
#printcontent {
	width: 210mm;
	height: 297mm;
	margin: 10mm auto;
	box-shadow: 0 0 10mm rgba(0, 0, 0, 0.25);
	padding: 10mm;
}
#printcontent h1 {
	padding: 15px 0 0;
	color: #000 !important;
	font-family: Arial, sans-serif;
	font-weight: bold;
	font-size: 18px;
	text-align: right;
	text-transform: uppercase;
}
#printcontent h4 {
	color: #000 !important;
	font-weight: normal;
	font-size: 15px;
	display: inline-block;
	padding: 0 0 3px;
	margin: 5px 0 10px;
	border-bottom: 1px solid;
}
#printcontent h4.reset {
	border: none;
	padding: 0;
	margin: 0;
}
#printcontent table {
	width: 100%;
	font-family: Arial, sans-serif;
	padding: 0;
	margin: 0 0 3px 0;
	border-collapse: collapse;
}
#printcontent td {
	background: #fff !important;
	font-size: 13px;
	text-align: left;
	border: none;
}
#printcontent .bordered {
	border: 1px solid;
	padding: 10px;
}
#printcontent .centered {
	text-align: center !important;
}
#printcontent .rightAligned {
	text-align: right;
}
#printcontent .w35 {
	width: 35%;
}
#printcontent table span {
	font-weight: bold;
}
#printcontent table.fontos tr:last-child td + td {
	text-align: right;
}
#printcontent table.fontos td strong {
	font-size: 15px;
	font-weight: bold;
}
#printcontent table.masodik td {
	font-size: 12px;
	padding: 10px 5px;
}
#printcontent table.masodik td + td {
	text-align: right;
}
#printcontent .height2 {
	height: 2px;
}
#printcontent .padding5 {
	padding: 5px;
}
#printcontent .padding5-10-10 {
	padding: 5px 10px 10px;
}
#printcontent .padding5-10-0 {
	padding: 5px 10px 0px;
}
#printcontent .itemNumber {
	width: 50px;
	text-align: center;
	border-right: 1px solid;
	margin-right: 5px;
}
#printcontent .grayBg {
	background: #eee !important;
}
#printcontent .itemGap {
	width: 20px;
	padding: 15px 0;
}
#printcontent .uppercase {
	text-transform: uppercase;
}
#printcontent .itemDetails {
	text-align:right;
	background:#eee !important;
	border-radius:999px;
	display:inline-block;
	padding:5px 10px;
	float:right;
	margin:0 0 10px;
}
</style>
`;

export default style;
