import { connect } from 'react-redux';
import * as appActions from './App.actions';

const mapStateToProps = ({ appReducer: { isLoading, isLoggedIn, zips } }: any) => ({
  isLoading,
  isLoggedIn,
  zips: (zips || []).length,
});

const mapDispatchToProps = {
  ...appActions,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
