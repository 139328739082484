import { connect } from 'react-redux';
import { toggleMobileNav } from '../App/App.actions';

const mapStateToProps = ({ appReducer: { user, isNavOpen } }: any) => ({
  user,
  isNavOpen,
});

const mapDispatchToProps = {
  toggleMobileNav,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
