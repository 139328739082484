import React, { useState, useEffect } from 'react';
import { CalendarPopup } from './CalendarPopup';
import { ICalendarPopupProps, ICalendarEvent } from './Calendar.interface';
import { cloneDeep } from 'lodash';

const classMap: any = {
  10: 'fullday',
  8: 'h8',
  5: 'halfday',
  0: 'noday',
  '': '',
};

// tslint:disable-next-line: variable-name
export const Calendar =
  ({
    label,
    type,
    group,
    calendarEvents,
    saveCalendarEvent,
    saturdayOrders,
    sundayOrders,
  }: any) => {
    const [daysInMonth, setdaysInMonth] = useState(0);
    const [paddedDays, setpaddedDays] = useState(0);
    const [date, setdate] = useState(new Date());
    const [showPopup, setshowPopup] = useState(false);
    const [selectedDays, setselectedDays] = useState<number[]>([]);

    const updateDate = (change: number) => {
      const newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + change);
      const dateHelper = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
      setdaysInMonth(dateHelper.getDate());
      dateHelper.setDate(1);
      setpaddedDays(dateHelper.getDay());
      setdate(newDate);
    };

    useEffect(() => {
      updateDate(0);
    // eslint-disable-next-line
    }, []);

    const getDay = (index: string, day: string) =>
      parseInt(index, 10) * 7 + parseInt(day, 10) + 1 - (index === '0' ? 0 : (paddedDays - 1));

    const displayPopup = (day: number) => () => {
      const newDays = cloneDeep(selectedDays);
      const index = selectedDays.indexOf(day);
      if (index > -1) {
        newDays.splice(index, 1);
      } else {
        newDays.push(day);
      }
      setselectedDays(newDays);
    };

    const hidePopup = () => {
      setshowPopup(false);
      setselectedDays([]);
    };

    const saveCalendar = (props: ICalendarEvent) =>
      saveCalendarEvent(props);

    const transformDays = (day: number): string => {
      const dateHelp = new Date(date);
      dateHelp.setDate(day);
      return `${dateHelp.getMonth()}-${dateHelp.getDate()}`;
    };

    const popupProps: ICalendarPopupProps = {
      saveCalendar,
      type,
      group,
      cancel: hidePopup,
      year: new Date(date).getFullYear(),
      selectedDays: selectedDays.map(transformDays),
    };

    const getDayClass = (index: string, day: string) => {
      const calendar = (calendarEvents && calendarEvents[date.getFullYear()]) || {};
      const currentDay = getDay(index, day);
      let classname = classMap[`${calendar[`${date.getMonth()}-${currentDay}`] }` || ''];
      const dateHelper = new Date(date);
      dateHelper.setDate(currentDay);
      if (!classname && [0, 6].includes(dateHelper.getDay())) {
        const dayOrders = dateHelper.getDay() === 6 ? saturdayOrders : sundayOrders;
        if (dayOrders === 'off') {
          classname = 'noday';
        }
      }
      if (selectedDays.includes(currentDay)) {
        classname = 'marked';
      }
      return classname;
    };

    return (
      <>
        <hr />
        <div className="calset" key={`calendar-${type}`}>
          {showPopup && <CalendarPopup {...popupProps} />}
          <h4>{label}</h4>
          <b>{date.getFullYear()}. {date.toLocaleString('hu-HU', { month: 'long' })}</b>
          <div
            className="adcal" onClick={() => selectedDays.length > 0 ? setshowPopup(true) : false} >
            <span className="noday">0 óra</span>
            <span className="halfday">5 óra</span>
            <span className="h8">8 óra</span>
            <span className="h10">10 óra</span>
          </div>
          <div className="navbuttons">
            <a className="button secondary" onClick={() => updateDate(-1)}>Előző</a>
            <a className="button secondary" onClick={() => updateDate(1)}>Következő</a>
          </div>
          <div className="csm">
            <em className="csmh"><i>H</i><i>K</i><i>SZ</i><i>CS</i><i>P</i><i>SZ</i><i>V</i></em>
            {Object.keys([...Array(Math.ceil((daysInMonth + paddedDays) / 7))])
            .map((index: string) => (
              <em key={`week-${type}-${index}`}>
                {index === '0' &&
                  Object.keys([...Array((paddedDays || 7) - 1)]).map((pd: string) => <i key={`padding-${type}-${pd}`} />)}
                {Object.keys([...Array(7 - (index === '0' ? paddedDays - 1 : 0))])
                  .map((day: string) =>
                    getDay(index, day) <= daysInMonth ?
                      <i
                        key={`padding-${index}-${day}`}
                        onClick={displayPopup(getDay(index, day))}
                        className={getDayClass(index, day)}
                      >
                        {getDay(index, day)}
                      </i> : null,
                  )}
              </em>
            ))}
          </div>
        </div>
      </>
    );
  };
