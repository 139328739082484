import { cloneDeep } from 'lodash';
import { FIRMS_ACTION } from './Firms.actions';
export const defaultAppState = {
  isLoading: true,
  firms: [],
};

export const firmReducer = (
  state: any = cloneDeep(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case FIRMS_ACTION.GET_FIRM_LIST:
      return { ...state, isLoading: true };
    case FIRMS_ACTION.SUCCESS.GET_FIRM_LIST:
      return {
        ...state,
        firms: action.payload.data.data,
        isLoading: false,
      };
    case FIRMS_ACTION.FAIL.GET_FIRM_LIST:
      return { ...state, isLoading: false };
    case FIRMS_ACTION.ADD_FIRM:
      return { ...state, isLoading: true };
    case FIRMS_ACTION.SUCCESS.ADD_FIRM:
      return {
        ...state,
        isLoading: false,
      };
    case FIRMS_ACTION.FAIL.ADD_FIRM:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
