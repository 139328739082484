export const USERS_ACTION = {
  GET_USER_LIST: '[USERS] GET_USER_LIST',
  ADD_USER: '[USERS] ADD_USER',
  DELETE_USER: '[USERS] DELETE_USER',
  SUCCESS: {
    GET_USER_LIST: '[USERS] GET_USER_LIST_SUCCESS',
    DELETE_USER: '[USERS] DELETE_USER_SUCCESS',
  },
  FAIL: {
    GET_USER_LIST: '[USERS] GET_USER_LIST_FAIL',
    DELETE_USER: '[USERS] DELETE_USER_FAIL',
  },
};

export const getUsers = () => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=getUsers',
      },
    },
    type: USERS_ACTION.GET_USER_LIST,
  };
};

export const deleteUser = (userId: string) => {
  return {
    payload: {
      request: {
        url: 'wp-admin/admin-ajax.php?action=deleteUser',
        method: 'POST',
        data: {
          id: userId,
        },
      },
    },
    type: USERS_ACTION.DELETE_USER,
  };
};
