import { cloneDeep, get } from 'lodash';
import { GROUPS_ACTION } from './Groups.actions';
export const defaultAppState = {
  isLoading: true,
  groups: [],
  firmGroups: [],
  firms: [],
  calendarEvents: {},
};

export const groupReducer = (
  state: any = cloneDeep(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case GROUPS_ACTION.GET_GROUP_LIST:
      return { ...state, isLoading: true };
    case GROUPS_ACTION.SUCCESS.GET_GROUP_LIST:
      return {
        ...state,
        firms: get(action, 'payload.data.data.firms', []),
        groups: get(action, 'payload.data.data.groups', []),
        isLoading: false,
      };
    case GROUPS_ACTION.FAIL.GET_GROUP_LIST:
      return { ...state, isLoading: false };
    case GROUPS_ACTION.SUCCESS.GET_CALENDAR_EVENTS:
      const {
        group: getgroup = '',
      } = JSON.parse(get(action, 'payload.config.data', {}));
      return {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          [getgroup]: {
            ...get(action, 'payload.data.data', {}),
          },
        },
      };
    case GROUPS_ACTION.SUCCESS.ADD_CALENDAR_EVENT:
      const {
        group = '',
        year = new Date().getFullYear(),
      } = JSON.parse(get(action, 'payload.config.data', {}));
      return {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          [group]: {
            ...state.calendarEvents[group],
            [year]: {
              ...get(action, `payload.data.data.${year}`, {}),
            },
          },
        },
      };
    case GROUPS_ACTION.SUCCESS.GET_GROUPS:
      return {
        ...state,
        firmGroups: get(action, 'payload.data.data', {}),
      };
    default:
      return state;
  }
};
