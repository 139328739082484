import React from 'react';
import { connector } from '../../components/Header/Header.connector';

// tslint:disable-next-line: variable-name
const Landing = () => {
  const text = 'Hello, üdv újra...';
  return (
    <section className="dashboard fullwidth active">
      <article>
        <h2>{text}</h2>
        <p className="welcome">
          Kérlek válassz a menüből.
          A világos szürkével jelölt menüpontok átmenetileg ki vannak kapcsolva.
        </p>
      </article>
    </section>
  );
};

export default connector(Landing);
