import { combineReducers } from 'redux';
import { appReducer } from './components/App/App.reducer';
import { userReducer } from './views/Users/Users.reducer';
import { firmReducer } from './views/Firms/Firms.reducer';
import { groupReducer } from './views/Groups/Groups.reducer';
import { orderReducer } from './views/Orders/Orders.reducer';
export default combineReducers({
  appReducer,
  userReducer,
  firmReducer,
  groupReducer,
  orderReducer,
});
