
import { get } from 'lodash';
// tslint:disable-next-line: no-unused-declaration
import React from 'react';
import { IGroup } from '../../views/Groups/Groups.interface';
import { IOrder } from '../../views/Orders/Orders.interface';
import { calculateSlotWidth, filterByGroup, fitInDay, getWorkingHoursOnDay, isAdminUser } from '../Common/Utils';
import { IBookCalendarProps } from './OrderForm.interface';

// tslint:disable-next-line: variable-name
const BookCalendar = (props: IBookCalendarProps) => {
  const {
    morningorders,
    afternoonorders,
    filteredGroups,
    calendarEvents,
    selectedGroup,
    isAssembly,
    allorders,
    actual_time,
    id,
    user,
    firm,
  } = props;
  const isAdmin = isAdminUser(user);

  const mapOrders = (item: IOrder, index: number, array: IOrder[]) => {
    let prePlaceholder = null;
    let placeholder = null;
    let overflow = null;
    const orders = allorders.filter(filterByGroup(item.date.group || 0));
    const {
      id: orderId = 0,
      orderNr: ordNr = 'hely',
      deliveryData: ddata = { name: 'Szabad' },
      date = { time: '', actual_time: '' },
      firm: orderFirm = '',
    } = (item || {});

    const hours = getWorkingHoursOnDay(
      calendarEvents[get(selectedGroup, 'id', 0)],
      date.actual_time,
      selectedGroup,
      isAssembly,
    );
    const orderLabel = orderFirm === firm ? ddata.name : 'N/A';
    const orderNrLabel = orderFirm === firm ? ordNr : 'N/A';
    const isMorning = date.time === 'Délelőtt (8-14 óráig)';
    const nrOfSlots = isMorning ? Math.ceil(hours / 2) : Math.floor(hours / 2);
    const nrOfOppositeSlots = hours - nrOfSlots;
    let className = '';
    const width = calculateSlotWidth(item);
    const isCurrentOrder = (id === orderId || !orderId);
    const ordersLength = orders.reduce((value: number, order: IOrder) =>
      value + ((calculateSlotWidth(order) + 1) / 10), 0);
    const fitInSlot = ordersLength <= hours;
    if (isCurrentOrder) {
      className = 'orderslot';
      const willFitInDay = fitInDay(
        orders,
        isAssembly,
        item,
        calendarEvents[item.date.group],
        selectedGroup,
      );
      if (!willFitInDay) {
        className = 'nemjo';
      }
    }
    if (orders.length === 1 && isAssembly && !fitInSlot) {
      overflow = <span className="cover" />;
    }
    className = `${className} ${isMorning ? 'de' : 'du'}`;
    if (index === (array.length - 1) || (!isMorning && index === 0)) {
      const currentSlotOrders = orders
        .filter((order: IOrder) => order.date.time !== array[index].date.time);
      const otherSlotOrdersLength = currentSlotOrders
        .reduce((value: number, order: IOrder) =>
          value + ((calculateSlotWidth(order) + 1) / 10), 0);
      const currentSlotOrdersLength = orders
        .filter((order: IOrder) => order.date.time === array[index].date.time)
        .reduce((value: number, order: IOrder) =>
          value + ((calculateSlotWidth(order) + 1) / 10), 0);
      const oppositeOverflows = otherSlotOrdersLength > nrOfOppositeSlots;
      const currentOverflows = currentSlotOrdersLength > nrOfSlots;
      const needPlaceholder = (isMorning && !otherSlotOrdersLength)
        || (!isMorning && !otherSlotOrdersLength)
        || (!currentOverflows
        && (!oppositeOverflows || nrOfSlots - currentSlotOrdersLength > 0));
      if (needPlaceholder) {
        let phwidth = (nrOfSlots - currentSlotOrdersLength) * 10 - 1;
        if (oppositeOverflows) {
          phwidth -= (otherSlotOrdersLength - nrOfOppositeSlots) * 10;
        }
        if (isMorning && !otherSlotOrdersLength) {
          phwidth += (nrOfOppositeSlots * 10);
        }
        const needPrePlaceholder = !isMorning && !otherSlotOrdersLength && index === 0;
        const needPostPlaceholder = phwidth > 0 && index === (array.length - 1);
        if (needPrePlaceholder) {
          const pphwidth =
            (Math.min(nrOfOppositeSlots, (hours - currentSlotOrdersLength)) * 10) - 1;
          if (pphwidth > 0) {
            prePlaceholder = (
              <span
                className={`placeholder preplaceholder ${isMorning ? 'de' : 'du'}`}
                key={`placeholder-${item.id}-1`}
                style={{ width: `${pphwidth}%` }}
              >
                <b>{'Szabad'}</b><br />
                {'hely'}
              </span>
            );
          }
        }
        if (needPostPlaceholder) {
          placeholder = (
            <span
              className={`placeholder ${isMorning ? 'de' : 'du'}`}
              key={`placeholder-${item.id}`}
              style={{ width: `${phwidth}%` }}
            >
              <b>{'Szabad'}</b><br />
              {'hely'}
            </span>
          );
        }
      }
    }
    return  (
      <React.Fragment key={`orderblock-${item.id}`}>
        {prePlaceholder}
        <span
          key={`order-${item.id}`}
          className={className}
          style={{ width: `${width}%` }}
        >
          <b>{orderLabel || 'N/A'}</b><br />
          {orderNrLabel || 'N/A'}
        </span>
        {placeholder}
        {overflow}
      </React.Fragment>
    );
  };

  const mapGroups = (fgroup: IGroup) => {
    const slots = getWorkingHoursOnDay(
      calendarEvents[fgroup.id || 0],
      actual_time,
      fgroup,
      isAssembly,
    );
    return (
      <div className="slotHolder" key={`slot-group-${fgroup.id}`}>
        <em className="dvlegend">
          {[...Array(slots).keys()].map((item: number) =>
            (<i key={`slot-${fgroup.id}-${item}`}>{item + 9}.00</i>))
          }
        </em>
        <div className="bookslots bsde">
          {isAdmin && <div>{fgroup.name}<br /></div>}
          {!(
            morningorders.filter(filterByGroup(fgroup.id || 0)).length
            || afternoonorders.filter(filterByGroup(fgroup.id || 0)).length
          ) &&
            <span
              className="placeholder fullday"
              key={`placeholder-full-${fgroup.id}`}
              style={{ width: `${(slots * 10 || 1) - 1}%` }}
            >
              <b>{'Szabad'}</b><br />
              {'hely'}
            </span>
          }
          {morningorders
            .filter(filterByGroup(fgroup.id || 0))
            .map(mapOrders)}
          {afternoonorders
            .filter(filterByGroup(fgroup.id || 0))
            .map(mapOrders)}
        </div>
      </div>
    );
  };
  return (
    <>
      {filteredGroups.map(mapGroups)}
    </>
  );
};

export default BookCalendar;
