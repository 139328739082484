import React, { useState } from 'react';
import { formatDate } from '../../components/Common/Helpers';
import { renderCheckbox, renderDatePicker, renderInput, renderSelect } from '../../components/Common/Renderers';
import siteConfig from '../../config/siteConfig';

export interface IFilterProps {
  getOrders: (
    startDate: string,
    endDate: string,
    useRedux?: boolean,
    firm?: string,
    query?: string,
  ) => Promise<any>;
  updateDate: (type: string, date: string) => void;
  setname: (name: string) => void;
  setaddress: (address: string) => void;
  setreclamation: (reclamation: boolean) => void;
  setbook: (book: boolean) => void;
  setfeedback: (feedback: boolean) => void;
  settype: (type: string) => void;
  setcity: (city: string) => void;
  setshop: (shop: string) => void;
  setteam: (shop: string) => void;
  team: string;
  shop: string;
  city: string;
  type: string;
  name: string;
  address: string;
  reclamation: boolean;
  book: boolean;
  feedback: boolean;
  startDate: string;
  endDate: string;
  cities: any;
  shops: any;
  teams: any;
}

export const types = {
  all: 'Összes',
  assembly: 'Szerelés',
  delivery: 'Szállítás',
};

// tslint:disable-next-line: variable-name
const Filter = (props: IFilterProps) => {
  const {
    getOrders,
    updateDate,
    startDate,
    endDate,
    setname,
    setaddress,
    setreclamation,
    setbook,
    setfeedback,
    settype,
    setcity,
    setshop,
    setteam,
    team,
    shop,
    city,
    type,
    name,
    address,
    reclamation,
    book,
    feedback,
    cities,
    shops,
    teams,
  } = props;
  const dateFormat = 'yyyy.MM.dd';
  const pickerProps = { dateFormat };
  const [query, setquery] = useState('');
  const [displayMoreFilters, setdisplayMoreFilters] = useState(false);
  cities.all = 'Összes';
  shops.all = 'Összes';
  teams.all = 'Összes';

  const exportOrdersCallback = () => {
    const params = new URLSearchParams({
      startDate,
      endDate,
      action: 'exportOrders',
    }).toString();
    window.open(`${siteConfig.domain}wp-admin/admin-ajax.php?${params}`);
  };

  const searchInputEventHandler = (event: any) => {
    if (event.key === 'Enter') {
      getOrders(startDate, endDate, true, '', query);
    }
  };
  return (
    <div className="filterandexport">
      {renderDatePicker(
        'startDate',
        'Kezdődátum',
        startDate,
        date => updateDate('startDate', formatDate(date)),
        'inputtype2',
        pickerProps,
      )}
      {renderDatePicker(
        'endDate',
        'Záródátum',
        endDate,
        date => updateDate('endDate', formatDate(date)),
        'inputtype2',
        pickerProps,
      )}
      {renderInput(
        'query',
        'Keresés',
        query,
        querystring => setquery(querystring),
        'inputtype2',
        'text',
        searchInputEventHandler,
      )}
      <div>
        <input
          type="submit"
          value="Szűrés"
          className="button"
          onClick={() => getOrders(startDate, endDate, true, '', query)}
        />
        <input
          type="submit"
          value="Export"
          className="button secondary"
          onClick={exportOrdersCallback}
        />
        <i className="mf" onClick={() => setdisplayMoreFilters(!displayMoreFilters)} />
      </div>
      {displayMoreFilters &&
        <div className="morefilter">
					<div>
            {renderInput(
              'name',
              'Megrendelő neve',
              name,
              name => setname(name),
              'inputtype2',
            )}
            {renderInput(
              'address',
              'Megrendelő címe',
              address,
              address => setaddress(address),
              'inputtype2',
            )}
					</div>
					<br className="clear" />
          {renderSelect(
            'type',
            'Típus',
            type,
            types,
            type => settype(type),
          )}
          {renderSelect(
            'city',
            'Város',
            city,
            cities,
            city => setcity(city),
          )}
          {renderSelect(
            'shop',
            'Áruház',
            shop,
            shops,
            shop => setshop(shop),
          )}
          {renderSelect(
            'teams',
            'Csapat',
            team,
            teams,
            team => setteam(team),
          )}
					<br className="clear" />
          {renderCheckbox(
            'reclamation',
            'Reklamáció',
            reclamation,
            reclamation => setreclamation(reclamation),
          )}
          {renderCheckbox(
            'book',
            'Előrendelés',
            book,
            book => setbook(book),
          )}
          {renderCheckbox(
            'feedback',
            'Visszajelzés',
            feedback,
            feedback => setfeedback(feedback),
          )}
				</div>
      }
    </div>
  );
};

export default Filter;
