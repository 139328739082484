import React, { useState, useEffect } from 'react';
import { connector } from './Firms.connector';
import { IFirmsProps, IFirm } from './Firms.interface';
import Spinner from '../../components/Image/Spinner';
import FirmForm from '../../components/FirmForm/FirmForm';
import './Firms.scss';

// tslint:disable-next-line: variable-name
const Firms = (props: IFirmsProps) => {
  const [displayFirmForm, setdisplayFirmForm] = useState(false);
  const [selectedFirm, setselectedFirm] = useState<null | IFirm>(null);
  const { getFirms, isLoading } = props;

  useEffect(() => {
    getFirms();
  }, [getFirms]);

  const renderUserRows = (): JSX.Element[] => {
    let rows = [(<tr key="user-0"><td colSpan={3}>Nem található cég.</td></tr>)];
    if (props.firms && props.firms.length) {
      rows = props.firms.map((firm: IFirm) => (
        <tr key={`user-${firm.id}`}>
          <td>
            <a href={`firm/${firm.id}`} onClick={editFirm(firm)} title={firm.name}>{firm.name}</a>
          </td>
          <td>{firm.city}</td>
          <td>{firm.address}</td>
        </tr>
      ));
    }
    return rows;
  };

  const editFirm = (firm: IFirm) => {
    return (event: any) => {
      event.preventDefault();
      setselectedFirm(firm);
      setdisplayFirmForm(true);
    };
  };

  const cancelForm = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setdisplayFirmForm(false);
    setselectedFirm(null);
  };

  const userFormProps = {
    selectedFirm,
    cancelForm,
  };

  return (
    <section className={`firms ${!displayFirmForm && 'fullwidth'}`}>
      {isLoading && <Spinner />}
      {!isLoading &&
        <div>
          {displayFirmForm && <FirmForm {...userFormProps} />}
          {!displayFirmForm &&
            <>
              <a
                href="/firm/add"
                onClick={(e: any) => {
                  e.preventDefault();
                  setdisplayFirmForm(true);
                }}
                className="button addnew"
              >
                Cég hozzáadása
              </a>
              <article className="fullwidth">
                <h2>Cégek</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Cégnév</th>
                      <th>város</th>
                      <th>Cím</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderUserRows()}
                  </tbody>
                </table>
              </article>
            </>
          }
        </div>
      }
    </section>
  );
};

export default connector(Firms);
