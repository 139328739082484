import React, { useEffect, useState } from 'react';
import { ITabProps } from '../OrderForm.interface';
import { cloneDeep, get } from 'lodash';
import { labels, countries } from '../../../views/Orders/Orders.constants';
import { renderInput, renderSelect } from '../../Common/Renderers';
import { getCalculationObject } from '../../Common/Utils';
import ZipSelector from '../../ZipSelector/ZipSelector';
import { IZip } from '../../ZipSelector/ZipSelector.interface';
import Spinner from '../../Image/Spinner';
import { needDelivery, formatNumbers } from '../../Common/Helpers';
declare var google: any;

// tslint:disable-next-line: variable-name
const DeliveryData = (props: ITabProps) => {
  const [isLoading, setisLoading] = useState(false);
  const { deliveryData, furnitures } = props.stateObject;
  const { deliveryData: deliveryDataFn } = props.functionMap;
  const filteredFirms = props.firms.find(ffirm => `${ffirm.id}` === props.stateObject.firm);
  const { calculation = 'kika', address: firmAddress = '', zips = '' } = filteredFirms || {};
  const {
    calculateDeliveryPrice,
    getExtraCost,
    editableFields,
  } = getCalculationObject(calculation);
  const { city, zip, address, country } = deliveryData;
  const saveDeliveryData = (key: string) =>
    (value: number | string | boolean) => {
      const newObject: any = cloneDeep(props.stateObject.deliveryData);
      newObject[key] = value;
      deliveryDataFn(newObject);
    };
  const isDelivery = needDelivery(furnitures);
  const formatDeliveryTime = (length: number) =>
    Math.floor(length / 60);
  const calculateRoute = (event: any) => {
    event.preventDefault();
    if (!city || !zip || !address) {
      alert('Add meg az irányítószámot, várost és cí­met!');
    } else {
      setisLoading(true);
      const userAddress: string = [city, address, zip].join(', ').concat(country);
      calculateRouteHelper(userAddress, firmAddress);
    }
  };
  const calculateRouteHelper = (start: string, end: string) => {
    const directionsService = new google.maps.DirectionsService();
    const request = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.DRIVING,
      durationInTraffic: false,
    };
    if (get(window, 'debug', false)) {
      // tslint:disable-next-line: no-console
      console.log(directionsService, request);
    }
    directionsService.route(request, (result: any, status: any) => {
      if (status === google.maps.DirectionsStatus.OK) {
        const calc = result.routes[0].legs;
        // tslint:disable: no-string-literal
        const distance = Math.round(calc[0]['distance']['value'] / 1000);
        const duration = calc[0]['duration']['value'];
        const extradist = getExtraCost({ zips, zip, distance, country, furnitures });
        const newObject: any = cloneDeep(props.stateObject.deliveryData);
        if (isReadonly('base')) {
          const basePrice = calculateDeliveryPrice({ country, isDelivery, furnitures });
          newObject.base = basePrice;
        }
        newObject.distance = distance;
        newObject.distance_price = extradist;
        newObject.distance_time = formatDeliveryTime(duration);
        deliveryDataFn(newObject);
      }
      setisLoading(false);
      if (get(window, 'debug', false)) {
        // tslint:disable-next-line: no-console
        console.log(result, status);
      }
    });
  };
  const selectZip = (item: IZip) => {
    const newObject: any = cloneDeep(props.stateObject.deliveryData);
    newObject.city = item.city;
    newObject.zip = item.zip;
    deliveryDataFn(newObject);
  };
  useEffect(() => {
    if (isReadonly('base')) {
      const basePrice = calculateDeliveryPrice({ country, isDelivery, furnitures });
      const newObject: any = cloneDeep(props.stateObject.deliveryData);
      newObject.base = basePrice;
      deliveryDataFn(newObject);
    }
  // eslint-disable-next-line
  }, [deliveryData.country]);
  const zipProps = {
    selectZip,
  };
  const getUpdateFn = (key: string) =>
    isReadonly(key) ? () => null : saveDeliveryData(key);
  const isReadonly = (key: string) => !editableFields.includes(`deliveryData.${key}`);
  const transformNumber = (nr: number) =>
    formatNumbers(parseInt(`${nr}`.replace(/\s+/g, '') || '0', 10));
  return (
    <div className="tabcontent" id="celpont">
      {renderInput(
        'name',
        labels.deliveryData.name,
        deliveryData.name,
        saveDeliveryData('name'),
        'inputtype2',
      )}
      {renderSelect(
        'country',
        labels.deliveryData.country,
        deliveryData.country,
        countries,
        saveDeliveryData('country'),
      )}
      <ZipSelector {...zipProps} />
      {renderInput(
        'zip',
        labels.deliveryData.zip,
        deliveryData.zip,
        saveDeliveryData('zip'),
        'inputtype2',
      )}
      {renderInput(
        'city',
        labels.deliveryData.city,
        deliveryData.city,
        saveDeliveryData('city'),
        'inputtype2',
      )}
      {renderInput(
        'address',
        labels.deliveryData.address,
        deliveryData.address,
        saveDeliveryData('address'),
        'inputtype2',
      )}
      {renderInput(
        'floor',
        labels.deliveryData.floor,
        deliveryData.floor,
        saveDeliveryData('floor'),
        'inputtype2',
      )}
      {renderInput(
        'bell',
        labels.deliveryData.bell,
        deliveryData.bell,
        saveDeliveryData('bell'),
        'inputtype2',
      )}
      {renderInput(
        'phone',
        labels.deliveryData.phone,
        deliveryData.phone,
        saveDeliveryData('phone'),
        'inputtype2',
      )}
      <hr />
      <div className="topbuttons">
        <input
          type="submit"
          value="Távolság kiszámítása"
          className="getroute button"
          onClick={calculateRoute}
        />
        <small>{firmAddress}</small>
        {isLoading && <Spinner />}
      </div>
      <hr />
      {renderInput(
        'base',
        labels.deliveryData.base,
        isReadonly('base') ? formatNumbers(deliveryData.base) : transformNumber(deliveryData.base),
        getUpdateFn('base'),
        `inputtype2 lightblue ${isReadonly('base') ? 'readonly' : ''}`,
      )}
      {renderInput(
        'distance',
        labels.deliveryData.distance,
        deliveryData.distance,
        () => null,
        'inputtype2 lightblue readonly',
        'number',
      )}
      {renderInput(
        'distance_price',
        labels.deliveryData.distance_price,
        transformNumber(deliveryData.distance_price),
        getUpdateFn('distance_price'),
        `inputtype2 lightblue ${isReadonly('distance_price') ? 'readonly' : ''}`,
      )}
      {renderInput(
        'distance_time',
        labels.deliveryData.distance_time,
        deliveryData.distance_time,
        () => null,
        'inputtype2 lightblue readonly',
      )}
    </div>
  );
};

export default DeliveryData;
