import { connect } from 'react-redux';
import * as ordersActions from './Orders.actions';
import { getGroups } from '../Groups/Groups.actions';

const mapStateToProps = ({
  orderReducer: { orders, isLoading, startDate, endDate },
  appReducer: { user, selectedComponent, selectedComponentProps },
  groupReducer: { firms, groups },
}: any) => ({
  orders,
  firms,
  groups,
  isLoading,
  startDate,
  endDate,
  user,
  selectedComponent,
  params: selectedComponentProps,
});

const mapDispatchToProps = {
  ...ordersActions,
  getGroups,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
