import React, { useState } from 'react';
import Print from './Tabs/Print';
import { print, downloadPdf } from '../Common/Utils';
import { getPrintProps } from '../../views/Calendar/Calendar.utils';
import { IOrderFormPopupProps } from './OrderForm.interface';

// tslint:disable-next-line: variable-name
const OrderFormPopup = (props: IOrderFormPopupProps) => {
  const { firms, order, cancel } = props;
  const [displayprint, setprint] = useState(false);
  const printProps = getPrintProps(firms, order);

  const printOrder = (event: any) => {
    setprint(true);
    setTimeout(() => {
      print(event);
      cancel();
    }, 10);
  };

  const download = (event: any) => {
    setprint(true);
    setTimeout(() => {
      downloadPdf(order.orderNr)(event);
      cancel();
    }, 10);
  };

  return (
    <div className="actionspanel">
      <div className="apinside">
        <h5>Sikeres mentés</h5>
        <input onClick={printOrder} type="submit" value="Nyomtatás" className="button b2" />
        <input onClick={download} type="submit" value="Pdf Letöltése" className="button b2" />
        <input onClick={cancel} type="submit" value="Mégsem" className="button secondary" />
        {displayprint && <Print {...printProps} />}
      </div>
    </div>
  );
};

export default OrderFormPopup;
