import { cloneDeep, get, values } from 'lodash';
import { ORDERS_ACTION } from './Orders.actions';
import { formatDate } from '../../components/Common/Helpers';
import { defaultValues } from './Orders.constants';
const today = new Date();
const nextWeek = new Date();
nextWeek.setTime(nextWeek.getTime() + 7 * 24 * 60 * 60 * 1000);
export const defaultAppState: any = {
  isLoading: false,
  orders: [],
  firms: [],
  startDate: formatDate(today),
  endDate: formatDate(nextWeek),
  currentOrder: {
    furnitures: [
      defaultValues.furnitures,
    ],
  },
};

export const orderReducer = (
  state = cloneDeep(defaultAppState),
  action: any,
) => {
  switch (action.type) {
    case ORDERS_ACTION.GET_ORDER_LIST:
      return { ...state, isLoading: true, orders: [] };
    case ORDERS_ACTION.SUCCESS.GET_ORDER_LIST:
      return {
        ...state,
        firms: get(action, 'payload.data.data.firms', []),
        orders: values(get(action, 'payload.data.data.orders', [])),
        isLoading: false,
      };
    case ORDERS_ACTION.FAIL.GET_ORDER_LIST:
      return { ...state, isLoading: false };
    case ORDERS_ACTION.SAVE_ORDER:
      return { ...state, isLoading: true };
    case ORDERS_ACTION.SUCCESS.SAVE_ORDER:
      return {
        ...state,
        isLoading: false,
      };
    case ORDERS_ACTION.FAIL.SAVE_ORDER:
      return { ...state, isLoading: false };
    case ORDERS_ACTION.SAVE_FEEDBACK:
      return { ...state, isLoading: true };
    case ORDERS_ACTION.SUCCESS.SAVE_FEEDBACK:
      return {
        ...state,
        isLoading: false,
      };
    case ORDERS_ACTION.FAIL.SAVE_FEEDBACK:
      return { ...state, isLoading: false };
    case ORDERS_ACTION.REMOVE_IMAGE:
      return { ...state, isLoading: true };
    case ORDERS_ACTION.SUCCESS.REMOVE_IMAGE:
      console.log(action);
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          feedback: {
            ...state.currentOrder.feedback,
            images: [
              ...Object.values(action.payload.data.data.images)
            ]
          },
        },
        isLoading: false,
      };
    case ORDERS_ACTION.FAIL.REMOVE_IMAGE:
      return { ...state, isLoading: false };
    case ORDERS_ACTION.UPDATE_DATE:
      return {
        ...state,
        [action.label]: action.value,
      };
    case ORDERS_ACTION.SET_ORDER:
      return {
        ...state,
        currentOrder: action.order,
      };
    case ORDERS_ACTION.UPDATE_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          [action.property]: action.value,
        },
      };
    case ORDERS_ACTION.UPDATE_FURNITURE:
      const updateFurnitures = cloneDeep(state.currentOrder.furnitures || []);
      updateFurnitures[action.key][action.property] = action.value;
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          furnitures: updateFurnitures,
        },
      };
    case ORDERS_ACTION.ADD_FURNITURE:
      const addFurniture = cloneDeep(state.currentOrder.furnitures || []);
      addFurniture.push(action.furniture);
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          furnitures: addFurniture,
        },
      };
    case ORDERS_ACTION.REMOVE_FURNITURE:
      const removeFurniture = cloneDeep(state.currentOrder.furnitures || []);
      removeFurniture.splice(action.key, 1);
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          furnitures: removeFurniture,
        },
      };
    default:
      return state;
  }
};
