import { cloneDeep, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { disabledAssemblyTypes, disabledDeliveryTypes } from '../../../views/Firms/Firms.constants';
import { IFirm } from '../../../views/Firms/Firms.interface';
import { IGroup } from '../../../views/Groups/Groups.interface';
import { labels, timeSlots } from '../../../views/Orders/Orders.constants';
import { IDate, IFurniture, IOrder } from '../../../views/Orders/Orders.interface';
import { needAssembly, formatDate, forceNumber, getTypeOrder } from '../../Common/Helpers';
import {
  renderCheckbox,
  renderDatePicker,
  renderInput,
  renderRadioGroup,
  renderSelect,
  renderTextarea,
} from '../../Common/Renderers';
import {
  calculateSlotWidth,
  convertObject,
  filterByGroup,
  filterByType,
  fitInDay,
  getWorkingHoursOnDay,
  isAdminUser,
  isDisabledWeekendOrder,
} from '../../Common/Utils';
import Spinner from '../../Image/Spinner';
import BookCalendar from '../BookCalendar';
import { connector } from '../OrderForm.connector';
import { ITabProps } from '../OrderForm.interface';

// tslint:disable-next-line: variable-name
const DateData = (props: ITabProps) => {
  const { getOrders, currentOrder, calendarEvents, firmGroups, user } = props;
  let { actual_time } = currentOrder.date;
  const { zip, city } = currentOrder.deliveryData;
  const { id, firm, furnitures, reclamation } = currentOrder;
  const {
    time,
    comment,
    book,
    group,
  } = currentOrder.date;
  const [highlightDates, sethighlightDates] = useState<any[]>([]);
  const [nearLocation, setnearLocations] = useState<string | null>(null);
  const [nearLocationDates, setnearLocationDates] = useState<string[]>([]);
  const [bookings, setbookings] = useState<IOrder[]>([]);
  const [allorders, setallorders] = useState<IOrder[]>([]);
  const [morningorders, setmorningorders] = useState<IOrder[]>([]);
  const [afternoonorders, setafternoonorders] = useState<IOrder[]>([]);
  const [slotWidth, setslotWidth] = useState(((calculateSlotWidth(currentOrder) + 1) / 10));
  const [isLoading, setisLoading] = useState(false);
  const [displayTeamSelector, setdisplayTeamSelector] = useState(false);
  const [selectedGroup, setselectedGroup]
    = useState(firmGroups.find((fGroup: IGroup) => fGroup.id === group));
  const selectedFirm = props.firms.find((ffirm: IFirm) => `${ffirm.id}` === firm);
  const isAssembly = needAssembly(furnitures);
  const filteredGroups = firmGroups
    .filter(filterByType(reclamation, furnitures))
    .filter((fgroup: IGroup) => {
      const hours = getWorkingHoursOnDay(
        calendarEvents[fgroup.id || 0],
        actual_time,
        fgroup,
        isAssembly,
      );
      const enabledWeekend = isDisabledWeekendOrder(
        actual_time,
        slotWidth,
        fgroup,
        isAssembly,
        calendarEvents[fgroup.id || 0],
      );
      return hours && !enabledWeekend;
    })
    .sort((fgroup: IGroup, fgroup2: IGroup) =>
      getTypeOrder(fgroup.workTypes) - getTypeOrder(fgroup2.workTypes));
  const {
    dayClosing = '3',
  } = selectedFirm || {};
  const assemblyTime = slotWidth * 60;
  const isAdmin = isAdminUser(user);

  useEffect(() => {
    updateCalendar(formatDate(new Date(actual_time)));
    setslotWidth(((calculateSlotWidth(currentOrder) || 9) + 1) / 10);
  // eslint-disable-next-line
  }, [actual_time, id, firm]);

  useEffect(() => {
    calulateGroup();
  // eslint-disable-next-line
  }, [actual_time, book, bookings]);

  useEffect(() => {
    update(allorders);
    updateHighlights(bookings);
  // eslint-disable-next-line
  }, [time, id, actual_time, book, currentOrder]);

  useEffect(() => {
    displayNearLocations();
  // eslint-disable-next-line
  }, [bookings, zip]);

  useEffect(() => {
    setselectedGroup(firmGroups.find((fGroup: IGroup) => fGroup.id === group));
  // eslint-disable-next-line
  }, [group]);

  const calulateGroup = (force: boolean = false) => {
    const filteredBookings = bookings
      .filter((order: IOrder) => order.id !== id)
      .filter((order: IOrder) => order.date.actual_time === actual_time);
    const fits = fitInDay(
      filteredBookings.filter(filterByGroup(group)),
      isAssembly,
      currentOrder,
      calendarEvents[group || 0],
      selectedGroup,
    );
    if (force || (!group || !fits || !time)) {
      const newGroup = filteredGroups.find((fgroup: IGroup) => {
        const groupBookings = filteredBookings.filter(filterByGroup(fgroup.id));
        return fitInDay(
          groupBookings,
          isAssembly,
          currentOrder,
          calendarEvents[fgroup.id || 0],
          fgroup,
        );
      });
      const firstGroupId = newGroup?.id || filteredGroups[0]?.id || 0;
      saveDateData('group')(firstGroupId);
      // update(allorders);
    }
  };

  const displayNearLocations = () => {
    let nearLoc = null;
    let nearLocs: string[] = [];
    props.firms
    .filter((ffirm: IFirm) => ffirm?.city === selectedFirm?.city)
    .forEach((ffirm: IFirm) => {
      if (!ffirm.zips.includes(`${zip}`)) {
        const newItems = bookings
          // .filter(order => order.firm === firm)
          .filter((order: IOrder) => order.id !== id)
          // .filter(order => order.date.group !== group)
          .filter((order: IOrder) => `${get(order, 'deliveryData.zip', '')}` === `${zip}`)
          .map((order: IOrder) => `${order.date.actual_time.substr(5).replace('-', '.')}.`)
          .filter((value, index, array) => array.indexOf(value) === index);
        nearLocs = nearLocs.concat(newItems);
      }
    });
    if (nearLocs.length) {
      nearLoc = city;
      setnearLocationDates(nearLocs);
    }
    setnearLocations(nearLoc);
  };

  const updateHighlights = (newBookings: any) => {
    const hDates: any = [];
    const filteredBookings = newBookings
      .filter((order: IOrder) => order.id !== id);
    let naDates = filteredBookings.map((order: IOrder) => order.date.actual_time);
    naDates = naDates.filter((item: string, pos: number) => naDates.indexOf(item) === pos);
    naDates = naDates.filter((day: string) => {
      const fit = firmGroups
        .filter(filterByType(reclamation, furnitures))
        .some((fGroup: IGroup) => {
          const groupBookings = filteredBookings
            .filter(filterByGroup(fGroup.id))
            .filter((order: IOrder) => order.date.actual_time === day);
          const fakeOrder = {
            ...currentOrder,
            date: {
              ...currentOrder.date,
              actual_time: day,
            },
          };
          const willFit = fitInDay(
            groupBookings,
            isAssembly,
            fakeOrder,
            calendarEvents[fGroup.id || 0],
            fGroup,
          );
          return willFit;
        });
      return !fit;
    })
    .map((day: string) => new Date(day));
    hDates.push({ naDates });
    sethighlightDates(hDates);
  };

  const update = (orders: IOrder[]) => {
    let newOrders = cloneDeep(orders);
    newOrders = newOrders
      .filter((order: IOrder) => order.id !== id)
      .filter((order: IOrder) => !order.date.book);
    if (!book) {
      newOrders.push(currentOrder);
    }
    newOrders = newOrders
      .sort((orderA: IOrder, orderB: IOrder) =>
        (orderB.date.time || '').localeCompare(orderA.date.time || ''));
    const morning = newOrders.filter((order: IOrder) =>
      order.date.time === 'Délelőtt (8-14 óráig)');
    const afternoon = newOrders.filter((order: IOrder) =>
      order.date.time === 'Délután (13-18 óráig)');
    setallorders(newOrders);
    setmorningorders(morning);
    setafternoonorders(afternoon);
  };

  const saveDateData = (key: string) =>
    (value: number | string | boolean) => {
      const newObject: IDate = cloneDeep(currentOrder.date);
      newObject[key] = value;
      props.functionMap.date(newObject);
      currentOrder.date = newObject;
    };
  const filterDate = (date: string) => {
    const today = new Date();
    const currentDay = new Date(date);
    const pastDate = today.getTime() >= currentDay.getTime();
    const sameDay = today.getMonth() === currentDay.getMonth()
      && today.getDate() === currentDay.getDate();
    today.setDate(today.getDate() + 1);
    const sameDayClosed = today.getMonth() === currentDay.getMonth()
      && today.getDate() === currentDay.getDate()
      && today.getHours() >= (parseInt(dayClosing || '3', 10) + 9);
    if ((sameDay || sameDayClosed) && isAdmin) {
      return true;
    }
    if (pastDate || sameDayClosed) {
      return false;
    }
    const disabledDays: number[] = [];
    if ([0, 6].includes(currentDay.getDay())) {
      const isDisabledDay = filteredGroups.every((fgroup: IGroup) => {
        const {
          saturdayOrders = 'off',
          sundayOrders = 'off',
        } = fgroup || {};
        const dayOrders = currentDay.getDay() === 6 ? saturdayOrders : sundayOrders;
        const types = isAssembly ? disabledAssemblyTypes : disabledDeliveryTypes;
        const isDisabled = types.includes(dayOrders);
        const isOverriden = Object.keys(calendarEvents)
          .map((cegroup: string) => {
            return getWorkingHoursOnDay(
              calendarEvents[cegroup],
              formatDate(currentDay),
              selectedGroup,
              isAssembly,
            );
          })
          .reduce((prev: number, item: number) => prev > item ? item : prev, 0);
        return isDisabled && !isOverriden;
      });
      if (isDisabledDay) {
        disabledDays.push(currentDay.getDay());
      }
    }
    const day = currentDay.getDay();
    return !disabledDays.includes(day);
  };
  const updateCalendar = (date: any) => {
    setisLoading(true);
    getOrders(date, date, false, firm)
    .then((data: any) => {
      const newOrders = get(data, 'payload.data.data.orders', []);
      const newBookings = get(data, 'payload.data.data.bookings', []);
      setbookings(newBookings);
      setallorders(newOrders);
      update(newOrders);
      updateHighlights(newBookings);
      setisLoading(false);
    });
  };
  const updateDate = (key: string) => (event: any) => {
    saveDateData(key)(formatDate(event, '-'));
  };
  const resetTeam = () => {
    calulateGroup(true);
    setdisplayTeamSelector(true);
  };
  if (actual_time === null) {
    const nextAvailableDay = new Date();
    while (!filterDate(nextAvailableDay.toDateString())) {
      nextAvailableDay.setDate(nextAvailableDay.getDate() + 1);
    }
    actual_time = nextAvailableDay.toDateString();
    updateDate('actual_time')(nextAvailableDay);
  }
  const ato = new Date(actual_time);
  const dateFormat = 'yyyy.MM.dd';
  const pickerProps = {
    dateFormat,
    filterDate,
    highlightDates,
    shouldCloseOnSelect: true,
    showTimeSelect: false,
  };
  const calendarProps = {
    morningorders,
    afternoonorders,
    filteredGroups,
    calendarEvents,
    selectedGroup,
    isAssembly,
    allorders,
    actual_time,
    id,
    user,
    firm,
  };
  if (!furnitures.length || furnitures.some((furniture: IFurniture) => !furniture.type)) {
    return (
      <div className="tabcontent" id="idopont">
        <h2>Kérlek válassz először bútort és állitsd be a típusát mindnek!</h2>
      </div>
    );
  }
  return (
    <div className="tabcontent" id="idopont">
      {renderTextarea(
        'comment',
        labels.date.comment,
        comment,
        saveDateData('comment'),
      )}
      <hr />
      {renderCheckbox(
        'book',
        labels.date.book,
        book,
        saveDateData('book'),
      )}
      {nearLocation &&
        <>
          <div className="recommend">
            <p>
              A következő napokra már van <strong>{nearLocation}</strong> kiszállás:&nbsp;
              {nearLocationDates.join(', ')}.&nbsp;
              Próbáld meg ajánlani az ügyfélnek!
            </p>
          </div>
          <hr className="gap" />
        </>}
      <span className="dpContainer">
        {isLoading && <Spinner />}
        {!book && !isLoading && renderDatePicker(
          'actual_time',
          labels.date.actual_time,
          actual_time,
          updateDate('actual_time'),
          'inputtype2',
          pickerProps,
        )}
      </span>
      {!book && renderRadioGroup(
        'time',
        labels.date.time,
        time,
        timeSlots,
        saveDateData('time'),
      )}
      <span style={{ display: !book && (isAdmin || displayTeamSelector) ? 'block' : 'none' }}>
        {renderSelect(
          'group',
          labels.date.group,
          `${group}`,
          convertObject(filteredGroups),
          (value: string) => saveDateData('group')(forceNumber(value)),
        )}
      </span>
      <hr />
      {!book &&
        <div className="dayview dvszerel">
          {!filteredGroups.map((fgroup: IGroup) => fgroup.id).includes(group) &&
            <i className="delete furniture" onClick={resetTeam} />}
          <h4>
            Naptár
            <b>{ato.getFullYear()}.&nbsp;
            {ato.toLocaleString('hu-HU', { month: 'long' })}&nbsp;
            {ato.getDate()}, {ato.toLocaleString('hu-HU', { weekday: 'long' })}</b>
          </h4>
          <BookCalendar {...calendarProps} />
        </div>
      }
      <hr />
      {!book && renderInput(
        'assemblyTime',
        labels.date.assemblyTime,
        assemblyTime,
        () => null,
        'inputtype2 lightblue readonly',
        'number',
      )}
    </div>
  );
};

export default connector(DateData);
