import axios from 'axios';
import siteConfig from './siteConfig';

export const clients = {
  default: {
    client: axios.create({
      baseURL: siteConfig.domain,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    }),
  },
};
