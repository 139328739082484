import React, { useState, useEffect } from 'react';
import { IZipSelector, IZip } from './ZipSelector.interface';
import { connector } from './ZipSelector.connector';
const defaultCities: any = [];
const nrOfDisplayedResults = 21;

// tslint:disable-next-line: variable-name
const ZipSelector = (props: IZipSelector) => {
  const [showResults, setshowResults] = useState(false);
  const [filter, setfilter] = useState('');
  const [filteredResults, setfilteredResults] = useState<IZip[]>([]);
  const { zips, selectZip } = props;
  const filterZips = (item: IZip) =>
    !!filter ?
      (item.city.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0
      || item.zip.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) >= 0)
      : defaultCities.includes(item.zip);
  // tslint:disable-next-line: variable-name
  const filterDisplayedResultsNr = (_item: IZip, index: number) =>
    index < nrOfDisplayedResults;
  const mapZips = (item: IZip) => (
    <li key={`city-${item.id}`} onClick={() => selectCity(item)}>
      {item.city} {filter ? `(${item.zip})` : ''}
    </li>
  );
  const selectCity = (item: IZip) => {
    selectZip(item);
    setfilter(`${item.city} (${item.zip})`);
  };
  const focusInput = () => {
    setfilter('');
    setshowResults(true);
  };
  useEffect(() => {
    setfilteredResults(zips.filter(filterZips));
  // eslint-disable-next-line
  }, [filter]);
  return (
    <div className="zipselector">
      <label className="inputtype2">
        <span>Válassz várost:</span>
        <input
          required={true}
          value={filter}
          onChange={(event: any) => setfilter(event.target.value)}
          onFocus={focusInput}
          onBlur={() => setTimeout(() => setshowResults(false), 350)}
        />
      </label>
      {showResults && !!filter &&
        <div className="choices">
          {
            filteredResults
              .filter(filterDisplayedResultsNr)
              .map(mapZips)
          }
          {!!filter && filteredResults.length > nrOfDisplayedResults &&
            <li>
              {filteredResults.length - nrOfDisplayedResults} további találat, gépelj tovább...
            </li>
          }
          {!!filter && !filteredResults.length &&
            <li>
              Nincs találat.
            </li>
          }
        </div>
      }
    </div>
  );
};

export default connector(ZipSelector);
