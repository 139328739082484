import React, { useEffect, useState } from 'react';
import { connector } from './UserForm.connector';
import { IUserFormProps } from './UserForm.interface';
import Spinner from '../Image/Spinner';
import { types, labels } from '../../views/Users/User.constants';
import chaticon from '../../assets/images/icon_chat.png';
import {
  renderInput,
  renderHiddenInput,
  renderSelect,
  renderRadioGroup,
  renderPassword,
  renderMultiSelect,
  renderCheckbox,
} from '../Common/Renderers';
import { convertObject, validation, convertToArray } from '../Common/Utils';
import { get } from 'lodash';

// tslint:disable-next-line: variable-name
const UserForm = (props: IUserFormProps) => {
  const [id, setid] = useState('');
  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [role, setrole] = useState('info');
  const [team, setteam] = useState([props.team[0].id]);
  const [firm, setfirm] = useState([props.firm[0].id]);
  const [phone, setphone] = useState('');
  const [password, setpassword] = useState('');
  const [onlyPreOrder, setonlyPreOrder] = useState(false);
  const { selectedUser, isLoading } = props;
  const stateObject: any = {
    id,
    name,
    email,
    role,
    onlyPreOrder,
    firm,
    team,
    phone,
    password,
  };

  useEffect(() => {
    if (selectedUser) {
      setid(selectedUser.id);
      setname(selectedUser.name);
      setemail(selectedUser.email);
      setrole(selectedUser.role);
      setteam(selectedUser.team || props.team[0].id);
      setfirm(selectedUser.firm || props.firm[0].id);
      setphone(selectedUser.phone);
      setonlyPreOrder(selectedUser.onlyPreOrder);
    }
  // eslint-disable-next-line
  }, [selectedUser]);

  const functionMap: any = {
    id: setid,
    name: setname,
    email: setemail,
    role: setrole,
    onlyPreOrder: setonlyPreOrder,
    team: setteam,
    firm: setfirm,
    phone: setphone,
    password: setpassword,
  };

  const title = id ? 'Felhasználó szerkesztése' : 'Új felhasználó létrehozása';

  const renderInputFields = (item: string) => {
    const isAdmin = ['team', 'firm'].includes(item) && stateObject.role === 'admin';
    switch (types[item]) {
      case 'hidden':
        return renderHiddenInput(item, stateObject[item], functionMap[item]);
      case 'select':
        const hideTeam = item === 'team' && stateObject.role === 'info';
        if (hideTeam || isAdmin) {
          return null;
        }
        return renderSelect(
          item,
          labels[item],
          stateObject[item],
          convertObject(props[item]),
          functionMap[item],
        );
      case 'multiSelect':
        const hidefirm =
          (item === 'firm' && stateObject.role === 'teammember')
          || (item === 'team' && stateObject.role === 'info');
        if (hidefirm || isAdmin) {
          return null;
        }
        return renderMultiSelect(
          item,
          labels[item],
          stateObject[item],
          convertObject(convertToArray(props[item])),
          functionMap[item],
        );
      case 'radio':
        return renderRadioGroup(
          item,
          labels[item],
          stateObject[item],
          convertObject(props[item]),
          functionMap[item],
        );
      case 'checkbox':
        return renderCheckbox(item, labels[item], stateObject[item], functionMap[item]);
      case 'password':
        return renderPassword(item, labels[item], stateObject[item], functionMap[item]);
      default:
        let callBack = functionMap[item];
        if (selectedUser !== null && item === 'email' && selectedUser[item]) {
          callBack = () => null;
        }
        return renderInput(item, labels[item], stateObject[item], callBack);
    }
  };

  const saveUser = (event: any) => {
    event.preventDefault();
    props.saveUser(stateObject)
    .then(() => {
      props.getUsers();
      props.cancelForm();
    });
  };

  const deleteUser = (event: any) => {
    event.preventDefault();
    if (!window.confirm('Biztosan törölni akarod?')) {
      return false;
    }
    props.deleteUser(id)
    .then(() => {
      props.getUsers();
      props.cancelForm();
    });
  };

  const dirtyFields: string[] = [];
  const requiredFieldsFilled = Object.keys(stateObject)
  .every((item: string) =>  {
    if (['id', 'onlyPreOrder'].includes(item)) {
      return true;
    }
    const valid = validation.empty(get(stateObject, item));
    if (!valid) {
      dirtyFields.push(item);
    }
    return valid;
  });

  return (
    <>
      <article className="users">
        {isLoading && <Spinner />}
        {!isLoading &&
          <div className="UserFormContainer">
            <h2>{title}</h2>
            {id ? <i className="delete" onClick={deleteUser} /> : null}
            <form>
              {Object.keys(stateObject).map(renderInputFields)}
              {!requiredFieldsFilled &&
                <p>A '{get(labels, dirtyFields[0])}' mező kitöltése kötelező!</p>}
              <input
                type="submit"
                onClick={saveUser}
                value="Felhasználó mentése"
                className="button"
                disabled={!requiredFieldsFilled}
              />
              <input
                type="submit"
                onClick={props.cancelForm}
                value="Mégsem"
                className="button secondary"
              />
            </form>
          </div>
        }
      </article>
      <article className="helper">
        <img src={chaticon} alt="chat" />
        <h4>Felhasználó szerkesztése</h4>
        <p>1. A felhasználó email címe egyedi kell legyen, és később nem változtatható.</p>
        <p>2. A jelszó lehetőleg legyen legalább 8 karakter hosszú.</p>
        <p>3. Biztonságos jelszó tartalmaz számokat és nagy betűket is,&nbsp;
          esetlegesen speciális karaktereket.</p>
      </article>
    </>
  );
};

export default connector(UserForm);
