const today = new Date();
today.setHours(8);
export const labels: any = {
  id: '',
  firm: 'Cég',
  orderNr: 'Szerződésszám',
  accountNr: 'Számlaszám',
  reclamation: 'Reklamáció',
  furnitures: {
    price: 'Bútor ára',
    quantity: 'Mennyiség',
    type: 'Bútortípus',
    assembly: 'Szerelést igényel?',
    own_delivery: 'Ügyfél szállítja ki?',
    assembly_price: 'Szerelés költsége',
    linear_meter: 'Folyóméter',
    worksheet: 'Fali/dekorációs panel',
    kitchenTools: 'Eszköz beépítés',
    modifyCabinet: 'Falra fúrás',
    modifyCabinetCount: 'Darabszám',
    assemblySum: 'Szerelés összköltsége',
    worksheet_length: 'Hossz (fm)',
    wallDrill: 'Falrafúrás',
    desk: 'Munkalap-szerelés',
    slat: 'Szegőléc-készítés',
  },
  deliveryData: {
    name: 'Név',
    country: 'Ország',
    zip: 'Irányitószám',
    city: 'Város',
    address: 'Cím',
    floor: 'Emelet',
    bell: 'Kapucsengő',
    phone: 'Telefonszám',
    base: 'Szállítási alapdíj',
    distance: 'Távolság(km)',
    distance_price: 'Távolság költség',
    distance_time: 'Távolság percben',
  },
  date: {
    comment: 'Megjegyzés',
    time: 'Időpont kiválasztása',
    client_time: 'Ügyfél által kért időpont',
    actual_time: 'Időpont',
    book: 'Előfoglalás',
    group: 'Csapat',
    assemblyTime: 'Szükséges idő (perc)',
  },
  summary: {
    deliveryDiscount: 'Szállítási engedmény',
    deliveryDiscountApprover: 'Engedélyező (szállítás)',
    assemblyDiscount: 'Szerelési engedmény',
    assemblyDiscountApprover: 'Engedélyező (szerelés)',
    discount: 'Engedmény',
    delivery: 'Szállítás (szállítási alapdíj + távolság költség)',
    assembly: 'Szerelés összesen',
    sumWithoutDiscount: 'Összesen (szállitás+szerelés)',
    sum: 'Összesen (engedmény után)',
    images: 'Dokumentum feltöltése',
  },
};

export const roles: any = {
  admin: 'Adminisztrátor',
  info: 'Információ pultos',
  teammember: 'Csapat tag',
};

export const radioValues: any = {
  admin: 'Adminisztrátor',
  info: 'Információ pultos',
  teammember: 'Csapat tag',
};

export const types: any = {
  id: 'hidden',
  firm: 'select',
  reclamation: 'checkbox',
  furnitures: 'furniture',
  deliveryData: 'tab',
  date: 'tab',
  summary: 'tab',
};

export const tabContent: any = {
  furnitures: {
    furniture: 'furniture',
  },
  deliveryData: {
    name: 'input',
    country: 'input',
    zip: 'input',
    city: 'input',
    address: 'input',
    floor: 'input',
    bell: 'input',
    phone: 'input',
  },
  date: 'tab',
  summary: 'tab',
};

export const defaultValues: any = {
  furnitures: {
    price: 0,
    quantity: 1,
    type: '',
    assembly: false,
    own_delivery: false,
    assembly_price: 0,
    linear_meter: 0,
    worksheet: false,
    kitchenTools: false,
    modifyCabinet: false,
    modifyCabinetCount: 0,
    wallCloset: 0,
    floorCloset: 0,
    highCloset: 0,
    other: 0,
    kitchenToolTypes: {
      hotplate: false,
      sink: false,
      dishwasher: false,
      cooler: false,
      dehumidifier: false,
    },
  },
  deliveryData: {
    name: '',
    country: 'Magyarország',
    zip: '',
    city: '',
    address: '',
    floor: '',
    bell: '',
    phone: '+36',
    base: 0,
    distance: 0,
    distance_price: 0,
    distance_time: 0,
  },
  date: {
    comment: '',
    group: 1,
    time: 'Délelőtt (8-14 óráig)',
    book: false,
    client_time: null,
    actual_time: null,
  },
  summary: {
    deliveryDiscount: 0,
    deliveryDiscountApprover: 'Infopultos',
    assemblyDiscount: 0,
    assemblyDiscountApprover: 'Infopultos',
    images: [],
  },
  sums: {
    assemblyPrice: 0,
    deliveryPrice: 0,
    discount: 0,
    sum: 0,
  },
};

export const kitchenClosetTypes: any = {
  wallCloset: 'Felső szekrény',
  floorCloset: 'Alsó szekrény',
  highCloset: 'Álló szekrény',
  other: 'Oldallpanel, hézagpotló, mosogató-előlap',
};

export const kitchenClosetTypesrint: any = {
  wallCloset: 'Felső szekrény',
  floorCloset: 'Alsó szekrény',
  highCloset: 'Magas szekrény',
  other: 'Oldallpanel, hézagpotló, mosogató-előlap',
};

export const kitchenToolTypes: any = {
  cooler: 'Beépíthető hűtőszekrény',
  hotplate: 'Beépíthető főzőlap',
  owen: 'Beépíthető sütő',
  dishwasher: 'Beépíthető mosogatógép',
  microwave: 'Beépíthető mikrohullámú',
  dehumidifier: 'Páraelszívó',
  sink: 'Mosogatótálca',
  other: 'Egyéb készülék',
};

export const countries: any = {
  Magyarország: 'Magyarország',
  Ausztria: 'Ausztria',
  Horvátország: 'Horvátország',
  Románia: 'Románia',
  Szerbia: 'Szerbia',
  Szlovákia: 'Szlovákia',
  Szlovénia: 'Szlovénia',
  Ukrajna: 'Ukrajna',
};

export const approvers = {
  Infopultos: 'Infopultos',
  'Bútorosztály vezető': 'Bútorosztály vezető',
  'DrSpeed vezető': 'DrSpeed vezető',
  'Áruház vezető': 'Áruház vezető',
};

export const timeSlots = {
  'Délelőtt (8-14 óráig)': 'Délelőtt (8-14 óráig)',
  'Délután (13-18 óráig)': 'Délután (13-18 óráig)',
};

export const furnitureTypes: any = {
  couch: 'Kanapé',
  bed: 'Kárpitozott ágy',
  bedCouch: 'Kanapé, kárpitozott ágy',
  furniture: 'Lapraszerelt bútor',
  kitchen: 'Lapraszerelt Konyha',
  plannedKitchen: 'Tervezett konyha',
  kitchenSurvey: 'Konyha felmérés',
};

export const requiredFields: any = {
  firm: ['empty'],
  orderNr: ['empty'],
  furnitures: ['length'],
  'deliveryData.name': ['empty'],
  'deliveryData.country': ['empty'],
  'deliveryData.zip': ['empty'],
  'deliveryData.city': ['empty'],
  'deliveryData.address': ['empty'],
  'deliveryData.phone': ['empty'],
  'deliveryData.distance_time': ['empty'],
  'date.time': ['emptyDate'],
  'date.actual_time': ['emptyDate'],
};

export const priceBasedTypes = [
  'plannedKitchen',
  'furniture',
  'bedCouch',
];
