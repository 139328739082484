import { connect } from 'react-redux';
import { saveFeedback } from '../../views/Orders/Orders.actions';

const mapStateToProps = ({
  orderReducer: { isLoading, firms },
}: any) => ({
  isLoading,
  firms,
});

const mapDispatchToProps = {
  saveFeedback,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
