import { connect } from 'react-redux';
import { getOrders } from '../Orders/Orders.actions';
import { getGroups } from '../Groups/Groups.actions';

const mapStateToProps = ({
  orderReducer: {  isLoading, orders },
  groupReducer: { firms, groups },
  appReducer: { user, selectedComponent },
}: any) => ({
  firms,
  isLoading,
  user,
  selectedComponent,
  groups,
  orders,
});

const mapDispatchToProps = {
  getOrders,
  getGroups,
};

export const connector = (component: any) =>
  connect(mapStateToProps, mapDispatchToProps)(component);
