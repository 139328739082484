import { ILogin } from './Login.interface';

export const LOGIN_ACTION = {
  LOGIN: '[LOGIN] LOGIN',
  SUCCESS: {
    LOGIN: '[LOGIN] LOGIN_SUCCESS',
  },
  FAIL: {
    LOGIN: '[LOGIN] LOGIN_FAIL',
  },
};

export const login = (data: ILogin) => {
  return {
    payload: {
      request: {
        data,
        method: 'POST',
        url: 'wp-admin/admin-ajax.php?action=loginUser',
      },
    },
    type: LOGIN_ACTION.LOGIN,
  };
};
